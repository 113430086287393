import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../../../components/modal";
import { Table } from "../../../../components/table";

import {
  DateInput,
  SelectInput,
} from "../../../../components/input";
import { capitalize, formatDateForDb, getDatesOptions, numberFormat, padStart } from "../../../../utils/helpers/helpers";
import { clearFilters, setFilters } from "../../../../states/FiltersState";
import { clearAlert, setAlert } from "../../../../states/AlertState";
import { useGetReceivablesQuery } from "../../../../services/accounting/AccountingReportService";
import moment from "moment";
import { useGetCustomersQuery } from "../../../../services/customers/CustomerService";
interface ReceivableSummaryProps {
  exportTitle?: string,
  vehicle?: any,
  employee?: any,
  customer?: any,
  cargo?: any,
  aging_by?: any,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const ReceivableSummary = (props: ReceivableSummaryProps) => {
  const filters = useSelector((state: any) => state.filtersState);
  const dispatch = useDispatch();

  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetReceivablesQuery(filters, { skip: !(filters?.report_type == "receivable_summary") });

  const { data: customers } = useGetCustomersQuery({});
  const [customerList, setCustomerList] = useState<any>([]);

  const [entities, setEntities] = useState([
    {label: "Invoice", value: "invoice"},
    {label: "Receipt", value: "receipt"},
  ]);

  const [agingStarts, setAgingStarts] = useState([
    {label: "Invoice Due Date", value: "invoice_due_date"},
    {label: "Invoice Date", value: "invoice_date"},
  ]);

  const [statusList, setStatusList] = useState([
    {label: "Open", value: "Open"},
    {label: "Paid", value: "Paid"},
    {label: "Overdue", value: "Overdue"},
    {label: "Partially Paid", value: "Partially Paid"},
  ]);

  useEffect(() => {
    dispatch(setFilters({
      document_type: props.document_type || "invoice",
      report_type: "receivable_summary",
      customer: props.customer,
    }))
  }, [])

  useEffect(() => {
    customers?.customers?.length && setCustomerList(customers?.customers?.map((customer: any) => ({label: customer.first_name, value: customer.customer_id, ...customer})));
  }, [customers])

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [])

  let columns = [
    {
      label: "Customer Name",
      name: "customer_name",
      customRender: true,
      valueGetter: (item: any) =>  capitalize(item.customer_name),
    },
    {
      label: "Date",
      name: "transaction_date",
      customRender: true,
      valueGetter: (item: any) =>  item.title || formatDateForDb(item.transaction_date),
    },
    {
      label: "Invoice#",
      name: "transaction_number",
      customRender: true,
      valueGetter: (item: any) =>  item.transaction_type == "credit" ? padStart(item.invoice_number, 4, '0') : item.journal_number,
    },
    {
      label: "Status",
      name: "aging_status",
      customRender: true,
      valueGetter: (item: any) =>  capitalize(item.aging_status),
    },
    {
      label: "Transaction Type",
      name: "type",
      customRender: true,
      valueGetter: (item: any) =>  item.isTotal ? "" : (item.document_type),
    },
    {
      label: "Total",
      name: "total_payment_amount",
      money: true,
      customRender: true,
      valueGetter: (item: any) =>  numberFormat(item.total_payment_amount, 2, true),
    },
    {
      label: "Balance",
      name: "total_payment_amount_due",
      money: true,
      customRender: true,
      valueGetter: (item: any) =>  numberFormat(item.total_payment_amount_due, 2, true),
    },
  ]

  return (
    <>
      <div className="row shadow bg-white p-2 mb-1">
      <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Report Date"
            block={true}
            options={getDatesOptions()}
            value={getDatesOptions().find(date => date.name === filters.monthly_report_date_name)}
            onChange={(value) => dispatch(setFilters({
              monthly_report_from_date: value?.name !== "custom" ? value?.from_date : filters.monthly_report_from_date,
              monthly_report_to_date: value?.name !== "custom" ? value?.to_date : filters.monthly_report_to_date,
              monthly_report_date_name: value?.name,
            }))}
          />
        </div>
        {filters.monthly_report_date_name === "custom" &&
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Custom Start Date"
            block={true}
            value={new Date(filters.monthly_report_from_date)}
            onChange={(value) => {
              dispatch(setFilters({
                monthly_report_from_date: formatDateForDb(value),
              }))
            }}
          />
        </div>}
        {filters.monthly_report_date_name === "custom" &&
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Custom End Date"
            block={true}
            value={new Date(filters.monthly_report_to_date)}
            onChange={(value) => {
              dispatch(setFilters({
                monthly_report_to_date: formatDateForDb(value),
              }))
            }}
          />
        </div>}
        {/* <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Report By"
            block={true}
            options={agingStarts}
            value={agingStarts.find(agingStarts => agingStarts.value === filters.aging_by)}
            onChange={(value) => dispatch(setFilters({
              aging_by: value?.value,
            }))}
          />
        </div> */}
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Entities"
            block={true}
            clearable
            options={entities}
            value={entities.find(entity => entity.value === filters.document_type)}
            onChange={(value) => dispatch(setFilters({
              document_type: value?.value || "payment_transactions",
            }))}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Customer"
            block={true}
            clearable
            options={customerList}
            value={customerList.find((customer: any) => customer.value == filters.customer)}
            onChange={(value) => dispatch(setFilters({
              customer: value?.value
            }))}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Status"
            block={true}
            clearable
            options={statusList}
            value={statusList.find((status: any) => status.value == filters.status)}
            onChange={(value) => dispatch(setFilters({
              status: value?.value
            }))}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
            <div>
              <Table
                loading={isLoading}
                title={<span>Receivable Summary- <span className="text-small">From {moment(filters.monthly_report_from_date).format("MMM DD, YYYY")}  To  {moment(filters.monthly_report_to_date).format("MMM DD, YYYY")}</span></span>}
                exportTitle={props.exportTitle}
                downloadFileName={props.exportTitle || `Receivable Summary- From ${moment(filters.monthly_report_from_date).format("MMM DD, YYYY")}  To  ${moment(filters.monthly_report_to_date).format("MMM DD, YYYY")}`}
                columns={columns}
                items={data?.entries || []}
                onSearch={(search) => dispatch(setFilters({search: search}))}
                pagination={{
                  ...(data?.pagination || {}),
                  onPageChange: (page) => dispatch(setFilters({page: page})),
                  onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
                }}
                params={filters}
                customRenders={[
                  {
                    columnName: "transaction_date",
                    render: (item, index) => <span>{item.title || formatDateForDb(item.transaction_date)}</span>
                  },
                  {
                    columnName: "due_date",
                    render: (item, index) => <span>{item.title || formatDateForDb(item.due_date)}</span>
                  },
                  {
                    columnName: "transaction_number",
                    render: (item, index) => <span>{item.transaction_type == "credit" ? padStart(item.invoice_number, 4, '0') : item.journal_number}</span>
                  },
                  {
                    columnName: "type",
                    render: (item, index) => <span>{capitalize(item.isTotal ? "" : item.document_type)}</span>
                  },
                  {
                    columnName: "aging_status",
                    render: (item, index) => {
                      let aging_status_class = item.aging_status == "Overdue" ? "bg-danger" : (item.aging_status === "Paid" ? "bg-success" : (item.aging_status === "Partially Paid" ? "bg-secondary" : (item.aging_status == "Unpaid" ? "bg-warning" : "bg-info")));
                    return <span className={`badge ${aging_status_class} text-xsmall`}>{capitalize(item.aging_status)}</span>
                    }
                  },
                  {
                    columnName: "customer_name",
                    render: (item, index) => <span>{capitalize(item.customer_name)}</span>
                  },
                  {
                    columnName: "age",
                    render: (item, index) => <span>{item.age >= 1 && `${item.age} Days`}</span>
                  },
                  {
                    columnName: "total_payment_amount",
                    render: (item) => <span>{numberFormat(item.total_payment_amount, 2, true)}</span>
                  },
                  {
                    columnName: "total_payment_amount_due",
                    render: (item) => <span>{numberFormat(item.total_payment_amount_due, 2, true)}</span>
                  },
                ]}
                totalColumns={[
                  { name: "total_payment_amount" },
                  { name: "total_payment_amount_due" },
                ]}
                decimalPlaces={2}
                showZeroDecimalPlaces
                money
                totalColSpan={6}
              />
            </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default ReceivableSummary;
