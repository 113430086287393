import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Document,
  Font,
  Page,
  PDFDownloadLink,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

// import fontRegular from "../../fonts/Custom-Regular.ttf";
// import fontItalic from "../../fonts/Custom-Italic.ttf";
// import fontBold from "../../fonts/Custom-Bold.ttf";

import Header from "../../../components/pdf/Header";
import Footer from "../../../components/pdf/Footer";
import PDFDocTable from "../../../components/pdf/PDFDocTable";

import { appName } from "../../../utils/constants/constants";
import { clearAlert, setAlert } from "../../../states/AlertState";
import { formatDateForDb, numberFormat } from "../../../utils/helpers/helpers";
import PDFAddressDetails from "../../../components/pdf/PDFAddressDetails";
import { useGetExpenseTransactionQuery } from "../../../services/accounting/ExpenseTransactionService";
import { Alert } from "../../../components/alert";

Font.register({
  family: "Custom",
  fonts: [
    // { src: fontRegular },
    // { src: fontItalic, fontStyle: "italic" },
    // { src: fontBold, fontWeight: 700 },
  ],
});

export const ExpenseBill = (props: any) => {
  const { data, user } = props || {};
  const config = props.user?.configuration || {};

  let {
    sub_total,
    discount,
    discount_type,
    expense_items,
    charges,
    total_charges,
    total_amount,
    total_discount,
    expense_amount,
    total_expense_payment,
    expense_amount_due,
    transaction_type,
    bill_number,
    order_number,
    expense_date,
    due_date,
  } = data;
  
  const summary = [
    {
      label: "Subtotal",
      value: numberFormat(sub_total),
      className: "text-bold"
    },
    {
      label: `Discount${discount_type === "percent" ? `(${numberFormat(discount || 0)}%)` : ""}`,
      value: `${total_discount > 0 ? "(-) " : ""}${numberFormat(total_discount || 0)}`,
    },
    ...(
      charges
      ?.filter((charge: any) => charge.charge_category === "tax")
      ?.map((charge: any) => ({
        label: `${charge.charge_name}${charge.charge_type === "percent" ? `(${numberFormat(charge.charge_amount || 0)}%)` : ""} - Tax`,
        value: numberFormat(charge.charge_type === "percent" ? numberFormat(sub_total * (charge.charge_amount / 100)) : numberFormat(charge.charge_amount)),
      })) || []
    ),
    ...(
      charges
      ?.filter((charge: any) => charge.charge_category === "other")
      ?.map((charge: any) => ({
        label: `${charge.charge_name}${charge.charge_type === "percent" ? `(${numberFormat(charge.charge_amount || 0)}%)` : ""} - Charge`,
        value: numberFormat(charge.charge_type === "percent" ? numberFormat(sub_total * (charge.charge_amount / 100)) : numberFormat(charge.charge_amount)),
      })) || []
    ),
    {
      label: "Total",
      value: numberFormat(expense_amount),
      className: "text-bold"
    },
    {
      label: "Amount Paid",
      value: numberFormat(total_expense_payment),
    },
    {
      label: `${expense_amount_due < 0 ? "Change Due" : "Amount Due"}, TZS`,
      name: "amount_due",
      value: numberFormat(Math.abs(expense_amount_due)),
    },
  ];

  const columns =[
    {
      label: "Description",
      name: "description",
      getValue: (item: any) => `${item.expense_category_name}${item.expense_category_item_name ? ` - ${item.expense_category_item_name}` : ""}`,
    },
    {
      label: "Rate, TZS",
      name: "rate",
      getValue: (item: any) => numberFormat(item.rate),
    },
    {
      label: "Quantity",
      name: "quantity",
      getValue: (item: any) => numberFormat(item.quantity),
    },
    {
      label: "Amount, TZS",
      name: "amount",
      getValue: (item: any) => numberFormat(item.amount),
    },
  ];

  const docDetails = [
    {
      label: "Bill#:",
      value: bill_number,
    },
    {
      label: "Bill Date:",
      value: formatDateForDb(expense_date),
    },
    {
      label: "Due Date:",
      value: due_date,
    },
    {
      label: "Order#:",
      value: order_number,
    },
  ]

  
  return (
    <Document title="Expense Bill" creator={appName} producer={appName}>
    <Page
    size="A4"
    style={{
      width: "100%",
      backgroundColor: "white",
      paddingHorizontal: 24,
      paddingTop: 24,
      paddingBottom: 35,
    }}
    orientation="portrait"
    >
    <Header
      title="Expense Bill"
      titleTextStyle={styles.text}
      contactsTextStyle={styles.text}
      user={{ company: {...data, company_name: data?.first_name} }}
      showTIN
      detailsAlign="flex-end"
    />
    <View style={[styles.row, {justifyContent: "space-between", marginBottom: 24}]}>
      <View style={[{width: `${3/12 * 100}%`}]}>
        <PDFAddressDetails data={{...user?.company, first_name: user?.company?.company_name}} title="Bill To" showTIN />
      </View>
      <View style={[{width: `${4/12 * 100}%`}]}>
        <PDFDocTable
          columns={[
            {name: "label"},
            {name: "value", getValue: (item: any) => item.value},
          ]}
          items={docDetails}
          noTitle
          hideIndex
          getRowStyle = {(item: any, index: any) => ({textAlign: "right"})}
        />
      </View>
    </View>

    <PDFDocTable
      // title="Items"
      columns={columns}
      items={data?.expense_items || []}
      hideIndex
    />

    <View style={[styles.row, {justifyContent: "flex-end", marginTop: 12}]}>
      <View style={[{width: `${5/12 * 100}%`}]}>
        <PDFDocTable
          columns={[
            {name: "label"},
            {
              name: "value",
              getValue: (item: any) => item.value,
              getStyle: (item: any, index: any) => ({textAlign: "right"})
            },
          ]}
          items={summary}
          noTitle
          hideIndex
          // getRowStyle = {(item: any, index: any) => (item.name === "amount_due" && {fontWeight: "bold", color: "red"})}
        />
      </View>
    </View>
    
    <Footer
      style={styles.text}
      user={user}
      render={({ pageNumber, totalPages }: any) =>
        `${pageNumber} / ${totalPages}`
      }
    />
  </Page>
  </Document>
);
}

const ExpenseBillPreview = (props: any) => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();
  const alert = useRef<any>();
  
  const [docData, setDocData] = useState<any>();
  
  const { data, isLoading, isSuccess, isError, error} = useGetExpenseTransactionQuery(props.transactionID);
  
  const populateData = (data: any) => {
    setDocData({...data.expense_transaction});
  }
  
  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);
  
  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
    // alert.current?.showProgress("Please Wait...");
  }, [isLoading])
  
  useEffect(() => {
    isSuccess &&  dispatch(clearAlert());
    // alert.current?.close();
  }, [isSuccess]);
  
  useEffect(() => {
    data && populateData(data);
  }, [data])
  
  return (
    <div className="card">
      <div className="card-header with-actions">
        <div>Expense Bill</div>
        <div className="card-actions">
        {docData ? (
          <PDFDownloadLink
          document={
            <ExpenseBill data={docData} user={user} {...props} />
          }
          fileName={`Expense_Bill_${docData.bill_number}`}
          children={() => (
            <button type="button" className="btn secondary small">
              Download
            </button>
          )}
          />
        ) : null}
        </div>
      </div>
      <div className="card-body">
        <Alert ref={alert} />
        {docData ? (
          <PDFViewer className="pdf-viewer">
            <ExpenseBill data={docData} user={user} {...props} />
          </PDFViewer>
        ) : null}
      </div>
      <div className="card-footer d-flex flex-row justify-center">
        <button
          type="button"
          className="btn outlined"
          onClick={() => {
            props.modal.hide();
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  spacer: {
    marginBottom: 4,
  },
  divider: {
    height: 1,
    backgroundColor: "black",
  },
  text: {
    fontSize: 8,
    // fontFamily: "Custom",
  },
  outlined: {
    border: "1pt solid black",
  },
  padded: {
    padding: 5,
  },
  grey: {
    backgroundColor: "grey",
  },
  lightGrey: {
    backgroundColor: "#F5F5F5",
  },
  row: {
    flexDirection: "row",
  },
  table: {
    border: "1pt solid #666666",
    borderTopWidth: 0,
    borderLeftWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    border: "1pt solid #666666",
    borderRightWidth: 0,
    borderBottomWidth: 0,
    padding: 4,
    flex: 1,
    fontSize: 8,
  },
});

export default ExpenseBillPreview;