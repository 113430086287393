import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { Form } from "../../../components/form";
import {
  Checkbox,
  DateInput,
  SelectInput,
  TextArea,
  TextField
} from "../../../components/input";
import { ConfirmationDialog, Modal } from "../../../components/modal";
import { formatDateForDb, getValidationRules, getValidDateOrNull, padStart, numberFormat, stripCommas } from './../../../utils/helpers/helpers';
import { useDispatch } from "react-redux";
import { clearAlert, setAlert } from "../../../states/AlertState";
import { useCreateJournalMutation, useGetJournalToUpdateQuery } from "../../../services/accounting/JournalService";
import { Table } from "../../../components/table";

interface JournalCreateProps {
  journalID?: any,
  modal?: MutableRefObject<any>,
}

const JournalCreate = (props: JournalCreateProps) => {
  const dispatch = useDispatch();
  const inputValidation = getValidationRules(); 

  const form = useRef<any>();
  const modal = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 20}).map(() =>  React.createRef()),
    []
  );
  const entryInputs: any = useMemo(
    () => Array.from({length: 100}).map(() =>  React.createRef()),
    []
  );

  const newJournalEntry = {account_id: undefined, debit: "0.00", credit: "0.00"};
  const entriesTotal = useRef<any>();

  const [formData, setFormData] = useState<any>({});

  const { data, isLoading, isSuccess, isError, error } = useGetJournalToUpdateQuery(props.journalID || -1);
  const [ createJournal ] = useCreateJournalMutation();

  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [accounts, setAccounts] = useState<any[]>([]);
  const [currencyList, setCurrencyList] = useState<any[]>([]);
  const [journalEntries, setJournalEntries] = useState<any[]>(Array.from({length: 2}).map(() => newJournalEntry));

  const [transactionCategories, setTransactionCategories] = useState<any[]>([
    {label: "Trip", value: "trip"},
    {label: "Expense", value: "expense"},
    {label: "Not Applicable", value: "other"},
  ]);


  const save = () => {
    if(!form?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    if(entriesTotal.current?.debit?.amount !== entriesTotal.current?.credit?.amount) {
      return dispatch(setAlert({type: "error", message: "Debit and Credit sides must be balanced."}));
    }
    
    let isEntryInputsError = false;
    journalEntries.forEach((entry, index) => {
      if(entry.debit > 0 || entry.credit > 0){
        if(!entryInputs[index].current?.validate())
          isEntryInputsError = true;
      }
      else{
        entryInputs[index].current?.clearValidation();
      }
    })
    if(isEntryInputsError)
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    modal?.current?.show(
      "Confirm Save",
      <ConfirmationDialog
      promptMessage="Are you sure want to save this Journal Info"
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Processing..."}));
        modal?.current?.hide();
        let formDt = {
          ...formData,
          amount: entriesTotal.current?.debit?.amount,
          debit: entriesTotal.current?.debit?.amount,
          credit: entriesTotal.current?.credit?.amount,
          status: "published",
          journal_entries: JSON.stringify(
            journalEntries.filter((entry) => (entry.debit > 0 || entry.credit > 0) && entry.account_id)
            .map(entry => ({...entry, account_category_id: undefined}))
          )
        }
        let FD: any = {};
        Object.keys(formDt).forEach((key) => {
          if(formDt[key]){
            FD[key] = typeof formDt[key]?.trim === "function" ? formDt[key]?.trim() : formDt[key];
          }
        });
        let FData = new FormData();
        Object.keys(FD).forEach((key) => {
          FData.append(key, FD[key]);
        });
        createJournal({data: FData, id: props.journalID})
        .unwrap()
        .then((response: any) => {
          if (props.journalID) {
            dispatch(setAlert({type: "success", message: "Journal Info has successfully saved", unreplaceable: true}));
          }
          else {
            dispatch(setAlert({type: "success", message: "Journal has successfully added", unreplaceable: true}));
          }
          setTimeout(() => {
            props.modal?.current?.hide();
          }, 1500);
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }

  const setEntriesTotal = (total: any) => {
    entriesTotal.current  = total;
  }

  const addEntryRow = () => {
    setJournalEntries([
      ...journalEntries,
      newJournalEntry,
    ])
  }

  const removeJournalEntry = (index: any) => {
    setJournalEntries(journalEntries.filter((entry, i) => i !== index))
  }

  const populateData = (data: any) => {
    setFormData({
      ...data.journal,
      payment_method: data.journal?.payment_method_id,
      currency_code: data.journal?.currency_code || "TZS",
      exchange_rate: data.journal?.exchange_rate || "1",
    });
    data?.journal?.journal_entries && setJournalEntries(data.journal.journal_entries);
    data?.payment_methods && setPaymentMethods(data.payment_methods.map((payment_method: any) => ({label: payment_method.payment_method_name, value: payment_method.payment_method_id, ...payment_method})));
    data?.accounts && setAccounts(data.accounts.map((account: any) => ({label: account.account_name, value: account.account_id, ...account})));
    data?.currency_list && setCurrencyList(data.currency_list.map((currency: any) => ({label: `${currency.currency_code} - ${currency.currency_name}`, value: currency.currency_code, ...currency})));
  }

  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);

  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])

  useEffect(() => {
    isSuccess &&  dispatch(clearAlert());
  }, [isSuccess]);

  useEffect(() => {
    data && populateData(data);
  }, [data])

  return (
    <>
      <div className="card scrollable y">
        <div className="card-body">
          <div>
            <Form ref={form}>
              <div className="row align-end">
                <div className="col-12 col-md-4">
                  <DateInput
                    ref={inputs[0]}
                    renderEndIcon={() => (
                      <span className="material-icons">calendar_today</span>
                    )}
                    label="Journal Date"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={getValidDateOrNull(formData.journal_date)}
                    onChange={(value: any) => {
                      setFormData({
                        ...formData,
                        journal_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[1]}
                    label="Journal Name"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={formData.journal_name || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      journal_name: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                     ref={inputs[2]}
                    label="Journal Number"
                    block={true}
                    disabled
                    requiredDecorator={true}
                    rules={[inputValidation.required, inputValidation.integer]}
                    value={padStart(formData.journal_number, 4, '0') || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      journal_number: parseInt(value)
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Reference Number"
                    block={true}
                    value={formData.reference || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      reference: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <div className="checkbox-group mt-2">
                    <div className="label">Journal Type:</div>
                    <Checkbox
                      label="Is Cash Based Journal Only"
                      checked={formData.is_cash_based_only == 1}
                      onChange={(event: any) => setFormData({
                        ...formData,
                        is_cash_based_only: event.target.checked ? "1" : "0"
                      })}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                    label="Transaction Type"
                    block={true}
                    options={transactionCategories}
                    value={transactionCategories.filter(category => category.value === (formData.transaction_category || "other"))}
                    onChange={(value) => setFormData({
                      ...formData,
                      transaction_category: value?.value,
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[3]}
                    label="Currency"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={currencyList}
                    value={currencyList.filter(currency => currency.value === (formData.currency_code || "TZS"))}
                    onChange={(value) => setFormData({
                      ...formData,
                      currency_code: value?.value,
                      exchange_rate: value?.exchange_rate,
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextArea
                    label="Notes"
                    block={true}
                    value={formData.notes || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      notes: value
                    })}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Table
                    className="no-table-striped"
                    hideIndex
                    noExport
                    noSearch
                    columns={[
                      {label: "Account", name: "account", customRender: true},
                      {label: `Debit${formData.currency_code ? ` (${formData.currency_code})` : ""}`, name: "debit", customRender: true},
                      {label: `Credit${formData.currency_code ? ` (${formData.currency_code})` : ""}`, name: "credit", customRender: true},
                      {label: "", name: "action", customRender: true},
                    ]}
                    items={journalEntries || []}
                    customRenders={[
                      {
                        columnName: "account",
                        render: (item, index) => (
                          <SelectInput
                            ref={entryInputs[index]}
                            block={true}
                            rules={[inputValidation.required]}
                            options={accounts}
                            value={accounts.filter(account => account.value === item.account_id)}
                            onChange={(value) => {
                              let entries = journalEntries.map(entry => ({...entry}));
                              entries[index].account_id = value?.value;
                              entries[index].account_category_id = value?.account_category_id;
                              entries[index].account_type_id = value?.account_type_id;
                              if(["1", "5"].includes(value?.account_category_id)){//If account is asset or expense
                                entries[index].amount = entries[index].credit > 0 ? (Math.abs(entries[index].amount) * -1) : Math.abs(entries[index].amount);
                              }
                              else{
                                entries[index].amount = entries[index].debit > 0 ? (Math.abs(entries[index].amount) * -1) : Math.abs(entries[index].amount);
                              }
                              setJournalEntries(entries);
                            }}
                          />
                        )
                      },
                      {
                        columnName: "debit",
                        render: (item, index) => (
                          <TextField
                            block={true}
                            disabled={item.credit && item.credit > 0}
                            value={item.debit && numberFormat(item.debit, 2) || ""}
                            onChange={(value) => {
                              let entries = journalEntries.map(entry => ({...entry}));
                              entries[index].debit = stripCommas(value);
                              entries[index].entry_type = "debit";
                              if(value && !["1", "5"].includes(entries[index].account_category_id)){//If account is not asset or expense
                                entries[index].amount = stripCommas(value) > 0 ? (Math.abs(stripCommas(value)) * -1) : Math.abs(stripCommas(value));
                              }
                              else{
                                entries[index].amount = Math.abs(stripCommas(value));
                              }
                              setJournalEntries(entries);
                            }}
                            onBlur={(value) => {
                              let entries = journalEntries.map(entry => ({...entry}));
                              if(value){
                                entries[index].debit = Math.abs(stripCommas(value));
                              }
                              else{
                                entries[index].debit = "0.00";
                              }
                              setJournalEntries(entries)
                            }}
                          />
                        )
                      },
                      {
                        columnName: "credit",
                        render: (item, index) => (
                          <TextField
                            block={true}
                            disabled={item.debit && item.debit > 0}
                            value={item.credit && numberFormat(item.credit, 2) || ""}
                            onChange={(value) => {
                              let entries = journalEntries.map(entry => ({...entry}));
                              entries[index].credit = stripCommas(value);
                              entries[index].entry_type = "credit";
                              if(value && ["1", "5"].includes(entries[index].account_category_id)){//If account is asset or expense
                                entries[index].amount = stripCommas(value) > 0 ? (Math.abs(stripCommas(value)) * -1) : Math.abs(stripCommas(value));
                              }
                              else{
                                entries[index].amount = Math.abs(stripCommas(value));
                              }
                              setJournalEntries(entries);
                            }}
                            onBlur={(value) => {
                              let entries = journalEntries.map(entry => ({...entry}));
                              if(value){
                                entries[index].credit = Math.abs(stripCommas(value));
                              }
                              else{
                                entries[index].credit = "0.00";
                              }
                              setJournalEntries(entries)
                            }}
                          />
                        )
                      },
                      {
                        columnName: "action",
                        render: (item, index) => (
                          <div className="d-flex align-center">
                            {index > 0 &&
                            <span
                              className="material-icons cursor-pointer danger-text text-large"
                              onClick={(e) => {
                                e.stopPropagation(); 
                                removeJournalEntry(index)
                              }}
                              title="Delete Record"
                            >
                              close
                            </span>}
                          </div>
                        )
                      }
                    ]}
                    totalColumns={[
                      { name: "debit" },
                      { name: "credit" }
                    ]}
                    totalColSpan={1}
                    setTotal={(total) => setEntriesTotal(total)}
                  />
                </div>
                <div className="col-12 d-flex justify-end">
                    <button
                      className="btn small bg-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        addEntryRow();
                      }}
                    >
                      <span className="material-icons">add</span> Add New Row
                    </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="card-footer d-flex flex-row">
          <button
            className="btn bg-secondary"
            onClick={() => save()}
          >
            {props.journalID ? "Save" : "Add Journal"}
          </button>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default JournalCreate;
