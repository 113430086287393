import { AppService } from '../../AppService';

export const TripDocumentTypeService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getTripDocumentTypes: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/trip-document-types",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'TripDocumentTypes', 'GetTripDocumentTypes'],
    }),
    getTripDocumentType: builder.query({
        query: (id) => ({
            url: `/trip-document-types/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'TripDocumentTypes', 'GetTripDocumentType'],
    }),
    getTripDocumentTypeToUpdate: builder.query({
        query: (id) => ({
            url: `/trip-document-types/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'TripDocumentTypes', 'GetTripDocumentTypeToUpdate'],
    }),
    createTripDocumentType: builder.mutation({
        query: (data = {}) => ({
            url: `/trip-document-types/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data,
        }),
       invalidatesTags: ['TripDocumentTypes'],
    }),
    deleteTripDocumentType: builder.mutation({
        query: (id) => ({
            url: `/trip-document-types/${id}`,
            method: "DELETE",
        }),
       invalidatesTags: ['GetTripDocumentTypes'],
    }),
  }),
});

export const {
    useGetTripDocumentTypesQuery,
    useGetTripDocumentTypeQuery,
    useGetTripDocumentTypeToUpdateQuery,
    useCreateTripDocumentTypeMutation,
    useDeleteTripDocumentTypeMutation,
} = TripDocumentTypeService;
