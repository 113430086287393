import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../../../components/modal";
import { Table } from "../../../../components/table";

import {
  DateInput,
  SelectInput,
} from "../../../../components/input";

import { useGetTripsQuery, useDeleteTripMutation } from "../../../../services/trips/TripService";
import { capitalize, formatDateForDb, getDatesOptions, numberFormat } from "../../../../utils/helpers/helpers";
import { clearFilters, setFilters } from "../../../../states/FiltersState";
import { clearAlert, setAlert } from "../../../../states/AlertState";
import { useGetPaymentsReceivedQuery } from "../../../../services/accounting/AccountingReportService";
import moment from "moment";
import { useGetCustomersQuery } from "../../../../services/customers/CustomerService";
interface TimeToGetPaidProps {
  exportTitle?: string,
  vehicle?: any,
  employee?: any,
  customer?: any,
  cargo?: any,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const TimeToGetPaid = (props: TimeToGetPaidProps) => {
  const filters = useSelector((state: any) => state.filtersState);
  const dispatch = useDispatch();

  const modal = useRef<any>();

  const [ listenData, setListenData ] = useState(true);
  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetPaymentsReceivedQuery(filters, { skip: !((filters?.report_type == "time_to_get_paid") && listenData) });

  const { data: customers } = useGetCustomersQuery({});
  const [customerList, setCustomerList] = useState<any>([]);

  useEffect(() => {
    dispatch(setFilters({
      report_type: "time_to_get_paid",
      date_filter_group: "annually",
      date_filter_type: "accumulated",
      limit: 1000000000
    }))
  }, [])

  useEffect(() => {
    customers?.customers?.length && setCustomerList(customers?.customers?.map((customer: any) => ({label: customer.first_name, value: customer.customer_id, ...customer})));
  }, [customers])

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [])

  return (
    <>
      <div className="row shadow bg-white p-2 mb-1">
      <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Report Date"
            block={true}
            options={getDatesOptions()}
            value={getDatesOptions().find(date => date.name === filters.annually_report_date_name)}
            onChange={(value) => dispatch(setFilters({
              annually_report_from_date: value?.name !== "custom" ? (value?.name === "previous_year" ? moment().subtract(1, "years").startOf("year").format("YYYY-MM-DD HH:mm:ss") : moment().startOf("year").format("YYYY-MM-DD HH:mm:ss")): filters.annually_report_from_date,
              annually_report_to_date: value?.name !== "custom" ? value?.to_date : filters.annually_report_to_date,
              annually_report_date_name: value?.name,
            }))}
          />
        </div>
        {filters.annually_report_date_name === "custom" &&
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Custom Report Date"
            block={true}
            value={new Date(filters.annually_report_to_date)}
            onChange={(value) => {
              dispatch(setFilters({
                annually_report_from_date: formatDateForDb(moment(value).startOf("year").format("YYYY-MM-DD HH:mm:ss")),
                annually_report_to_date: formatDateForDb(value),
              }))
            }}
          />
        </div>}
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Customer"
            block={true}
            clearable
            options={customerList}
            value={customerList.find((customer: any) => customer.value == filters.customer)}
            onChange={(value) => dispatch(setFilters({
              customer: value?.value
            }))}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
            <div>
              <Table
                loading={isLoading}
                title={<span>Time to Get Paid - <span className="text-small">AS of {moment(filters.annually_report_to_date).format("MMM DD, YYYY")}</span></span>}
                exportTitle={props.exportTitle}
                downloadFileName={props.exportTitle || `Time to Get Paid - AS of ${moment(filters.annually_report_to_date).format("MMM DD, YYYY")}`}
                // onRowClick={(item) => showDetailedReport(item)}
                columns={[
                  {
                    label: "Customer Name",
                    name: "customer_name",
                    customRender: true,
                    valueGetter: (item) =>  capitalize(item.customer_name),
                  },
                  {
                    label: "0-30 Days",
                    name: "payments_age_1",
                    customRender: true,
                    valueGetter: (item) =>  `${numberFormat((item.payments_age_1/item.payment_transactions_count) * 100, 2)}%`,
                  },
                  {
                    label: "31-60 Days",
                    name: "payments_age_2",
                    customRender: true,
                    valueGetter: (item) =>  `${numberFormat((item.payments_age_2/item.payment_transactions_count) * 100, 2)}%`,
                  },
                  {
                    label: "61-90 Days",
                    name: "payments_age_3",
                    customRender: true,
                    valueGetter: (item) =>  `${numberFormat((item.payments_age_3/item.payment_transactions_count) * 100, 2)}%`,
                  },
                  {
                    label: "> 90 Days",
                    name: "payments_age_4",
                    customRender: true,
                    valueGetter: (item) =>  `${numberFormat((item.payments_age_4/item.payment_transactions_count) * 100, 2)}%`,
                  },
                ]}
                items={data?.entries || []}
                onSearch={(search) => dispatch(setFilters({search: search}))}
                // pagination={{
                //   ...(data?.pagination || {}),
                //   onPageChange: (page) => dispatch(setFilters({page: page})),
                //   onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
                // }}
                params={filters}
                customRenders={[
                  {
                    columnName: "customer_name",
                    render: (item, index) => <span>{capitalize(item.customer_name)}</span>
                  },
                  {
                    columnName: "payments_age_1",
                    render: (item) => <span>{numberFormat((item.payments_age_1/item.payment_transactions_count) * 100, 2)}%</span>
                  },
                  {
                    columnName: "payments_age_2",
                    render: (item) => <span>{numberFormat((item.payments_age_2/item.payment_transactions_count) * 100, 2)}%</span>
                  },
                  {
                    columnName: "payments_age_3",
                    render: (item) => <span>{numberFormat((item.payments_age_3/item.payment_transactions_count) * 100, 2)}%</span>
                  },
                  {
                    columnName: "payments_age_4",
                    render: (item) => <span>{numberFormat((item.payments_age_4/item.payment_transactions_count) * 100, 2)}%</span>
                  },
                ]}
              />
            </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default TimeToGetPaid;
