import React, { MutableRefObject, useMemo, useRef, useState } from "react";
import { Form } from "../../../components/form";
import {
  Checkbox,
  TextField
} from "../../../components/input";
import { ConfirmationDialog, Modal } from "../../../components/modal";
import { getValidationRules } from '../../../utils/helpers/helpers';
import { useDispatch } from "react-redux";
import { setAlert } from "../../../states/AlertState";
import { useChangePasswordMutation } from "../../../services/users/UserService";
import { setChangePassword } from "../../../states/GeneralState";

interface PasswordChangeProps {
  modal?: MutableRefObject<any>,
  user?: {[rest: string]: any}
}

const PasswordChange = (props: PasswordChangeProps) => {
  const dispatch = useDispatch();
  const inputValidation = getValidationRules(); 

  const form = useRef<any>();
  const modal = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 3}).map(() =>  React.createRef()),
    []
  )

  const [formData, setFormData] = useState<any>({});
  const [ ChangePassword ] = useChangePasswordMutation();
  const [ showPassword, setShowPassword ] = useState(false);

  const save = () => {
    if(!form?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    modal?.current?.show(
      "Confirm Password Change",
      <ConfirmationDialog
      promptMessage="Are you sure want to change password"
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Please Wait..."}));
        modal?.current?.hide();
        let FD: any = {};
        Object.keys(formData).forEach((key) => {
         FD[key] = typeof formData[key]?.trim === "function" ? formData[key]?.trim() : formData[key];
        });
        FD = {
          ...FD,
          username: props.user?.username,
        }
        ChangePassword({...FD, id: props.user?.user_id})
        .unwrap()
        .then((response: any) => {
            if(response?.success){
                dispatch(setAlert({type: "success", message: "Password has successfully changed"}));
                setTimeout(() => {
                  dispatch(setChangePassword(false));
                  props.modal?.current?.hide();
                }, 1500);
            }
            else{
                dispatch(setAlert({type: "error", message: response.errors || "Failed to change password"}));
            }
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }

  return (
    <>
      <div className="card scrollable y">
        <div className="card-body">
          <div>
            <Form ref={form}>
              <div className="row">
                {props.user?.username &&
                <div className="col-12">
                  <TextField
                    label="Username"
                    block={true}
                    disabled
                    renderStartIcon={() => <span className="material-icons grey-text">person</span>}
                    value={props.user?.username}
                  />
                </div>}
                {!props.user?.username &&
                <div className="col-12">
                  <TextField
                    ref={inputs[0]}
                    label="Current Password"
                    block={true}
                    type={showPassword ? "text" : "password"}                    requiredDecorator={true}
                    rules={[
                      inputValidation.required,
                      // (value: any) => inputValidation.password(value, "Please enter a valid Current Password")
                    ]}
                    onChange={(value) => setFormData({
                      ...formData,
                      current_password: value
                    })}
                    renderStartIcon={() => <span className="material-icons grey-text">lock</span>}
                  />
                </div>}
                <div className="col-12">
                  <TextField
                    ref={inputs[1]}
                    label="New Password"
                    block={true}
                    type={showPassword ? "text" : "password"}
                    requiredDecorator={true}
                    rules={[inputValidation.required, inputValidation.password]}
                    onChange={(value) => setFormData({
                      ...formData,
                      new_password: value
                    })}
                    renderStartIcon={() => <span className="material-icons grey-text">lock</span>}
                  />
                </div>
                <div className="col-12">
                  <TextField
                    ref={inputs[2]}
                    label="Confirm New Password"
                    block={true}
                    type={showPassword ? "text" : "password"}
                    requiredDecorator={true}
                    rules={[
                      inputValidation.required,
                      (value: any) => value === formData.new_password || "New Passwords do not match"
                    ]}
                    onChange={(value) => setFormData({
                      ...formData,
                      confirm_new_password: value
                    })}
                    renderStartIcon={() => <span className="material-icons grey-text">lock</span>}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="card-footer d-flex justify-space-between">
          <button
            className="btn bg-secondary"
            onClick={() => save()}
          >
            Change Password
          </button>
          <Checkbox
            label="Show Passwords"
            onChange={() => setShowPassword(!showPassword)}
          />
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default PasswordChange;
