import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../../../components/modal";
import { Table } from "../../../../components/table";

import {
  DateInput,
  SelectInput,
} from "../../../../components/input";
import { capitalize, formatDateForDb, getDatesOptions, numberFormat, padStart } from "../../../../utils/helpers/helpers";
import { clearFilters, setFilters } from "../../../../states/FiltersState";
import { clearAlert, setAlert } from "../../../../states/AlertState";
import { useGetPaymentsReceivedQuery } from "../../../../services/accounting/AccountingReportService";
import moment from "moment";
import { useGetCustomersQuery } from "../../../../services/customers/CustomerService";
import { useGetAccountsQuery } from "../../../../services/accounting/AccountService";
interface PaymentsReceivedProps {
  exportTitle?: string,
  vehicle?: any,
  employee?: any,
  customer?: any,
  cargo?: any,
  aging_by?: any,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const PaymentsReceived = (props: PaymentsReceivedProps) => {
  const filters = useSelector((state: any) => state.filtersState);
  const dispatch = useDispatch();

  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetPaymentsReceivedQuery(filters, { skip: !(filters?.report_type == "payments_received") });

  const { data: customers } = useGetCustomersQuery({});
  const { data: accounts } = useGetAccountsQuery({});
  const [customerList, setCustomerList] = useState<any>([]);
  const [accountList, setAccountList] = useState<any>([]);
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);

  useEffect(() => {
    dispatch(setFilters({
      report_type: "payments_received",
      customer: props.customer,
    }))
  }, [])

  useEffect(() => {
    customers?.customers?.length && setCustomerList(customers?.customers?.map((customer: any) => ({label: customer.first_name, value: customer.customer_id, ...customer})));
  }, [customers])

  useEffect(() => {
    accounts?.accounts?.length && setAccountList(accounts?.accounts?.filter((account: any) => ["1", "2"].includes(account.account_type_id))
      ?.map((account: any) => ({label: account.account_name, value: account.account_id, ...account})));
  }, [accounts])

  useEffect(() => {
    data?.payment_methods && setPaymentMethods(data.payment_methods.map((payment_method: any) => ({label: payment_method.payment_method_name, value: payment_method.payment_method_id})));
  }, [data])

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [])

  let columns = [
    {
      label: "Date",
      name: "payment_date",
      customRender: true,
      valueGetter: (item: any) =>  formatDateForDb(item.payment_date),
    },
    {
      label: "Payment#",
      name: "payment_number",
      customRender: true,
      valueGetter: (item: any) =>  padStart(item.payment_number, 4, "0"),
    },
    {
      label: "Customer Name",
      name: "customer_name",
      customRender: true,
      valueGetter: (item: any) =>  capitalize(item.customer_name),
    },
    {
      label: "Payment Mode",
      name: "payment_method_name",
      customRender: true,
      valueGetter: (item: any) =>  capitalize(item.payment_method_name),
    },
    {
      label: "Notes",
      name: "comment",
    },
    {
      label: "Invoice#",
      name: "invoice_number",
      customRender: true,
      valueGetter: (item: any) =>  padStart(item.invoice_number, 4, '0'),
    },
    {
      label: "Deposit To",
      name: "account_name",
      customRender: true,
      valueGetter: (item: any) =>  capitalize(item.account_name),
    },
    {
      label: "Total",
      name: "amount",
      money: true,
      customRender: true,
      valueGetter: (item: any) =>  numberFormat(item.amount, 2, true),
    },
  ]

  return (
    <>
      <div className="row shadow bg-white p-2 mb-1">
      <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Report Date"
            block={true}
            options={getDatesOptions()}
            value={getDatesOptions().find(date => date.name === filters.monthly_report_date_name)}
            onChange={(value) => dispatch(setFilters({
              monthly_report_from_date: value?.name !== "custom" ? value?.from_date : filters.monthly_report_from_date,
              monthly_report_to_date: value?.name !== "custom" ? value?.to_date : filters.monthly_report_to_date,
              monthly_report_date_name: value?.name,
            }))}
          />
        </div>
        {filters.monthly_report_date_name === "custom" &&
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Custom Start Date"
            block={true}
            value={new Date(filters.monthly_report_from_date)}
            onChange={(value) => {
              dispatch(setFilters({
                monthly_report_from_date: formatDateForDb(value),
              }))
            }}
          />
        </div>}
        {filters.monthly_report_date_name === "custom" &&
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Custom End Date"
            block={true}
            value={new Date(filters.monthly_report_to_date)}
            onChange={(value) => {
              dispatch(setFilters({
                monthly_report_to_date: formatDateForDb(value),
              }))
            }}
          />
        </div>}
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Customer"
            block={true}
            clearable
            options={customerList}
            value={customerList.find((customer: any) => customer.value == filters.customer)}
            onChange={(value) => dispatch(setFilters({
              customer: value?.value
            }))}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Payment Mode"
            block={true}
            clearable
            options={paymentMethods}
            value={paymentMethods.find((payment_method: any) => payment_method.value == filters.payment_method)}
            onChange={(value) => dispatch(setFilters({
              payment_method: value?.value
            }))}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Deposit To"
            block={true}
            clearable
            options={accountList}
            value={accountList.find((account: any) => account.value == filters.deposit_account)}
            onChange={(value) => dispatch(setFilters({
              deposit_account: value?.value
            }))}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
            <div>
              <Table
                loading={isLoading}
                title={<span>Payments Received - <span className="text-small">From {moment(filters.monthly_report_from_date).format("MMM DD, YYYY")}  To  {moment(filters.monthly_report_to_date).format("MMM DD, YYYY")}</span></span>}
                exportTitle={props.exportTitle}
                downloadFileName={props.exportTitle || `Payments Received - From ${moment(filters.monthly_report_from_date).format("MMM DD, YYYY")}  To  ${moment(filters.monthly_report_to_date).format("MMM DD, YYYY")}`}
                columns={columns}
                items={data?.entries || []}
                onSearch={(search) => dispatch(setFilters({search: search}))}
                pagination={{
                  ...(data?.pagination || {}),
                  onPageChange: (page) => dispatch(setFilters({page: page})),
                  onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
                }}
                params={filters}
                customRenders={[
                  {
                    columnName: "payment_date",
                    render: (item, index) => <span>{formatDateForDb(item.payment_date)}</span>
                  },
                  {
                    columnName: "payment_number",
                    render: (item, index) => <span>{padStart(item.payment_number, 4, "0")}</span>
                  },
                  {
                    columnName: "invoice_number",
                    render: (item, index) => <span>{padStart(item.invoice_number, 4, '0')}</span>
                  },
                  {
                    columnName: "customer_name",
                    render: (item, index) => <span>{capitalize(item.customer_name)}</span>
                  },
                  {
                    columnName: "payment_method_name",
                    render: (item, index) => <span>{capitalize(item.payment_method_name)}</span>
                  },
                  {
                    columnName: "account_name",
                    render: (item, index) => <span>{capitalize(item.account_name)}</span>
                  },
                  {
                    columnName: "amount",
                    render: (item) => <span>{numberFormat(item.amount, 2, true)}</span>
                  },
                ]}
                totalColumns={[
                  { name: "amount" },
                ]}
                decimalPlaces={2}
                showZeroDecimalPlaces
                money
                totalColSpan={7}
              />
            </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default PaymentsReceived;
