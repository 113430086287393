import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../../../components/modal";
import { Table } from "../../../../components/table";

import {
  DateInput,
  SelectInput,
} from "../../../../components/input";

import { capitalize, formatDateForDb, getDatesOptions, numberFormat, padStart } from "../../../../utils/helpers/helpers";
import { clearFilters, setFilters } from "../../../../states/FiltersState";
import { clearAlert, setAlert } from "../../../../states/AlertState";
import { useGetReceivablesQuery } from "../../../../services/accounting/AccountingReportService";
import moment from "moment";
import { useGetCustomersQuery } from "../../../../services/customers/CustomerService";
interface ARAgingDetailsProps {
  exportTitle?: string,
  vehicle?: any,
  employee?: any,
  customer?: any,
  cargo?: any,
  aging_by?: any,
  document_type?: any,
  annually_report_from_date?: any,
  annually_report_to_date?: any,
  annually_report_date_name?: any,
  setListenData?: any,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const ARAgingDetails = (props: ARAgingDetailsProps) => {
  const filters = useSelector((state: any) => state.filtersState);
  const dispatch = useDispatch();

  const modal = useRef<any>();

  const [ listenData, setListenData ] = useState(false);
  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetReceivablesQuery(
    filters, { skip: !((filters?.report_type == "ar_aging_details") && listenData) }
  );

  const { data: customers } = useGetCustomersQuery({});
  const [customerList, setCustomerList] = useState<any>([]);
  const [reportData, setReportData] = useState<any[]>([]);

  const [entities, setEntities] = useState([
    {label: "Invoice", value: "invoice"},
    {label: "Journal", value: "journal"},
  ]);

  const [agingStarts, setAgingStarts] = useState([
    {label: "Invoice Due Date", value: "invoice_due_date"},
    {label: "Invoice Date", value: "invoice_date"},
  ]);

  const getTitleObject = (title: string, level: string = "") => {
    return {
      title: capitalize(title),
      isTitle: true,
      [`isSection${capitalize(level)}Title`]: true,
    }
  }

  const getTotalObject = (totalObject: any, label: string, level: string = "") => {
    return {
      title: capitalize(label),
      total_payment_amount: totalObject.total_amount,
      total_payment_amount_due: totalObject.total_balance,
      isTotal: true,
      [`isSection${capitalize(level)}Total`]: true,
    }
  }

  const populateData = () => {
    let groupedEntries: any = {
      age_1: {total_amount: 0, total_balance: 0, entries: []},
      age_2: {total_amount: 0, total_balance: 0, entries: []},
      age_3: {total_amount: 0, total_balance: 0, entries: []},
      age_4: {total_amount: 0, total_balance: 0, entries: []},
      age_5: {total_amount: 0, total_balance: 0, entries: []},
    }

    let total_object: any = {
      total_amount: 0,
      total_balance: 0,
    }

    data?.entries?.forEach((entry: any) => {
      if(entry.age < 1){
        groupedEntries.age_1.entries.push(entry);
        groupedEntries.age_1.total_amount += (parseFloat(entry.total_payment_amount) || 0);
        groupedEntries.age_1.total_balance += (parseFloat(entry.total_payment_amount_due) || 0);
      }
      else if(entry.age >= 1 && entry.age <= 30){
        groupedEntries.age_2.entries.push(entry);
        groupedEntries.age_2.total_amount += (parseFloat(entry.total_payment_amount) || 0);
        groupedEntries.age_2.total_balance += (parseFloat(entry.total_payment_amount_due) || 0);
      }
      else if(entry.age >= 31 && entry.age <= 60){
        groupedEntries.age_3.entries.push(entry);
        groupedEntries.age_3.total_amount += (parseFloat(entry.total_payment_amount) || 0);
        groupedEntries.age_3.total_balance += (parseFloat(entry.total_payment_amount_due) || 0);
      }
      else if(entry.age >= 61 && entry.age <= 90){
        groupedEntries.age_4.entries.push(entry);
        groupedEntries.age_4.total_amount += (parseFloat(entry.total_payment_amount) || 0);
        groupedEntries.age_4.total_balance += (parseFloat(entry.total_payment_amount_due) || 0);
      }
      else{
        groupedEntries.age_5.entries.push(entry);
        groupedEntries.age_5.total_amount += (parseFloat(entry.total_payment_amount) || 0);
        groupedEntries.age_5.total_balance += (parseFloat(entry.total_payment_amount_due) || 0);
      }
    })

    Object.values(groupedEntries).forEach((group: any) => {
      total_object.total_amount += group.total_amount;
      total_object.total_balance += group.total_balance;
    })

    setReportData([
      ...(groupedEntries.age_5.total_balance > 0 ? [getTitleObject("> 90 Days", "Minor")] : []),
      ...groupedEntries.age_5.entries,
      ...(groupedEntries.age_5.total_balance > 0 ? [getTotalObject(groupedEntries.age_5, "Subtotal", "Sub")] : []),
      ...(groupedEntries.age_4.total_balance > 0 ? [getTitleObject("61-90 Days", "Minor")] : []),
      ...groupedEntries.age_4.entries,
      ...(groupedEntries.age_4.total_balance > 0 ? [getTotalObject(groupedEntries.age_4, "Subtotal", "Sub")] : []),
      ...(groupedEntries.age_3.total_balance > 0 ? [getTitleObject("31-60 Days", "Minor")] : []),
      ...groupedEntries.age_3.entries,
      ...(groupedEntries.age_3.total_balance > 0 ? [getTotalObject(groupedEntries.age_3, "Subtotal", "Sub")] : []),
      ...(groupedEntries.age_2.total_balance > 0 ? [getTitleObject("1-30 Days", "Minor")] : []),
      ...groupedEntries.age_2.entries,
      ...(groupedEntries.age_2.total_balance > 0 ? [getTotalObject(groupedEntries.age_2, "Subtotal", "Sub")] : []),
      ...(groupedEntries.age_1.total_balance > 0 ? [getTitleObject("Current", "Minor")] : []),
      ...groupedEntries.age_1.entries,
      ...(groupedEntries.age_1.total_balance > 0 ? [getTotalObject(groupedEntries.age_1, "Subtotal", "Sub")] : []),

      getTotalObject(total_object, "Total"),
    ]);
  }

  useEffect(() => {
    populateData();
  }, [data])

  useEffect(() => {
    dispatch(setFilters({
      aging_by: props.aging_by || "invoice_due_date",
      document_type: props.document_type || "receivables",
      report_type: "ar_aging_details",
      customer: props.customer,
      annually_report_from_date: props.annually_report_from_date,
      annually_report_to_date: props.annually_report_to_date,
      annually_report_date_name: props.annually_report_date_name,
      date_filter_group: "annually",
      date_filter_type: "accumulated",
      limit: 1000000000
    }))
    props.setListenData(false);
    setListenData(true);
  }, [])

  useEffect(() => {
    customers?.customers?.length && setCustomerList(customers?.customers?.map((customer: any) => ({label: customer.first_name, value: customer.customer_id, ...customer})));
  }, [customers])

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      // dispatch(clearFilters())
      dispatch(setFilters({
        aging_by: props.aging_by || "invoice_due_date",
        document_type: props.document_type || "receivables",
        report_type: "ar_aging_summary",
        customer: undefined,
        annually_report_from_date: props.annually_report_from_date,
        annually_report_to_date: props.annually_report_to_date,
        annually_report_date_name: props.annually_report_date_name,
      }))
      props.setListenData(true);
    }
  }, [])

  let columns = [
    {
      label: "Date",
      name: "transaction_date",
      customRender: true,
      valueGetter: (item: any) =>  item.title || formatDateForDb(item.transaction_date),
      className: (item: any, index: number, column: any) => item.isSectionTitle ? "bg-primary text-large text-center text-uppercase" : (item.isSectionSubTitle ? "bg-d text-medium" : (item.isSectionMinorTitle ? "bg-secondary text-center" : "")),
      colSpan: (item: any, index: number, column: any) => item.isTitle && 8,
      flex: (item: any, index: number, column: any) => item.isSectionTitle ? 8 : 1,
    },
    {
      label: "Transaction#",
      name: "transaction_number",
      customRender: true,
      valueGetter: (item: any) =>  item.transaction_type == "credit" ? padStart(item.invoice_number, 4, '0') : item.journal_number,
    },
    {
      label: "Type",
      name: "type",
      customRender: true,
      valueGetter: (item: any) =>  item.isTotal ? "" : (item.transaction_type == "credit" ? "Invoice" : "Journal"),
    },
    {
      label: "Status",
      name: "aging_status",
      customRender: true,
      valueGetter: (item: any) =>  capitalize(item.aging_status),
    },
    {
      label: "Customer Name",
      name: "customer_name",
      customRender: true,
      valueGetter: (item: any) =>  capitalize(item.customer_name),
    },
    {
      label: "Age",
      name: "age",
      customRender: true,
      valueGetter: (item: any) =>  item.age >= 1 && `${item.age} Days`,
    },
    {
      label: "Amount",
      name: "total_payment_amount",
      money: true,
      customRender: true,
      valueGetter: (item: any) =>  numberFormat(item.total_payment_amount, 2, true),
    },
    {
      label: "Balance Due",
      name: "total_payment_amount_due",
      money: true,
      customRender: true,
      valueGetter: (item: any) =>  numberFormat(item.total_payment_amount_due, 2, true),
    },
  ]

  return (
    <>
      <div className="row shadow bg-white p-2 mb-1">
      <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Report Date"
            block={true}
            options={getDatesOptions()}
            value={getDatesOptions().find(date => date.name === filters.annually_report_date_name)}
            onChange={(value) => dispatch(setFilters({
              annually_report_from_date: value?.name !== "custom" ? (value?.name === "previous_year" ? moment().subtract(1, "years").startOf("year").format("YYYY-MM-DD HH:mm:ss") : moment().startOf("year").format("YYYY-MM-DD HH:mm:ss")): filters.annually_report_from_date,
              annually_report_to_date: value?.name !== "custom" ? value?.to_date : filters.annually_report_to_date,
              annually_report_date_name: value?.name,
            }))}
          />
        </div>
        {filters.annually_report_date_name === "custom" &&
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Custom Report Date"
            block={true}
            value={new Date(filters.annually_report_to_date)}
            onChange={(value) => {
              dispatch(setFilters({
                annually_report_from_date: formatDateForDb(moment(value).startOf("year").format("YYYY-MM-DD HH:mm:ss")),
                annually_report_to_date: formatDateForDb(value),
              }))
            }}
          />
        </div>}
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Aging By"
            block={true}
            options={agingStarts}
            value={agingStarts.find(agingStarts => agingStarts.value === filters.aging_by)}
            onChange={(value) => dispatch(setFilters({
              aging_by: value?.value,
            }))}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Entities"
            block={true}
            clearable
            options={entities}
            value={entities.find(entity => entity.value === filters.document_type)}
            onChange={(value) => dispatch(setFilters({
              document_type: value?.value,
            }))}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Customer"
            block={true}
            clearable
            options={customerList}
            value={customerList.find((customer: any) => customer.value == filters.customer)}
            onChange={(value) => dispatch(setFilters({
              customer: value?.value
            }))}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
            <div>
              <Table
              hideIndex
                loading={isLoading}
                title={<span>AR Aging Details By {capitalize(filters.aging_by?.split("_")?.join(" "))} - <span className="text-small">AS of {moment(filters.annually_report_to_date).format("MMM DD, YYYY")}</span></span>}
                exportTitle={props.exportTitle}
                downloadFileName={props.exportTitle || `AR Aging Details By ${capitalize(filters.aging_by?.split("_")?.join(" "))} - AS of ${moment(filters.annually_report_to_date).format("MMM DD, YYYY")}`}
                columns={columns}
                items={reportData || []}
                onSearch={(search) => dispatch(setFilters({search: search}))}
                // pagination={{
                //   ...(data?.pagination || {}),
                //   onPageChange: (page) => dispatch(setFilters({page: page})),
                //   onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
                // }}
                params={filters}
                customRenders={[
                  {
                    columnName: "transaction_date",
                    getClassName: (item) => (item.isSectionMinorTotal ? "text-bold" : "") + (item.isSectionSubTotal ? "text-bold bg-info text-left" : "") + (item.isSectionTotal ? "text-bold bg-d text-large" : "") + (item.labelClassName || ""),
                    render: (item, index) => <span>{item.title || formatDateForDb(item.transaction_date)}</span>
                  },
                  {
                    columnName: "transaction_number",
                    getClassName: (item) => (item.isSectionMinorTotal ? "text-bold" : "") + (item.isSectionSubTotal ? "text-bold bg-info text-center" : "") + (item.isSectionTotal ? "text-bold bg-d text-large" : "") + (item.valueClassName || ""),
                    render: (item, index) => <span>{item.transaction_type == "credit" ? padStart(item.invoice_number, 4, '0') : item.journal_number}</span>
                  },
                  {
                    columnName: "type",
                    getClassName: (item) => (item.isSectionMinorTotal ? "text-bold" : "") + (item.isSectionSubTotal ? "text-bold bg-info text-center" : "") + (item.isSectionTotal ? "text-bold bg-d text-large" : "") + (item.valueClassName || ""),
                    render: (item, index) => <span>{item.isTotal ? "" : (item.transaction_type == "credit" ? "Invoice" : "Journal")}</span>
                  },
                  {
                    columnName: "aging_status",
                    getClassName: (item) => (item.isSectionMinorTotal ? "text-bold" : "") + (item.isSectionSubTotal ? "text-bold bg-info text-center" : "") + (item.isSectionTotal ? "text-bold bg-d text-large" : "") + (item.valueClassName || ""),
                    render: (item, index) => {
                      let aging_status_class = item.aging_status == "Overdue" ? "bg-danger" : (item.aging_status === "Partially Paid" ? "bg-success" : (item.aging_status == "Unpaid" ? "bg-warning" : "bg-info"));
                    return <span className={`badge ${aging_status_class} text-xsmall`}>{capitalize(item.aging_status)}</span>
                    }
                  },
                  {
                    columnName: "customer_name",
                    getClassName: (item) => (item.isSectionMinorTotal ? "text-bold" : "") + (item.isSectionSubTotal ? "text-bold bg-info text-center" : "") + (item.isSectionTotal ? "text-bold bg-d text-large" : "") + (item.valueClassName || ""),
                    render: (item, index) => <span>{capitalize(item.customer_name)}</span>
                  },
                  {
                    columnName: "age",
                    getClassName: (item) => (item.isSectionMinorTotal ? "text-bold" : "") + (item.isSectionSubTotal ? "text-bold bg-info text-center" : "") + (item.isSectionTotal ? "text-bold bg-d text-large" : "") + (item.valueClassName || ""),
                    render: (item, index) => <span>{item.age >= 1 && `${item.age} Days`}</span>
                  },
                  {
                    columnName: "total_payment_amount",
                    getClassName: (item) => (item.isSectionMinorTotal ? "text-bold" : "") + (item.isSectionSubTotal ? "text-bold bg-info text-center" : "") + (item.isSectionTotal ? "text-bold bg-d text-large" : "") + (item.valueClassName || ""),
                    render: (item) => <span>{numberFormat(item.total_payment_amount, 2, true)}</span>
                  },
                  {
                    columnName: "total_payment_amount_due",
                    getClassName: (item) => (item.isSectionMinorTotal ? "text-bold" : "") + (item.isSectionSubTotal ? "text-bold bg-info text-center" : "") + (item.isSectionTotal ? "text-bold bg-d text-large" : "") + (item.valueClassName || ""),
                    render: (item) => <span>{numberFormat(item.total_payment_amount_due, 2, true)}</span>
                  },
                ]}
                // totalColumns={[
                //   { name: "total_payment_amount" },
                //   { name: "total_payment_amount_due" },
                // ]}
                // decimalPlaces={2}
                // showZeroDecimalPlaces
                // totalColSpan={7}
              />
            </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default ARAgingDetails;
