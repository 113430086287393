import React, { ReactNode, useEffect, useRef, useState } from "react";
import ReactToPrint, {useReactToPrint} from "react-to-print";
// import Html2Pdf from "js-html2pdf";
import html2Pdf from "html2pdf.js";
import { getNoPermissionMessage, numberFormat, stripCommas } from "../../utils/helpers/helpers";
import Pagination from "../pagination/Pagination";
import { SelectInput, TextField } from "../input";
import SpreadsheetReport from "../reports/SpreadsheetReport";
import PDFReport from "../reports/PDFReport";
import ReportHeader from "../reports/ReportHeader";
import ReportFooter from "../reports/ReportFooter";
import { useDispatch } from "react-redux";
import { setAlert } from "../../states/AlertState";
interface TableProps {
  value?: any,
  defaultValue?: any,
  columns?: {
    label?: any,
    name: string,
    customRender?: boolean,
    style?: {},
    money?: boolean,
    onClick?: (item: any, index: number, event: any, column: any) => void,
    className?: string | ((item: any, index: number, column: any) => void),
    colSpan?: number | ((item: any, index: number, column: any) => void),
    show?: boolean,
    webOnly?: boolean,
    valueGetter?: (item: any, index: number, column: any) => void,
  }[],
  hideIndex?: boolean,
  indexStyle?: {},
  getIndexColSpan?: (element: any, index: number) => number,
  getIndexClassName?: (element: any, index: number) => string,
  onIndexClick?: (item: any, index: number) => void,
  indexHeadCellLabel?: string,
  indexHeadCellClassName?: string,
  indexHeadCellTitle?: string,
  indexHeadCellStyle?: {},
  columnGroups?: {}[],
  items?: any[],
  onRowClick?: (item: any, index: number) => void,
  renderCaption?: () => ReactNode,
  getRowClassName?: (element: any, index: number) => string,
  customRenders?: {
    columnName: string,
    render: (item: any, index: number) => ReactNode,
    getClassName?: (item: any, index: number) => string,
  }[],
  noHeaderRow?: boolean,
  loading?: boolean,
  className?: string,
  wrapperClassName?: string,
  tableHeadClassName?: string,
  tableCellClassName?: string,
  scrollY?: boolean,
  stickyHeader?: boolean,
  wrapperStyle?: {}
  expanded?: any,
  renderExpanded?: (item: any, index: number) => ReactNode,
  totalColumns?: {
    name: string,
    value?: any,
  }[],
  totalColSpan?: number,
  decimalPlaces?: number,
  showZeroDecimalPlaces?: boolean,
  money?: boolean,
  setTotal?: (total: {}) => void,
  hideTotalRow?: boolean,
  renderFooter?: () => ReactNode,
  title?: any,
  exportTitle?: string,
  downloadFileName?: string,
  actions?: ReactNode[],
  params?: any,
  onSearch?: (page: any) => void,
  pagination?: {
    onPageChange: (page: number) => void,
    onPageLimitChange: (pageLimit: number) => void,
    [rest: string]: any,
  },
  exportFormats?: string[],
  noSearch?: boolean,
  noExport?: boolean,
  noTitle?: boolean,
  permission?: any,  
};

const Table = (props: TableProps) => {
  const dispatch = useDispatch();
  const [printCount, setPrintCount] = useState(0);
  const [columns, setColumns] = useState<any[]>([]);
  const columnGroups = (props.columnGroups || []).filter(
    (e: any) => typeof e.show === "undefined" || e.show
  );

  const printRef = useRef<any>();
  const [reLoadColumns, setReLoadColumns] = useState(0);
  
  const [pageLimits, setPageLimits] = useState([
    {label: 10, value: 10},
    {label: 25, value: 25},
    {label: 50, value: 50},
    {label: 100, value: 100},
    {label: 250, value: 250},
    {label: 500, value: 500},
    {label: "All", value: 1000000000},
  ]);

  const renderTotal = (data: any, totalColumns: any, columns: any = [], totalColSpan: any = 1) => {
    let totalObjects: any = {};
    totalColumns?.forEach((item: any) => {
      let correspondingColumn = columns.find((val: any) => val.name == item.name);
      totalObjects[item.name] = {
        amount: item.value || data?.reduce((acc: number, curr: any) => {
          return correspondingColumn?.valueGetter
            ? acc +
                parseFloat(
                  stripCommas(correspondingColumn.valueGetter(curr)) || 0
                )
            : acc + parseFloat(stripCommas(curr[item.name]) || 0);
        }, 0),
      };
    });
    props.setTotal && props.setTotal(totalObjects);
    return (
      !props.hideTotalRow ?
      <tr>
        <th colSpan={totalColSpan}  className="text-center">Total</th>
        {columns.map((col: any, index: any) => {
          return (index + (!props.hideIndex ? 2 : 1) > totalColSpan) ? (
            <th key={col.name} className={`${col.className} ${props.money && "text-right"}`}>
              {totalObjects[col.name] && numberFormat(totalObjects[col.name]?.amount || "0", props.decimalPlaces || 2, props.showZeroDecimalPlaces) }
            </th>
          ) : null;
        })}
      </tr> : null
    );
  };

  const _getWrapperClassName = () => {
    let className = "table-wrapper scrollable x ";
    if (props.wrapperClassName) {
      className += props.wrapperClassName;
    }

    if (props.scrollY) {
      className += " y ";
    }

    if (props.stickyHeader) {
      className += " relative";
    }

    return className;
  };

  const _getTableClassName = () => {
    let className = "";
    if (props.className) {
      className += props.className;
    }

    if (props.onRowClick) {
      className += " row-clickable";
    }

    return className;
  };

  const _getTableHeaderClassName = () => {
    let className = "";

    if (props.tableHeadClassName) {
      className = props.tableHeadClassName;
    }

    if (props.stickyHeader) {
      className += "sticky top-0 left-0 z-index-max";
    }
    return className;
  };

  const _getTableHeadCellClassName = (e: any) => {
    let className = e.className || "";
    if (e.money) {
      className += " text-right";
    }
    return className;
  };

  const _getCellClassName = (item: any, index: number, column: any) => {
    let className = props.tableCellClassName || "";
    className += ` ${typeof column.className === "function"
        ? column.className(item, index, column)
        : column.className || ""}`;
      className += ` ${typeof column.dataClassName === "function"
        ? column.dataClassName(item, index, column)
        : column.dataClassName || ""}`;

    if (column.onClick) {
      className += " clickable";
    }
    if (column.money) {
      className += " text-right";
    }

    let customRenders = props.customRenders || [];
    let customRender: any = customRenders.find((e: any) => e.columnName === column.name);
    let custom =
      customRender && customRender.getClassName
        ? ` ${customRender.getClassName(item, index)}`
        : "";

    return className + " " + custom;
  };

  const getCustomRender = (item: any, index: number, columnName: any) => {
    let customRenders = props.customRenders || [];
    let customRender: any = customRenders.find((e: any) => e.columnName === columnName);
    return customRender ? customRender.render(item, index) : null;
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByClassName("print")[0];
        // html.style.display = "block";
        // const header: any = document.getElementsByClassName("report-header")[0];
        // const footer: any = document.getElementsByClassName("report-footer")[0];
        //   header.style.display = "block";
        //   footer.style.display = "flex";
        const report: any = document.getElementsByClassName("report")[0];
        // report.style.color = "red";
        report.classList.add("printable");
        // v.className += "addCSS";
        const options = {
          // margin: 0,
          filename: "the-joys-of-buying-over-building.pdf",
          // jsPDF: { unit: "px", format: [600, 800], orientation: "portrait" },
          // image:        { type: 'png', quality: 0.98 },
          // html2canvas:  { scale: 2, logging: true, dpi: 192, letterRendering: true },
          jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait' },
          // pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
          pagebreak: { mode: ['avoid-all'] },
          // header: {
          //   height: "1cm",
          //   contents: '<div class="center">page {{pageNumber}} of {{totalPages}}</div>'
          // },
          // footer: {
          //   height: "1cm",
          //   contents: '<div class="center">page {{pageNumber}} of {{totalPages}}</div>'
          // }
        };
        // const exporter = new Html2Pdf(html, options);
        // await exporter.getPdf(options);
        html2Pdf().from(html).set(options).toPdf().get('pdf').then(function(pdf: any) {
          var totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(10);
              pdf.setTextColor(100);
              pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() / 2.3), (pdf.internal.pageSize.getHeight() - 0.8));
          }
        }).save();
      }
    },
    
    // pageStyle: "",
    // documentTitle: "Statement"  
  });

  
  const handlePrint2 = async () => {
    const html: any = document.getElementsByClassName("print")[0];
    // const html = printRef.current;
    // html.style.display = "none";
    const header: any = document.getElementsByClassName("report-header")[0];
    const footer: any = document.getElementsByClassName("report-footer")[0];
    // header.style.display = "block";
    // footer.style.display = "flex";
    const options = {
      margin:       0,
      filename:     'myfile.pdf',
      image:        { type: 'png', quality: 0.98 },
      html2canvas:  { scale: 2, logging: true, dpi: 192, letterRendering: true },
      jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };
    // const exporter = new Html2Pdf(html, options);
    // await exporter.getPdf(options);
    // header.style.display = "none";
    // footer.style.display = "none";

    // exporter.getPdf(false).then((pdf: any) => {
    //   // console.log('doing something before downloading pdf file');
    //   var totalPages = pdf.internal.getNumberOfPages();
    //   for (let i = 1; i <= totalPages; i++) {
    //       pdf.setPage(i);
    //       pdf.setFontSize(10);
    //       pdf.setTextColor(100);
    //       pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() / 2.3), (pdf.internal.pageSize.getHeight() - 0.8));
    //   }
    //   pdf.save();
    // });

    html2Pdf().from(html).set(options).toPdf().get('pdf').then(function(pdf: any) {
      var totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.setTextColor(100);
          pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() / 2.3), (pdf.internal.pageSize.getHeight() - 0.8));
      }
    }).save();
  };

  const onPrint = () => {
    setColumns((columns || []).filter(
      (e: any) => e.name !== "action"
    ));
    setPrintCount(printCount + 1);
  }

  useEffect(() => {
    if(printCount > 0) {
      window.print();
      setReLoadColumns(reLoadColumns + 1);
    }
  }, [printCount])

  useEffect(() => {
    setColumns((props.columns || []).filter(
      (e: any) => typeof e.show === "undefined" || e.show
    ))
  }, [props.columns, reLoadColumns])

  return (
    <div>
      <div className="row justify-space-between mb-2 bg-white">
        <div className="d-flex align-center">
          {props.actions && props.actions?.map(action => action)}
          {(!props.noTitle && props.title) && <div className="text-medium ml-3">{props.title}</div>}
        </div>
        <div className="d-flex justify-end align-center">
          {!props.noSearch &&
          <div className="mr-3 d-flex align-center">
            <TextField
              block={true}
              placeholder="Search"
              value={props.params?.search || ""}
              onChange={(value) => props.onSearch && props.onSearch(value)}
            />
          </div>}
          {props.pagination &&
          <div className="mr-5 d-flex align-center">
            <SelectInput
              options={pageLimits}
              block={true}
              value={pageLimits.find(limit => limit.value === (props.pagination?.limit || 10))}
              onChange={(value) => props.pagination?.onPageLimitChange(value?.value)}
            />
          </div>}
          {!props.noExport &&
          <div className="d-flex align-center">
            {(!props.exportFormats || props.exportFormats.includes("csv")) &&
            <SpreadsheetReport
              format="csv"
              title={props.downloadFileName || props.exportTitle || props.title}
              columns={props.columns?.filter(column => column.webOnly !== true) || []}
              items={props.items?.filter((item: any) => (item.isTitle || item.isSectionTitle) !== true) || []}
              className={`bg-success ${(props.permission && !props.permission?.export) && "disabled"}`}
              onClick={
                (props.permission && !props.permission?.export) ?
                () => dispatch(setAlert({type: "error", message: getNoPermissionMessage("export", props.title)})) :
                undefined
              }
            />}
            {(!props.exportFormats || props.exportFormats.includes("excel")) &&
            <SpreadsheetReport
              format="xlsx"
              title={props.downloadFileName || props.exportTitle || props.title}
              columns={props.columns?.filter(column => column.webOnly !== true) || []}
              items={props.items?.filter((item: any) => (item.isTitle || item.isSectionTitle) !== true) || []}
              className={`bg-secondary ml-1 ${(props.permission && !props.permission?.export) && "disabled"}`}
              onClick={
                (props.permission && !props.permission?.export) ?
                () => dispatch(setAlert({type: "error", message: getNoPermissionMessage("export", props.title)})) :
                undefined
              }
            />}
            {(!props.exportFormats || props.exportFormats.includes("pdf")) &&
            <button
              className={`btn bg-primary small ml-1 ${(props.permission && !props.permission?.print) && "disabled"}`}
              onClick={() => {
                (props.permission && !props.permission?.print) ?
                dispatch(setAlert({type: "error", message: getNoPermissionMessage("print", props.title)})) :
                onPrint();
              }}
            >
              Print
            </button>}
            {/* <PDFReport
              title={props.exportTitle || props.title}
              columns={props.columns?.filter(column => column.webOnly !== true) || []}
              items={props.items || []}
              params={props.params}
              className="bg-primary mb-2 ml-1"
            /> */}
          </div>}
        </div>
      </div>
      <div ref={printRef} className={`${_getWrapperClassName()} report ${!(props.permission && !props.permission?.print) && "print"}`} style={props.wrapperStyle}>
        <ReportHeader
          title={props.exportTitle || props.title}
          params={props.params}
        />
        <table className={_getTableClassName()}>
          {props.renderCaption ? (
            <caption>{props.renderCaption()}</caption>
          ) : null}
          {!props.noHeaderRow ? (
            <thead className={_getTableHeaderClassName()}>
              {columnGroups.length ? (
                <tr>
                  {columnGroups.map((e: any, i) => (
                    <th key={i} colSpan={e.colSpan} scope="colgroup">
                      {e.label}
                    </th>
                  ))}
                </tr>
              ) : null}
              <tr>
                {!props.hideIndex && <th
                  className={props.indexHeadCellClassName}
                  title={props.indexHeadCellTitle}
                  style={props.indexHeadCellStyle}
                >
                  {props.indexHeadCellLabel || "#"}
                </th>}
                {columns.map((e: any, i) => (
                  <th
                    key={i}
                    className={_getTableHeadCellClassName(e)}
                    title={e.title}
                    style={e.style}
                  >
                    {e.label}
                  </th>
                ))}
              </tr>
            </thead>
          ) : null}
          <tbody>
            {props.loading ? (
              <tr>
                <td className="progress-bar-linear" colSpan={columns.length + 1}>
                  <div className="indicator" />
                </td>
              </tr>
            ) : null}
            {props.items?.length ? (
              <>
                {props.items.map((e, i) => (
                  !(e.show === undefined || e.show) ? null :
                  <React.Fragment key={i}>
                    <tr
                      className={(props.getRowClassName && props.getRowClassName(e, i)) || ""}
                      onClick={() => {
                        if (props.onRowClick) {
                          props.onRowClick(e, i);
                        }
                      }}
                    >
                    {!props.hideIndex && <td
                        className={props.getIndexClassName && props.getIndexClassName(e, i)}
                        onClick={() => {
                          if (props.onIndexClick) {
                            props.onIndexClick(e, i);
                          }
                        }}
                        style={props.indexStyle}
                        colSpan={(props.getIndexColSpan && props.getIndexColSpan(e, i))}
                      >
                        {i + (props.pagination?.page_start || 1)}
                      </td>}
                      {columns.map((f: any, index) => {
                        if((e.isTitle || e.isSectionTitle) !== true || index === 0){
                          return <td
                            key={index}
                            className={_getCellClassName(e, i, f)}
                            onClick={(event) => {
                              if (f.onClick) {
                                f.onClick(e, i, event, f);
                              }
                            }}
                            style={f.style}
                            colSpan={f.colSpan && f.colSpan(e, i, f)}
                          >
                            {f.customRender
                              ? getCustomRender(e, i, f.name)
                              : f.money
                              ? numberFormat(parseFloat(e[f.name]) || 0)
                              : e[f.name]}
                          </td>
                        }
                      })}
                    </tr>
                    {props.items?.length && props.expanded === props.items[i] ? (
                      <tr className="expanded">
                        <td colSpan={columns.length + 1}>
                          {props.renderExpanded &&
                            props.renderExpanded(props.expanded, i)}
                        </td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan={columns.length + 1} className="grey-text text-center">
                  No data available
                </td>
              </tr>
            )}
            {props.items?.length && props.items.length >= 15 && props.loading ? (
              <tr>
                <td className="progress-bar-linear" colSpan={columns.length + 1}>
                  <div className="indicator" />
                </td>
              </tr>
            ) : null}
          </tbody>
          {/* {props.totalColumns ? ( */}
            <tfoot className="sticky  bottom-0">
              {props.totalColumns && renderTotal(
                props.items || [],
                props.totalColumns,
                columns,
                props.totalColSpan
              )}
            </tfoot>
          {/* ) : null} */}
          {/* {props.renderFooter ? <tfoot>{props.renderFooter()}</tfoot> : null} */}
        </table>
        <ReportFooter />
      </div>
      {props.pagination &&
      <Pagination 
        data={props.pagination}
        onChange={(page) => props.pagination?.onPageChange(page)}
      />}
    </div>
  );
};

export default Table;