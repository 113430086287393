const ReportsMenu = {
    menuTitle: "Reports",
    items: [
      {
        title: 'Summary Report',
        startIcon: "analytics",
        url: "/reports/summary",
        permission: "reports",
      },
      {
        title: 'Trips Report',
        startIcon: "commute",
        url: "/reports/trips",
        permission: "reports_trips",
      },
      {
        title: 'Income Report',
        startIcon: "paid",
        url: "/reports/income",
        permission: "reports_income",
      },
      {
        title: 'Expenses',
        startIcon: "paid",
        url: "/reports/expenses",
        subMenu: [
          {
            title: 'All Expenses Report',
            startIcon: "paid",
            url: "/reports/expenses/all",
            permission: "reports_expenses",
          },
          {
            title: 'Allowance Report',
            startIcon: "paid",
            url: "/reports/expenses/allowance",
            permission: "reports_expenses",
          },
          {
            title: 'Fuel Report',
            startIcon: "paid",
            url: "/reports/expenses/fuel",
            permission: "reports_expenses",
          },
          {
            title: 'Oil Report',
            startIcon: "paid",
            url: "/reports/expenses/oil",
            permission: "reports_expenses",
          },
          {
            title: 'Tyre Report',
            startIcon: "paid",
            url: "/reports/expenses/tyre",
            permission: "reports_expenses",
          },
          {
            title: 'Repair Report',
            startIcon: "paid",
            url: "/reports/expenses/repair",
            permission: "reports_expenses",
          },
          {
            title: 'Spare Parts Report',
            startIcon: "paid",
            url: "/reports/expenses/spare",
            permission: "reports_expenses",
          },
        ]
      },
      {
        title: 'Income Vs Expense Report',
        startIcon: "paid",
        url: "/reports/income-vs-expense",
        permission: "reports_income_vs_expense",
      },
      {
        title: 'User Activities Report',
        startIcon: "people",
        url: "/reports/user-activities",
        permission: "reports_user_activities",
      },
    ]
}

export default ReportsMenu;