const SettingsMenu = {
    menuTitle: "Settings",
    items: [
      {
        title: 'Expenses Setup',
        startIcon: "paid",
        url: "/settings/expenses",
        subMenu: [
          {
            title: 'Expense Categories',
            startIcon: "settings",
            url: "/settings/expenses/expense-categories",
            permission: "settings_expense_categories",
          },
          {
            title: 'Expense Items',
            startIcon: "settings",
            url: "/settings/expenses/expense-category-items",
            permission: "settings_expense_category_items",
          },
          {
            title: 'Expense Charges',
            startIcon: "settings",
            url: "/settings/expenses/expense-charges",
            permission: "settings_expense_charges",
          },
        ]
      },
      {
        title: 'Employees Setup',
        startIcon: "badge",
        url: "/settings/employees",
        subMenu: [
          {
            title: 'Roles',
            startIcon: "settings",
            url: "/settings/employees/roles",
            permission: "settings_roles",
          },
          {
            title: 'Designations',
            startIcon: "settings",
            url: "/settings/employees/designations",
            permission: "settings_designations",
          },
        ]
      },
      {
        title: 'Trips Setup',
        startIcon: "badge",
        url: "/settings/trips",
        subMenu: [
          {
            title: 'Discount & Charges',
            startIcon: "settings",
            url: "/settings/trips/discount-and-charges",
            permission: "settings_discount_and_charges",
          },
          {
            title: 'Trip Document Types',
            startIcon: "settings",
            url: "/settings/trips/document-types",
            permission: "settings_trip_document_types",
          },
        ]
      },
      {
        title: 'Accounting Setup',
        startIcon: "badge",
        url: "/settings/accounting",
        subMenu: [
          {
            title: 'Invoice',
            startIcon: "settings",
            url: "/settings/accounting/invoice",
            permission: "settings_invoice",
          },
          {
            title: 'Receipt',
            startIcon: "settings",
            url: "/settings/accounting/receipt",
            permission: "settings_receipt",
          },
          {
            title: 'Currency',
            startIcon: "settings",
            url: "/settings/accounting/currency",
            permission: "settings_currency",
          },
        ]
      },
      {
        title: 'Company Setup',
        startIcon: "business",
        url: "/settings/company",
        permission: "settings_company",
      },
      // {
      //   title: 'General Setup',
      //   startIcon: "badge",
      //   url: "/settings/general",
      //   subMenu: [
      //     {
      //       title: 'Charges',
      //       startIcon: "settings",
      //       url: "/settings/general/charges",
      //       permission: "settings_charges",
      //     },
      //   ]
      // },
    ]
}

export default SettingsMenu;