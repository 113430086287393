import { AppService } from '../AppService';

export const TripDocumentService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getTripDocuments: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/trip-documents",
            method: "GET",
            params: params,
        }),
        // keepUnusedDataFor: 5,
        providesTags: ['App', 'TripDocuments', 'GetTripDocuments'],
    }),
    getTripDocumentToUpdate: builder.query({
        query: (id) => ({
            url: `/trip-documents/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'TripDocuments', 'GetTripDocumentToUpdate'],
    }),
    createTripDocument: builder.mutation({
        query: (data = {}) => ({
            url: `/trip-documents/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data.data,
        }),
       invalidatesTags: ['TripDocuments', 'GetTrip'],
    }),
    deleteTripDocument: builder.mutation({
        query: (id) => ({
            url: `/trip-documents/delete/${id}}`,
            method: "DELETE",
        }),
       invalidatesTags: ['GetTripDocuments', 'GetTrip'],
    }),
  }),
});

export const {
    useGetTripDocumentsQuery,
    useGetTripDocumentToUpdateQuery,
    useCreateTripDocumentMutation,
    useDeleteTripDocumentMutation,
} = TripDocumentService;
