import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { Form } from "../../components/form";
import {
  DateInput,
  FileInput,
  SelectInput,
  TextArea,
  TextField
} from "../../components/input";
import { ConfirmationDialog, Modal } from "../../components/modal";
import { formatDateForDb, getValidDateOrNull, numberFormat, stripCommas } from "../../utils/helpers/helpers";
import { getValidationRules } from '../../utils/helpers/helpers';
import { useDispatch } from "react-redux";
import { useCreateExpenseMutation, useGetExpenseToUpdateQuery } from "../../services/expenses/ExpenseService";
import { clearAlert, setAlert } from "../../states/AlertState";
import { useGetAccountsQuery } from "../../services/accounting/AccountService";

interface ExpenseCreateProps {
  expenseID?: any,
  expenseTransaction?: any,
  trip?: any,
  vehicle?: any,
  employee?: any,
  expenseCategoryID?: any,
  from?: string,
  modal?: MutableRefObject<any>,
}

const ExpenseCreate = (props: ExpenseCreateProps) => {
  const dispatch = useDispatch();
  const inputValidation = getValidationRules(); 

  const form = useRef<any>();
  const modal = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 8}).map(() =>  React.createRef()),
    []
  )

  const [formData, setFormData] = useState<any>({});

  const { data, isLoading, isSuccess, isError, error } = useGetExpenseToUpdateQuery(props.expenseID || -1);
  const [ createExpense ] = useCreateExpenseMutation();

  const { data: accounts } = useGetAccountsQuery({});

  const [expenseCategories, setExpenseCategories] = useState<any[]>([]);
  const [accountList, setAccountList] = useState<any>([]);
  
  const [expenseCategoryItemsOG, setExpenseCategoryItemsOG] = useState<any[]>([]);
  const [expenseCategoryItems, setExpenseCategoryItems] = useState<any[]>([]);

  const [vehicles, setVehicles] = useState<any[]>([]);

  const [employees, setEmployees] = useState<any[]>([]);

  const [fieldsLabels, setFieldsLabels] = useState({
    expenseDate: "Expense Date",
    expenseCategoryItem: "Expense Item",
    expenseCategoryItemUnit: "Expense Item Units",
    amount: "Expense Amount"
  })

  const [fieldsRequired, setFieldsRequired] = useState({
    expenseCategoryItemUnit: false,
    vehicle: false,
    expenseCategoryItem: false
  })

  const save = () => {
    if(!form?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    modal?.current?.show(
      "Confirm Save",
      <ConfirmationDialog
      promptMessage="Are you sure want to save this Expense Info"
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Processing..."}));
        modal?.current?.hide();
        let FormDt = {
          ...formData,
          is_payment: (props.expenseTransaction) && 1,
        }
        let FD: any = {};
        Object.keys(FormDt).forEach((key) => {
          if(FormDt[key]){
            FD[key] = typeof FormDt[key]?.trim === "function" ? FormDt[key]?.trim() : FormDt[key];
          }
        });
        let FData = new FormData();
        Object.keys(FD).forEach((key) => {
          FData.append(key, FD[key]);
        });
        createExpense({data: FData, id: props.expenseID})
        .unwrap()
        .then((response: any) => {
          if (props.expenseID) {
            dispatch(setAlert({type: "success", message: "Expense Info has successfully saved", unreplaceable: true}));
          }
          else {
            dispatch(setAlert({type: "success", message: "Expense has successfully added", unreplaceable: true}));
          }
          setTimeout(() => {
            props.modal?.current?.hide();
          }, 1500);
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message, unreplaceable: true}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }

  const populateData = (data: any) => {
    setFormData({
      ...data.expense,
      expense_category: data.expense?.expense_category_id || props.expenseCategoryID,
      expense_category_item: data.expense?.expense_category_item_id,
      vehicle: data.expense?.vehicle_id || props.trip?.vehicle_id || props.vehicle?.vehicle_id ,
      trip: data.expense?.trip_id || props.trip?.trip_id,
      expense_transaction: data.expense?.expense_transaction_id || props.expenseTransaction?.expense_transaction_id,
      employee: data.expense?.employee_id || props.trip?.driver_id || props.employee?.user_id,
      amount: data.expense?.amount || (props.expenseTransaction?.expense_amount_due > 0 ? props.expenseTransaction?.expense_amount_due : undefined),
    });
    data?.expense_categories && setExpenseCategories(data.expense_categories.map((expense_category: any) => ({label: expense_category.expense_category_name, value: expense_category.expense_category_id})));
    data?.expense_category_items && setExpenseCategoryItemsOG(data.expense_category_items.map((expense_category_item: any) => ({...expense_category_item, label: expense_category_item.expense_category_item_name, value: expense_category_item.expense_category_item_id})));
    data?.vehicles && setVehicles(data.vehicles.map((vehicle: any) => ({label: vehicle.registration_number, value: vehicle.vehicle_id})));
    data?.employees && setEmployees(data.employees.map((employee: any) => ({label: `${employee.first_name} ${employee.last_name}` , value: employee.user_id})));
  }

  const onCategoryChange = (category: any) => {
    category = parseInt(category);
    form.current?.clearValidation();
    switch(category){
      case 2:
        setFieldsLabels({
          expenseDate: "Filling Date",
          expenseCategoryItem: "Fuel Brand",
          expenseCategoryItemUnit: "Filled Litres",
          amount: "Cost Amount"
        });
        setFieldsRequired({
          expenseCategoryItemUnit: true,
          vehicle: true,
          expenseCategoryItem: false
        });
        break;
      case 3:
        setFieldsLabels({
          expenseDate: "Changing Date",
          expenseCategoryItem: "Oil Brand",
          expenseCategoryItemUnit: "Total Units",
          amount: "Cost Amount"
        });
        setFieldsRequired({
          expenseCategoryItemUnit: false,
          vehicle: true,
          expenseCategoryItem: false
        });
        break;
      case 4:
        setFieldsLabels({
          expenseDate: "Changing Date",
          expenseCategoryItem: "Tyre Brand",
          expenseCategoryItemUnit: "Total Units",
          amount: "Cost Amount"
        });
        setFieldsRequired({
          expenseCategoryItemUnit: false,
          vehicle: true,
          expenseCategoryItem: true
        });
        break;
      case 5:
        setFieldsLabels({
          expenseDate: "Reparing Date",
          expenseCategoryItem: "Repared Item",
          expenseCategoryItemUnit: "Item Units",
          amount: "Cost Amount"
        });
        setFieldsRequired({
          expenseCategoryItemUnit: false,
          vehicle: true,
          expenseCategoryItem: false
        });
        break;
      default:
        if(category === 1){
          setFieldsLabels({
            expenseDate: "Paid Date",
            expenseCategoryItem: "Allowance Type",
            expenseCategoryItemUnit: "Expense Item units",
            amount: "Paid Amountt"
          });
          setFieldsRequired({
            expenseCategoryItemUnit: false,
            vehicle: false,
            expenseCategoryItem: true
          });
        }
        else{
          setFieldsLabels({
            expenseDate: "Expense Date",
            expenseCategoryItem: "Expense Item",
            expenseCategoryItemUnit: "Expense Item units",
            amount: "Expense Amount"
          });
          setFieldsRequired({
            expenseCategoryItemUnit: false,
            vehicle: false,
            expenseCategoryItem: false
          });
        }

        // if(category === 1 && !formData.trip){
        //   dispatch(setAlert({type: "warning", message: "Allowance need to be added to the respective trip, please go to the respective trip and add it as an expense of the trip."}));
        //   setFormData({...formData, expense_category: undefined})
        // }

        if(category === 1 && formData.trip && !data.expense){
          setFormData({...formData, expense_category_item: "119"})
        }
    }
    //Populate the expenses items with the selected expense category items
    setExpenseCategoryItems(expenseCategoryItemsOG.filter(item => parseInt(item.expense_category_id) === category));
  }

  const onCategoryItemChange = (categoryItem: any) => {
    categoryItem = parseInt(categoryItem);
    if(categoryItem === 119 && !formData.trip){
      dispatch(setAlert({type: "warning", message: "Trip Allowance need to be added to the respective trip, please go to the respective trip and add it as an expense of the trip."}));
      setFormData({...formData, expense_category_item: undefined})
    }
  }

  useEffect(() => {
    accounts?.accounts?.length && setAccountList(accounts?.accounts?.filter((account: any) => ["1", "2", "10"].includes(account.account_type_id))
      ?.map((account: any) => ({label: account.account_name, value: account.account_id, ...account})));
  }, [accounts])

  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);

  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])

  useEffect(() => {
    isSuccess &&  dispatch(clearAlert());
  }, [isSuccess]);

  useEffect(() => {
    expenseCategoryItemsOG.length && setExpenseCategoryItems(expenseCategoryItemsOG);
  }, [expenseCategoryItemsOG])

  useEffect(() => {
    data && populateData(data);
  }, [data])

  useEffect(() => {
    formData.expense_category && onCategoryChange(formData.expense_category);
  }, [formData.expense_category]);

  useEffect(() => {
    formData.expense_category_item && onCategoryItemChange(formData.expense_category_item);
  }, [formData.expense_category_item]);

  let attachmentUrlParts = typeof formData.supporting_doc?.split === "function" && formData.supporting_doc?.split("/");
  let attachmentName = attachmentUrlParts.length && attachmentUrlParts[attachmentUrlParts.length-1];

  return (
    <>
      <div className="card scrollable y">
        <div className="card-body">
          <div>
            <Form ref={form}>
              <div className="row">
                {!(props.expenseCategoryID || props.expenseTransaction) &&
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[0]}
                    label="Expense Category"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={expenseCategories}
                    value={expenseCategories.filter(expenseCategory => expenseCategory.value === formData.expense_category)}
                    onChange={(value) => setFormData({
                      ...formData,
                      expense_category: value?.value,
                      expense_category_item: undefined,
                    })}
                  />
                </div>}
                <div className="col-12 col-md-4">
                  <DateInput
                    ref={inputs[1]}
                    renderEndIcon={() => (
                      <span className="material-icons">calendar_today</span>
                    )}
                    label={props.expenseTransaction ? "Payment Date" : fieldsLabels.expenseDate}
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={getValidDateOrNull(formData.expense_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        expense_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                {/* {!((parseInt(formData.expense_category) === 1 && formData.trip) || props.expenseTransaction) &&  */}
                {!props.expenseTransaction &&
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[2]}
                    label={fieldsLabels.expenseCategoryItem}
                    block={true}
                    disabled={parseInt(formData.expense_category) === 1 && formData.trip}
                    requiredDecorator={fieldsRequired.expenseCategoryItem}
                    rules={[...(fieldsRequired.expenseCategoryItem ? [inputValidation.required] : [])]}
                    options={expenseCategoryItems}
                    value={expenseCategoryItems.find(expenseCategoryItem => expenseCategoryItem.value === formData.expense_category_item)}
                    onChange={(value) => setFormData({
                      ...formData,
                      expense_category_item: value?.value
                    })}
                  />
                </div>}
                {/* {(parseInt(formData.expense_category) === 1 && formData.trip) &&  */}
                {(parseInt(formData.expense_category) === 1) && 
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[3]}
                    label="Paid Employee"
                    block={true}
                    options={employees}
                    value={employees.find(employee => employee.value === formData.employee)}
                    onChange={(value) => setFormData({
                      ...formData,
                      employee: value?.value
                    })}
                  />
                </div>}
                {/* {!((parseInt(formData.expense_category) === 1 && formData.trip) || props.expenseTransaction) &&  */}
                {!((parseInt(formData.expense_category) === 1) || props.expenseTransaction) && 
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[4]}
                    label={fieldsLabels.expenseCategoryItemUnit}
                    block={true}
                    requiredDecorator={fieldsRequired.expenseCategoryItemUnit}
                    rules={[...(fieldsRequired.expenseCategoryItemUnit ? [inputValidation.required] : [])]}
                    value={numberFormat(formData.expense_category_item_unit) || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      expense_category_item_unit: stripCommas(value)
                    })}
                  />
                </div>}
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[5]}
                    label={props.expenseTransaction ? "Paid Amount" : fieldsLabels.amount}
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={numberFormat(formData.amount) || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      amount: stripCommas(value)
                    })}
                  />
                </div>
                {((!(props.trip?.vehicle_id || props.vehicle?.vehicle_id) || data?.expense?.vehicle_id) && !props.expenseTransaction) &&
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[6]}
                    label="Vehicle"
                    block={true}
                    requiredDecorator={fieldsRequired.vehicle}
                    rules={[...(fieldsRequired.vehicle ? [inputValidation.required] : [])]}
                    clearable
                    options={vehicles}
                    value={vehicles.find(vehicle => vehicle.value === formData.vehicle)}
                    onChange={(value) => setFormData({
                      ...formData,
                      vehicle: value?.value
                    })}
                  />
                </div>}
                {(props.expenseTransaction || props.trip) && 
                <div className="col-12 col-md-4">
                <SelectInput
                  ref={inputs[7]}
                  label="Paid Through"
                  block={true}
                  requiredDecorator={true}
                  rules={[inputValidation.required]}
                  options={accountList}
                  value={accountList.filter((account: any) => account.value == formData.payment_account)}
                  onChange={(value) => setFormData({
                    ...formData,
                    payment_account: value?.value,
                    payment_account_category: value?.account_category_id
                  })}
                />
              </div>}
                <div className="col-12 col-md-4">
                  <FileInput
                    label="Attachment"
                    placeholder={attachmentName || "Chose file..."}
                    block={true}
                    onChange={(value) => setFormData({
                      ...formData,
                      supporting_doc: value && value[0]
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextArea
                    ref={inputs[8]}
                    label="Description"
                    block={true}
                    value={formData.description || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      description: value
                    })}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="card-footer d-flex flex-row">
          <button
            className="btn bg-secondary"
            onClick={() => save()}
          >
            {props.expenseID ? "Save" : "Add Expense"}
          </button>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default ExpenseCreate;
