import {
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

const PDFAddressDetails = (props: any) => {
  const { data, title } = props || {};

  return (
    <View style={[styles.text, {lineHeight: 1.5}]}>
      {title && <Text style={[{fontWeight: "bold", fontSize: 10, marginBottom: 3}]}>{title}:</Text>}
      <Text>{data?.first_name}{data?.last_name && ` ${data?.last_name}` }</Text>
      {data?.physical_address?.split(",")?.map((address: any, index: number) => (
        <Text key={index}>{typeof address?.trim === "function" ? address?.trim() : address}</Text>
      ))}
      {props.showTIN && <Text>{data?.tin_number && `TIN: ${data.tin_number}`}</Text>}
      <Text>{data?.phone_number && `Phone#: ${data.phone_number}`}</Text>
      <Text>{data?.email && `Email: ${data.email}`}</Text>
      <Text>{data?.pobox && `P.O.Box: ${data.pobox}`}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  spacer: {
    marginBottom: 4,
  },
  divider: {
    height: 1,
    backgroundColor: "black",
  },
  text: {
    fontSize: 8,
    // fontFamily: "Custom",
  },
  outlined: {
    border: "1pt solid black",
  },
  padded: {
    padding: 5,
  },
  grey: {
    backgroundColor: "grey",
  },
  lightGrey: {
    backgroundColor: "#F5F5F5",
  },
  row: {
    flexDirection: "row",
  },
  table: {
    border: "1pt solid #666666",
    borderTopWidth: 0,
    borderLeftWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    border: "1pt solid #666666",
    borderRightWidth: 0,
    borderBottomWidth: 0,
    padding: 4,
    flex: 1,
    fontSize: 8,
  },
});

export default PDFAddressDetails;
