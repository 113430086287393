import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { Form } from "../../components/form";
import {
  DateInput,
  SelectInput,
  TextArea,
  TextField
} from "../../components/input";
import { ConfirmationDialog, Modal } from "../../components/modal";
import { formatDateForDb, formatDateTimeForDb, getValidDateOrNull, getValidDateTimeOrNull, stripCommas } from "../../utils/helpers/helpers";
import { useCreateTripMutation, useGetTripToUpdateQuery } from "../../services/trips/TripService";
import { getValidationRules } from '../../utils/helpers/helpers';
import { numberFormat } from './../../utils/helpers/helpers';
import { clearAlert, setAlert } from "../../states/AlertState";
import { useDispatch, useSelector } from "react-redux";
import { useGetChargesQuery } from "../../services/settings/general/ChargeService";

interface TripCreateProps {
  tripID?: any,
  vehicle?: any,
  employee?: any,
  customer?: any,
  cargo?: any,
  modal?: MutableRefObject<any>,
}

const TripCreate = (props: TripCreateProps) => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();
  const inputValidation = getValidationRules(); 

  const form = useRef<any>();
  const modal = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 10}).map(() =>  React.createRef()),
    []
  );

  const [formData, setFormData] = useState<any>({});

  const { data, isLoading: isDataLoading, isSuccess: isDataSuccess, isError: isDataError, error: dataError } = useGetTripToUpdateQuery(props.tripID || -1);
  const { data: charges } = useGetChargesQuery({account_category: "income"});
  const [ createTrip ] = useCreateTripMutation();

  const [drivers, setDrivers] = useState<any[]>([]);
  const [cargos, setCargos] = useState<any[]>([]);
  const [customers, setCustomers] = useState<any[]>([]);
  const [routes, setRoutes] = useState<any[]>([]);
  const [vehicles, setVehicles] = useState<any[]>([]);
  const [taxCharges, setTaxCharges] = useState<any>([]);
  const [otherCharges, setOtherCharges] = useState<any>([]);
  const [tripTaxCharges, setTripTaxCharges] = useState<any>([]);
  const [tripOtherCharges, setTripOtherCharges] = useState<any>([]);

  const [containerSizes, setContainerSizes] = useState([
    {label: "20ft", value: "1"},
    {label: "40ft", value: "2"},
  ]);

  const [tripTypes, setTripTypes] = useState([
    {label: "Singletrip", value: "1"},
    {label: "Roundtrip", value: "0"},
  ]);

  const [tripStatus, setTripStatus] = useState([
    {label: "Completed", value: "0"},
    {label: "Ongoing", value: "1"},
    {label: "Yet to start", value: "2"},
    {label: "Yet to Assign", value: "3"},   
  ]);

  const [discountTypes] = useState<any[]>([
    {label: "Percent", value: "percent"},
    {label: "Fixed", value: "fixed"},
  ]);

  const save = () => {
    if(!form?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    modal?.current?.show(
      "Confirm Save",
      <ConfirmationDialog
      promptMessage="Are you sure want to save this Trip Info"
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Processing..."}));
        modal?.current?.hide();
        let FD: any = {};
        Object.keys(formData).forEach((key) => {
          FD[key] = typeof formData[key]?.trim === "function" ? formData[key]?.trim() : formData[key];
        });
        createTrip({...FD, id: props.tripID})
        .unwrap()
        .then((response: any) => {
          if (props.tripID) {
            dispatch(setAlert({type: "success", message: "Trip Info has successfully saved", unreplaceable: true}));
          }
          else {
            dispatch(setAlert({type: "success", message: "Trip has successfully added", unreplaceable: true}));
          }
          setTimeout(() => {
            props.modal?.current?.hide();
          }, 1500);
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message, unreplaceable: true}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }

  const populateData = (data: any) => {
    let config = user?.configuration;
    setFormData({
      ...data.trip,
      discount: data.trip ? data.trip.discount : config.discount,
      discount_type: data.trip ? data.trip.discount_type : config.discount_type,
      customer: data.trip?.customer_id || props.customer?.customer_id,
      vehicle: data.trip?.vehicle_id || props.vehicle?.vehicle_id,
      driver: data.trip?.driver_id || props.vehicle?.driver_id || props.employee?.user_id,
      route: data.trip?.route_id,
      cargos: data.trip?.cargos?.split(",") || (props.cargo && [props.cargo?.cargo_id]),
    });
    data?.cargos && setCargos(data.cargos.map((cargo: any) => ({label: cargo.cargo_name, value: cargo.cargo_id})));
    data?.customers && setCustomers(data.customers.map((customer: any) => ({label: customer.first_name, value: customer.customer_id})));
    data?.vehicles && setVehicles(data.vehicles.map((vehicle: any) => ({label: vehicle.registration_number, value: vehicle.vehicle_id, driver_id: vehicle.driver_id})));
    data?.routes && setRoutes(data.routes.map((route: any) => ({label: `${route.start_location} - ${route.end_location}`, value: route.route_id})));
    data?.drivers && setDrivers(data.drivers.map((driver: any) => ({label: `${driver.first_name} ${driver.last_name}` , value: driver.user_id})));
  }

  const populateCharges = () => {
    let config = user?.configuration;
    let tempCharges = charges?.charges || [];
    let tempTripCharges = data?.trip?.charges || [];
    let tempTripsSetupChargesIDs = config?.charges || "";
    let tempTaxTripCharges = tempTripCharges?.filter((charge: any) => charge.charge_category === "tax");
    let tempOtherTripCharges = tempTripCharges?.filter((charge: any) => charge.charge_category === "other");

    setTripTaxCharges(
      data?.trip ? tempTaxTripCharges :
      tempCharges.filter((charge: any) => charge.category === "tax" && tempTripsSetupChargesIDs.split(",")?.includes(charge.charge_id))
      ?.map((charge: any) => ({
        charge_id: charge.charge_id,
        charge_name: charge.name,
        charge_amount: charge.amount,
        charge_type: charge.type,
        charge_category: charge.category,
        account_id: charge.account_id,
        account_type_id: charge.account_type_id,
      }))
    );
    setTripOtherCharges(
      data?.trip ? tempOtherTripCharges :
      tempCharges.filter((charge: any) => charge.category === "other" && tempTripsSetupChargesIDs.split(",")?.includes(charge.charge_id))
      ?.map((charge: any) => ({
        charge_id: charge.charge_id,
        charge_name: charge.name,
        charge_amount: charge.amount,
        charge_type: charge.type,
        charge_category: charge.category,
        account_id: charge.account_id,
        account_type_id: charge.account_type_id,
      }))
    );

    // set Tax and Other Charges Options
    setTaxCharges([
      ...tempCharges
      ?.filter((charge: any) => charge.category === "tax")
      ?.map((charge: any) => {
        let existingChargeRecord = tempTripCharges.find((exCharge: any) => exCharge.charge_id === charge.charge_id)
        return existingChargeRecord ?
        {
          label: existingChargeRecord.charge_name,
          value: existingChargeRecord.charge_id,
          charge_id: existingChargeRecord.charge_id,
          name: existingChargeRecord.charge_name,
          amount: existingChargeRecord.charge_amount, 
          type: existingChargeRecord.charge_type,
          category: existingChargeRecord.charge_category,
          account_id: existingChargeRecord.account_id,
          account_type_id: existingChargeRecord.account_type_id,
        } :
        {
          label: charge.name,
          value: charge.charge_id,
          ...charge
        };
      }),
      ...tempTripCharges
      ?.filter((exCharge: any) => exCharge.charge_category === "tax" && !(tempCharges.find((charge: any) => charge.charge_id === exCharge.charge_id)))
      ?.map((exCharge: any) => ({
        label: exCharge.charge_name,
        value: exCharge.charge_id,
        charge_id: exCharge.charge_id,
        name: exCharge.charge_name,
        amount: exCharge.charge_amount, 
        type: exCharge.charge_type,
        category: exCharge.charge_category,
        account_id: exCharge.account_id,
        account_type_id: exCharge.account_type_id,
      })),
    ]);
    setOtherCharges([
      ...tempCharges
      ?.filter((charge: any) => charge.category === "other")
      ?.map((charge: any) => {
        let existingChargeRecord = tempTripCharges.find((exCharge: any) => exCharge.charge_id === charge.charge_id)
        return existingChargeRecord ?
        {
          label: existingChargeRecord.charge_name,
          value: existingChargeRecord.charge_id,
          charge_id: existingChargeRecord.charge_id,
          name: existingChargeRecord.charge_name,
          amount: existingChargeRecord.charge_amount, 
          type: existingChargeRecord.charge_type,
          category: existingChargeRecord.charge_category,
          account_id: existingChargeRecord.account_id,
          account_type_id: existingChargeRecord.account_type_id,
        } :
        {
          label: charge.name,
          value: charge.charge_id,
          ...charge
        };
      }),
      ...tempTripCharges
      ?.filter((exCharge: any) => exCharge.charge_category === "other" && !(tempCharges.find((charge: any) => charge.charge_id === exCharge.charge_id)))
      ?.map((exCharge: any) => ({
        label: exCharge.charge_name,
        value: exCharge.charge_id,
        charge_id: exCharge.charge_id,
        name: exCharge.charge_name,
        amount: exCharge.charge_amount, 
        type: exCharge.charge_type,
        category: exCharge.charge_category,
        account_id: exCharge.account_id,
        account_type_id: exCharge.account_type_id,
      })),
    ]);
  }

  useEffect(() => {
    if(data?.trip?.charges){
      let tempTripCharges = data?.trip?.charges || [];
      setTripTaxCharges(tempTripCharges?.filter((charge: any) => charge.charge_category === "tax"));
      setTripOtherCharges(tempTripCharges?.filter((charge: any) => charge.charge_category === "other"));
    }
  }, [charges, data])

  useEffect(() => {
    isDataError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in dataError ? dataError.errorMessage : dataError.message}));
  }, [isDataError]);

  useEffect(() => {
    isDataLoading && dispatch(setAlert({type: "progress"}));
  }, [isDataLoading])

  useEffect(() => {
    isDataSuccess &&  dispatch(clearAlert());
  }, [isDataSuccess]);

  useEffect(() => {
    // if(tripTaxCharges?.length || tripOtherCharges?.length){
      setFormData({
        ...formData,
        charges: [
          ...tripTaxCharges,
          ...tripOtherCharges,
        ]
      })
    // }
  }, [tripTaxCharges, tripOtherCharges])

  useEffect(() => {
    charges?.charges?.length && populateCharges()
  }, [charges, data])

  useEffect(() => {
    data && populateData(data);
  }, [data])

  return (
    <>
      <div className="card scrollable y">
        <div className="card-body">
          <div>
            <Form ref={form}>
              <div className="row">
                <div className="col-12 col-md-4">
                  <TextField
                    label="File Number"
                    block={true}
                    value={formData.file_number || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      file_number: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[0]}
                    label="Cargo"
                    block={true}
                    isMulti
                    disabled={props.cargo?.cargo_id}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={cargos}
                    value={cargos.filter(cargo => formData.cargos?.includes(cargo.value))}
                    onChange={(value) => setFormData({
                      ...formData,
                      cargos: value?.map((val: any) => val.value),
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[1]}
                    label="Cargo Weight (Kg)"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required, inputValidation.number]}
                    value={numberFormat(formData.cargo_weight, 2) || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      cargo_weight: stripCommas(value)
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Cargo Number"
                    block={true}
                    value={formData.cargo_number || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      cargo_number: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Container Number"
                    block={true}
                    value={formData.container_number || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      container_number: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                    label="Container Size"
                    block={true}
                    options={containerSizes}
                    value={containerSizes.find(containerSize => containerSize.value === formData.container_size)}
                    onChange={(value) => setFormData({
                      ...formData,
                      container_size: value?.value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[2]}
                    label="Customer"
                    block={true}
                    disabled={props.customer?.customer_id}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={customers}
                    value={customers.find(customer => customer.value === formData.customer)}
                    onChange={(value) => setFormData({
                      ...formData,
                      customer: value?.value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Shipping Address"
                    block={true}
                    value={formData.shipping_physical_address || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      shipping_physical_address: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Consignee Name"
                    block={true}
                    value={formData.consignee_name || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      consignee_name: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Consignee Contact"
                    block={true}
                    value={formData.consignee_contact || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      consignee_contact: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[3]}
                    label="Route"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={routes}
                    value={routes.find(route => route.value === formData.route)}
                    onChange={(value) => setFormData({
                      ...formData,
                      route: value?.value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[4]}
                    label="Trip Type"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={tripTypes}
                    value={tripTypes.find(tripType => tripType.value === formData.trip_type)}
                    onChange={(value) => setFormData({
                      ...formData,
                      trip_type: value?.value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[5]}
                    label="Freight Rate"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required, inputValidation.number]}
                    value={numberFormat(formData.freight_rate, 2) || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      freight_rate: stripCommas(value)
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                    label="Discount Type"
                    block={true}
                    clearable
                    options={discountTypes}
                    value={discountTypes.filter(discountType => discountType.value === formData.discount_type)}
                    onChange={(value) => setFormData({
                      ...formData,
                      discount_type: value?.value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Discount Rate/Amount"
                    block={true}
                    rules={[inputValidation.number]}
                    value={numberFormat(formData.discount) || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      discount: stripCommas(value)
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                    label="Taxes"
                    block={true}
                    isMulti
                    options={taxCharges || []}
                    value={taxCharges.filter((charge: any) => tripTaxCharges?.find((exCharge: any) => exCharge.charge_id === charge.value))}
                    onChange={(value) => {
                      let selectedCharges = value?.map((val: any) => ({
                        charge_id: val.value,
                        charge_name: val.name,
                        charge_amount: val.amount,
                        charge_type: val.type,
                        charge_category: val.category,
                        account_id: val.account_id,
                        account_type_id: val.account_type_id,
                      }));
                      setTripTaxCharges(selectedCharges);
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                    label="Other Charges"
                    block={true}
                    isMulti
                    options={otherCharges || []}
                    value={otherCharges.filter((charge: any) => tripOtherCharges?.find((exCharge: any) => exCharge.charge_id == charge.value))}
                    onChange={(value) => {
                      let selectedCharges = value?.map((val: any) => ({
                        charge_id: val.value,
                        charge_name: val.name,
                        charge_amount: val.amount,
                        charge_type: val.type,
                        charge_category: val.category,
                        account_id: val.account_id,
                        account_type_id: val.account_type_id,
                      }));
                      setTripOtherCharges(selectedCharges);
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[6]}
                    label="Vehicle"
                    block={true}
                    disabled={props.vehicle?.vehicle_id}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={vehicles}
                    value={vehicles.find(vehicle => vehicle.value === formData.vehicle)}
                    onChange={(value) => setFormData({
                      ...formData,
                      vehicle: value?.value,
                      ...(!props.employee?.user_id ? {driver: value.driver_id} : {}),
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[7]}
                    label="Driver"
                    block={true}
                    disabled={props.employee?.user_id}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={drivers}
                    value={drivers.find(driver => driver.value === formData.driver)}
                    onChange={(value) => setFormData({
                      ...formData,
                      driver: value?.value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[8]}
                    label="Status"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={tripStatus}
                    value={tripStatus.find(tripStatus => tripStatus.value === formData.status)}
                    onChange={(value) => setFormData({
                      ...formData,
                      status: value?.value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Vessel Name"
                    block={true}
                    value={formData.vessel_name || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      vessel_name: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="Vessel ETA"
                    block={true}
                    showTimeSelect
                    dateFormat="yyyy-MM-dd HH:mm"
                    value={getValidDateTimeOrNull(formData.vessel_eta)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        vessel_eta: formatDateTimeForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="Discharge Date"
                    block={true}
                    value={getValidDateOrNull(formData.discharge_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        discharge_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="Loading Date"
                    block={true}
                    value={getValidDateOrNull(formData.loading_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        loading_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Loading Place"
                    block={true}
                    value={formData.loading_place || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      loading_place: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                    ref={inputs[9]}
                    renderEndIcon={() => (
                      <span className="material-icons">calendar_today</span>
                    )}
                    label="Departure Date"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={getValidDateOrNull(formData.start_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        start_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="First Border Arrival Date"
                    block={true}
                    value={getValidDateOrNull(formData.first_border_arrival_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        first_border_arrival_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="First Border Departure Date"
                    block={true}
                    value={getValidDateOrNull(formData.first_border_departure_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        first_border_departure_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="Second Border Arrival Date"
                    block={true}
                    value={getValidDateOrNull(formData.second_border_arrival_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        second_border_arrival_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="Second Border Departure Date"
                    block={true}
                    value={getValidDateOrNull(formData.second_border_departure_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        second_border_departure_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="Arrival Date At Destination"
                    block={true}
                    value={getValidDateOrNull(formData.end_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        end_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Offloading Place"
                    block={true}
                    value={formData.offloading_place || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      offloading_place: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Waiting Time (Hours)"
                    block={true}
                    value={formData.waiting_time || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      waiting_time: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="Offloading Date"
                    block={true}
                    value={getValidDateOrNull(formData.offloading_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        offloading_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Vehicle Detention Days"
                    block={true}
                    value={formData.detention_time || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      detention_time: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="Departure Date After Offloading"
                    block={true}
                    value={getValidDateOrNull(formData.after_offloading_departure_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        after_offloading_departure_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
              </div>
              <div className="grey-text text-large mt-5 mb-3">Documents</div>
              <div className="row">
                <div className="col-12 col-md-4">
                  <TextField
                    label="Bill of Lading Number"
                    block={true}
                    value={formData.bol_number || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      bol_number: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                    renderEndIcon={() => (
                      <span className="material-icons">calendar_today</span>
                    )}
                    label="OBL+CI+PL Received Date"
                    block={true}
                    value={getValidDateOrNull(formData.documents_received_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        documents_received_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
              </div>
              <div className="grey-text text-large mt-5 mb-3">Return Trip</div>
              <div className="row">
                <div className="col-12 col-md-4">
                  <DateInput
                    renderEndIcon={() => (
                      <span className="material-icons">calendar_today</span>
                    )}
                    label="Loading Place Arrival Date"
                    block={true}
                    value={getValidDateOrNull(formData.return_loading_place_arrival_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        return_loading_place_arrival_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                    renderEndIcon={() => (
                      <span className="material-icons">calendar_today</span>
                    )}
                    label="Loading Date"
                    block={true}
                    value={getValidDateOrNull(formData.return_loading_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        return_loading_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Loading Place"
                    block={true}
                    value={formData.return_loading_place || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      return_loading_place: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                    renderEndIcon={() => (
                      <span className="material-icons">calendar_today</span>
                    )}
                    label="Documents Received Date"
                    block={true}
                    value={getValidDateOrNull(formData.return_documents_received_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        return_documents_received_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                    renderEndIcon={() => (
                      <span className="material-icons">calendar_today</span>
                    )}
                    label="Departure Date"
                    block={true}
                    value={getValidDateOrNull(formData.return_start_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        return_start_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                    renderEndIcon={() => (
                      <span className="material-icons">calendar_today</span>
                    )}
                    label="First Border Arrival Date"
                    block={true}
                    value={getValidDateOrNull(formData.return_first_border_arrival_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        return_first_border_arrival_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                    renderEndIcon={() => (
                      <span className="material-icons">calendar_today</span>
                    )}
                    label="First Border Departure Date"
                    block={true}
                    value={getValidDateOrNull(formData.return_first_border_departure_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        return_first_border_departure_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                    renderEndIcon={() => (
                      <span className="material-icons">calendar_today</span>
                    )}
                    label="Second Border Arrival Date"
                    block={true}
                    value={getValidDateOrNull(formData.return_second_border_arrival_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        return_second_border_arrival_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                    renderEndIcon={() => (
                      <span className="material-icons">calendar_today</span>
                    )}
                    label="Second Border Departure Date"
                    block={true}
                    value={getValidDateOrNull(formData.return_second_border_departure_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        return_second_border_departure_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                    renderEndIcon={() => (
                      <span className="material-icons">calendar_today</span>
                    )}
                    label="Arrival Date At Destination"
                    block={true}
                    value={getValidDateOrNull(formData.return_end_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        return_end_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                    renderEndIcon={() => (
                      <span className="material-icons">calendar_today</span>
                    )}
                    label="Offloading Date"
                    block={true}
                    value={getValidDateOrNull(formData.return_offloading_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        return_offloading_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                    renderEndIcon={() => (
                      <span className="material-icons">calendar_today</span>
                    )}
                    label="Empty Container Return Date"
                    block={true}
                    value={getValidDateOrNull(formData.return_empty_container_return_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        return_empty_container_return_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
              </div>
              <div className="grey-text text-large mt-5 mb-3">Current Trip Status</div>
              <div className="row">
                <div className="col-12 col-md-4">
                  <TextField
                    label="Current Position"
                    block={true}
                    value={formData.current_position || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      current_position: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextArea
                    label="Current Status Comment"
                    block={true}
                    value={formData.comment || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      comment: value
                    })}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="card-footer d-flex flex-row">
          <button
            className="btn bg-secondary"
            onClick={() => save()}
          >
            {props.tripID ? "Save" : "Add Trip"}
          </button>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default TripCreate;
