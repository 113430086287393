import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { Form } from "../../../../components/form";
import {
  TextField
} from "../../../../components/input";
import { ConfirmationDialog, Modal } from "../../../../components/modal";
import { getValidationRules, numberFormat, stripCommas } from '../../../../utils/helpers/helpers';
import { useDispatch, useSelector } from "react-redux";
import { clearAlert, setAlert } from "../../../../states/AlertState";
import { useCreateCurrencyMutation, useGetCurrencyToUpdateQuery } from "../../../../services/settings/accounting/currency/CurrencyService";

interface CurrencyCreateProps {
  currencyID?: any,
  modal?: MutableRefObject<any>,
}

const CurrencyCreate = (props: CurrencyCreateProps) => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();
  const inputValidation = getValidationRules(); 

  const form = useRef<any>();
  const modal = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 8}).map(() =>  React.createRef()),
    []
  )

  const [formData, setFormData] = useState<any>({});
  const { data, isLoading, isSuccess, isError, error } = useGetCurrencyToUpdateQuery(props.currencyID || -1);
  const [ createCurrency ] = useCreateCurrencyMutation();

  const save = () => {
    if(!form?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    modal?.current?.show(
      "Confirm Save",
      <ConfirmationDialog
      promptMessage="Are you sure want to save this Currency Info"
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Processing..."}));
        modal?.current?.hide();
        let FD: any = {};
        Object.keys(formData).forEach((key) => {
         FD[key] = typeof formData[key]?.trim === "function" ? formData[key]?.trim() : formData[key];
        });
        createCurrency({...FD, id: props.currencyID})
        .unwrap()
        .then((response: any) => {
          if (props.currencyID) {
            dispatch(setAlert({type: "success", message: "Currency Info has successfully saved", unreplaceable: true}));
          }
          else {
            dispatch(setAlert({type: "success", message: "Currency has successfully added", unreplaceable: true}));
          }
          setTimeout(() => {
            props.modal?.current?.hide();
          }, 1500);
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }

  const populateData = (data: any) => {
    setFormData({
      ...data.currency,
    });
  }

  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);

  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])

  useEffect(() => {
    isSuccess &&  dispatch(clearAlert());
  }, [isSuccess]);

  useEffect(() => {
    data && populateData(data);
  }, [data])

  return (
    <>
      <div className="card scrollable y">
        <div className="card-body">
          <div>
            <Form ref={form}>
              <div className="row">
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[0]}
                    label="Currency Name"
                    block={true}
                    disabled={formData.company_id == 0}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={formData.currency_name || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      currency_name: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[1]}
                    label="Currency Code"
                    block={true}
                    disabled={formData.company_id == 0}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={formData.currency_code || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      currency_code: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[2]}
                    label="Currency Symbol"
                    block={true}
                    disabled={formData.company_id == 0}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={formData.currency_symbol || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      currency_symbol: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[3]}
                    label="Exchange Rate"
                    block={true}
                    // disabled={user?.configuration?.currency_code == formData.currency_code}
                    disabled={"TZS" == formData.currency_code}
                    requiredDecorator={true}
                    rules={[inputValidation.required, inputValidation.number]}
                    value={numberFormat(formData.exchange_rate) || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      exchange_rate: stripCommas(value)
                    })}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="card-footer d-flex flex-row">
          <button
            className="btn bg-secondary"
            onClick={() => save()}
          >
            {props.currencyID ? "Save" : "Add Currency"}
          </button>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default CurrencyCreate;
