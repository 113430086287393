import { useEffect, useRef, useState } from "react";
import { ConfirmationDialog, Modal } from "../../../../components/modal";
import { Table } from "../../../../components/table";
import TripDocumentTypeCreate from "./TripDocumentTypeCreate";

import { useDispatch, useSelector } from "react-redux";
import { useDeleteTripDocumentTypeMutation, useGetTripDocumentTypesQuery } from "../../../../services/settings/trips/TripDocumentTypeService";
import { clearAlert, setAlert } from "../../../../states/AlertState";
import { clearFilters, setFilters } from "../../../../states/FiltersState";
import { capitalize, numberFormat } from "../../../../utils/helpers/helpers";
interface TripDocumentTypeListProps {
  exportTitle?: string,
  accountCategory?: string,
  title?: string,
  [rest: string]: any,
}

const TripDocumentTypeList = (props: TripDocumentTypeListProps) => {
  const filters = useSelector((state: any) => state.filtersState);
  const dispatch = useDispatch();
  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetTripDocumentTypesQuery({...filters, account_category: props.accountCategory});
  const [ deleteTripDocumentTypeRecord ] = useDeleteTripDocumentTypeMutation();

  const createNewTripDocumentType = (tripDocumentType: any = undefined) => {
    modal.current?.show(
      `${tripDocumentType ? "Edit Trip Document Type Info" : "New Trip Document Type"}`,
      (<TripDocumentTypeCreate
        tripDocumentTypeID={tripDocumentType?.trip_document_type_id}
        accountCategory={props.accountCategory}
        modal={modal}
      />),
      window.pageYOffset,
      "small"
    );
  }

  const deleteTripDocumentType = (tripDocumentType: any) => {
    modal?.current?.show(
      "Delete Trip Document Type",
      <ConfirmationDialog
        promptMessage="Are you sure want to delete this trip document type"
        okayText="Delete"
        okayClassName="bg-danger"
        onOkay={() => {
          dispatch(setAlert({type: "progress", message: "Deleting..."}));
          modal?.current?.hide();
          deleteTripDocumentTypeRecord(tripDocumentType?.trip_document_type_id)
          .unwrap()
          .then((response: any) => {
            dispatch(setAlert({type: "success", message: "Trip Document Type successfully deleted"}));
          })
          .catch((error: any) => {
            dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
          })
        }}
        onCancel={() => {
          modal?.current?.hide();
        }}
      />
    )
  };

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [])

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div>
            <Table
              loading={isLoading}
              title={`${props.title || "Trip Document Types"}`}
              exportTitle={props.exportTitle}
              columns={[
                {label: "Document Type Name", name: "document_type_name", customRender: true},
                {label: "Description", name: "description"},
                {label: "Action", name: "action", customRender: true, webOnly: true},
              ]}
              items={data?.trip_document_types || []}
              onSearch={(search) => dispatch(setFilters({search: search}))}
              pagination={{
                ...(data?.pagination || {}),
                onPageChange: (page) => dispatch(setFilters({page: page})),
                onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
              }}
              params={{
                ...filters,
                from_date: undefined,
                to_date: undefined
              }}
              actions={[
                <button
                  className="btn small with-icon bg-secondary"
                  onClick={() => createNewTripDocumentType()}
                  key={0}
                >
                  <span className="material-icons">add</span>
                  <span>Add</span>
                </button>,
              ]}
              customRenders={[
                {
                  columnName: "document_type_name",
                  render: (item, index) => <span>{item.document_type_name && capitalize(item.document_type_name)}</span>
                },
                {
                  columnName: "action",
                  render: (item) => (
                    <div className="d-flex flex-row align-center">
                      <span
                        className="material-icons secondary-text cursor-pointer ml-2"
                        onClick={() => createNewTripDocumentType(item)}
                        title="Edit Info"
                      >
                        edit
                      </span>
                      <span
                        className="material-icons danger-text cursor-pointer ml-2"
                        onClick={() => deleteTripDocumentType(item)}
                        title="Delete Record"
                      >
                        delete
                      </span>
                    </div>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default TripDocumentTypeList;
