import { useEffect, useRef, useState } from "react";
import { ConfirmationDialog, Modal } from "../../../../components/modal";
import { Table } from "../../../../components/table";
import ExpenseCategoryItemCreate from "./ExpenseCategoryItemCreate";

import { useDispatch, useSelector } from "react-redux";
import { useDeleteExpenseCategoryItemMutation, useGetExpenseCategoryItemsQuery } from "../../../../services/settings/expenses/expense-category-items/ExpenseCategoryItemService";
import { clearAlert, setAlert } from "../../../../states/AlertState";
import { clearFilters as clearFilter, setFilters as setFilter } from "../../../../states/FiltersState";
import { SelectInput } from "../../../../components/input";
import { clearLocalFilters, setLocalFilters } from "../../../../states/FiltersLocalState";
interface ExpenseCategoryItemListProps {
  exportTitle?: string,
  vendor?: any,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const ExpenseCategoryItemList = (props: ExpenseCategoryItemListProps) => {
  const filters = useSelector((state: any) => !props.useLocalFilters ? state.filtersState : state.filtersLocalState);
  const setFilters: any = !props.useLocalFilters ? setFilter : setLocalFilters;
  const clearFilters: any = !props.useLocalFilters ? clearFilter : clearLocalFilters;
  const dispatch = useDispatch();
  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetExpenseCategoryItemsQuery({
    ...filters,
    vendor: filters.vendor || props.vendor?.vendor_id,
  });
  const [ deleteExpenseCategoryItemRecord ] = useDeleteExpenseCategoryItemMutation();

  const [expenseCategories, setExpenseCategories] = useState<any[]>([]);

  const createNewExpenseCategoryItem = (expenseCategoryItem: any = undefined) => {
    modal.current?.show(
      `${expenseCategoryItem ? "Edit Expense Item Info" : "New Expense Item"}`,
      (<ExpenseCategoryItemCreate
        expenseCategoryItemID={expenseCategoryItem?.expense_category_item_id}
        modal={modal}
      />),
    );
  }

  const deleteExpenseCategoryItem = (expenseCategoryItem: any) => {
    modal?.current?.show(
      "Delete ExpenseCategoryItem",
      <ConfirmationDialog
        promptMessage="Are you sure want to delete this expense item"
        okayText="Delete"
        okayClassName="bg-danger"
        onOkay={() => {
          dispatch(setAlert({type: "progress", message: "Deleting..."}));
          modal?.current?.hide();
          deleteExpenseCategoryItemRecord(expenseCategoryItem?.expense_category_item_id)
          .unwrap()
          .then((response: any) => {
            dispatch(setAlert({type: "success", message: "Expense item successfully deleted"}));
          })
          .catch((error: any) => {
            dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
          })
        }}
        onCancel={() => {
          modal?.current?.hide();
        }}
      />
    )
  };

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    data?.expense_categories && setExpenseCategories(data.expense_categories.map((expense_category: any) => ({label: expense_category.expense_category_name, value: expense_category.expense_category_id})));
  }, [data])

  useEffect(() => {
    return () => {
      dispatch(clearFilters());
    }
  }, [])

  return (
    <>
      <div className="row shadow bg-white p-2 mb-1 justify-center">
        <div className="col-12 col-md-4 col-sm-6">
          <SelectInput
            placeholder="Expense Category"
            block={true}
            clearable
            options={expenseCategories}
            onChange={(value) => dispatch(setFilters({
              expense_category: value?.value
            }))}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div>
            <Table
              loading={isLoading}
              title="Expense Items"
              exportTitle={props.exportTitle}
              columns={[
                {label: "Expense Item Name", name: "expense_category_item_name"},
                {label: "Expense Category", name: "expense_category_name"},
                {label: "Description", name: "description"},
                {label: "Action", name: "action", customRender: true, webOnly: true},
              ]}
              items={data?.expense_category_items || []}
              onSearch={(search) => dispatch(setFilters({search: search}))}
              pagination={{
                ...(data?.pagination || {}),
                onPageChange: (page) => dispatch(setFilters({page: page})),
                onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
              }}
              params={filters}
              actions={[
                ...(!props.vendor ? [<button
                  className="btn small with-icon bg-secondary"
                  onClick={() => createNewExpenseCategoryItem()}
                  key={0}
                >
                  <span className="material-icons">add</span>
                  <span>Add</span>
                </button>] : [])
              ]}
              customRenders={[
                {
                  columnName: "index",
                  render: (item, index) => <span>{index + 1}</span>
                },
                {
                  columnName: "action",
                  render: (item) => (
                    item.company_id !=0 ?
                    <div className="d-flex flex-row align-center">
                      <span
                        className="material-icons secondary-text cursor-pointer ml-2"
                        onClick={() => createNewExpenseCategoryItem(item)}
                        title="Edit Info"
                      >
                        edit
                      </span>
                      <span
                        className="material-icons danger-text cursor-pointer ml-2"
                        onClick={() => deleteExpenseCategoryItem(item)}
                        title="Delete Record"
                      >
                        delete
                      </span>
                    </div> :
                    <span className="grey-text">N/A</span>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default ExpenseCategoryItemList;
