import { useEffect, useRef, useState } from "react";
import { ConfirmationDialog, Modal } from "../../../components/modal";
import { Table } from "../../../components/table";
import TripDocumentCreate from "./TripDocumentCreate";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useDeleteTripDocumentMutation, useGetTripDocumentsQuery } from "../../../services/trips/TripDocumentService";
import { clearAlert, setAlert } from "../../../states/AlertState";
import { setFilters as setFilter } from "../../../states/FiltersState";
import { clearLocalFilters, setLocalFilters } from "../../../states/FiltersLocalState";
interface TripDocumentListProps {
  exportTitle?: string,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const TripDocumentList = (props: TripDocumentListProps) => {
  const setFilters: any = !props.useLocalFilters ? setFilter : setLocalFilters;
  const filters = useSelector((state: any) => !props.useLocalFilters ? state.filtersState : state.filtersLocalState);
  const dispatch = useDispatch();
  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetTripDocumentsQuery({...filters, trip: filters.trip || props.trip?.trip_id});
  const [ deleteTripDocumentRecord ] = useDeleteTripDocumentMutation();

  const [ params, setParams ] = useState<any>({
    from_date: moment().startOf("month").format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    page: 1,
    limit: 10,
  });

  const createNewTripDocument = (tripDocument: any = undefined) => {
    modal.current?.show(
      `${tripDocument ? "Edit Trip Document Info" : "New Trip Document"}`,
      (<TripDocumentCreate
        tripDocumentID={tripDocument?.trip_document_id}
        tripID={props.trip?.trip_id}
        modal={modal}
      />),
      window.pageYOffset,
      "small"
    );
  }

  const deleteTripDocument = (tripDocument: any) => {
    modal?.current?.show(
      "Delete Trip Document",
      <ConfirmationDialog
        promptMessage="Are you sure want to delete this Document"
        okayText="Delete"
        okayClassName="bg-danger"
        onOkay={() => {
          dispatch(setAlert({type: "progress", message: "Deleting..."}));
          modal?.current?.hide();
          deleteTripDocumentRecord(tripDocument?.trip_document_id)
          .unwrap()
          .then((response: any) => {
            dispatch(setAlert({type: "success", message: "Document successfully deleted"}));
          })
          .catch((error: any) => {
            dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
          })
        }}
        onCancel={() => {
          modal?.current?.hide();
        }}
      />
    )
  };

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      props.useLocalFilters && dispatch(clearLocalFilters());
    }
  }, [])

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div>
            <Table
              loading={isLoading}
              title="Trip Documents"
              exportTitle={props.exportTitle}
              columns={[
                {label: "Document Type", name: "document_type_name"},
                {label: "Document File", name: "attachment", customRender: true, webOnly: true},
                {label: "Description", name: "description"},
                {label: "Action", name: "action", customRender: true, webOnly: true},
              ]}
              items={data?.trip_documents || []}
              onSearch={(search) => dispatch(setFilters({search: search}))}
              pagination={{
                ...(data?.pagination || {}),
                onPageChange: (page) => dispatch(setFilters({page: page})),
                onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
              }}
              params={{
                ...filters,
                from_date: undefined,
                to_date: undefined
              }}
              actions={[
                <button
                  className="btn small with-icon bg-secondary"
                  onClick={() => createNewTripDocument()}
                  key={0}
                >
                  <span className="material-icons">add</span>
                  <span>Add</span>
                </button>,
              ]}
              customRenders={[
                {
                  columnName: "attachment",
                  render: (item) => (
                    <div className="d-flex flex-row align-center">
                      {item.attachment &&
                      <button
                        className="btn small bg-primary cursor-pointer"
                        onClick={() => window.open(item.attachment, "_blank")}
                        title="Document File"
                      >
                        View
                      </button>}
                    </div>
                  )
                },
                {
                  columnName: "action",
                  render: (item) => (
                    <div className="d-flex flex-row align-center">
                      <span
                        className="material-icons secondary-text cursor-pointer ml-2"
                        onClick={() => createNewTripDocument(item)}
                        title="Edit Info"
                      >
                        edit
                      </span>
                      <span
                        className="material-icons danger-text cursor-pointer ml-2"
                        onClick={() => deleteTripDocument(item)}
                        title="Delete Record"
                      >
                        delete
                      </span>
                    </div>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default TripDocumentList;
