import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmationDialog, Modal } from "../../components/modal";
import { Table } from "../../components/table";
import TripCreate from "./TripCreate";

import {
  DateInput,
  SelectInput,
} from "../../components/input";

import { useGetTripsQuery, useDeleteTripMutation } from "../../services/trips/TripService";
import { formatDateForDb, getModulePermissions, getNoPermissionMessage, padStart } from "../../utils/helpers/helpers";
import { useNavigate } from "react-router-dom";
import TripImport from "./TripImport";
import { setFilters as setFilter } from "../../states/FiltersState";
import { clearAlert, setAlert } from "../../states/AlertState";
import { clearLocalFilters, setLocalFilters } from "../../states/FiltersLocalState";
interface TripListProps {
  exportTitle?: string,
  vehicle?: any,
  employee?: any,
  customer?: any,
  cargo?: any,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const TripList = (props: TripListProps) => {
  const user = useSelector((state: any) => state.userState);
  const setFilters: any = !props.useLocalFilters ? setFilter : setLocalFilters;
  const filters = useSelector((state: any) => !props.useLocalFilters ? state.filtersState : state.filtersLocalState);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetTripsQuery({
    ...filters,
    vehicle: filters.vehicle || props.vehicle?.vehicle_id,
    employee: filters.employee || props.employee?.user_id,
    customer: filters.customer || props.customer?.customer_id,
    cargo: filters.cargo || props.cargo?.cargo_id,
  });
  const [ deleteTripRecord ] = useDeleteTripMutation();

  const [tripTypes, setTripTypes] = useState([
    {label: "Singletrip", value: "1"},
    {label: "Roundtrip", value: "0"},
  ]);

  const [tripStatus, setTripStatus] = useState([
    {label: "Completed", value: "0"},
    {label: "Ongoing", value: "1"},
    {label: "Yet to start", value: "2"},
    {label: "Yet to Assign", value: "3"},
  ]);
  
  const permission = useMemo(
    () => getModulePermissions("trips", user?.permissions),
    [user]
  );

  const createNewTrip = (trip: any = undefined) => {
    modal.current?.show(
      `${trip ? "Edit Trip Info" : "New Trip"}`,
      (<TripCreate
        tripID={trip?.trip_id}
        vehicle={props.vehicle}
        employee={props.employee}
        customer={props.customer}
        cargo={props.cargo}
        modal={modal}
      />),
    );
  }

  const createFromExcel = () => {
    modal.current?.show(
      "Import From Excel",
      (<TripImport
        modal={modal}
      />),
      window.pageYOffset,
      "small"
    );
  }

  const deleteTrip = (trip: any) => {
    modal?.current?.show(
      "Delete Trip",
      <ConfirmationDialog
        promptMessage="Are you sure want to delete this trip"
        okayText="Delete"
        okayClassName="bg-danger"
        onOkay={() => {
          dispatch(setAlert({type: "progress", message: "Deleting..."}));
          modal?.current?.hide();
          deleteTripRecord(trip?.trip_id)
          .unwrap()
          .then((response: any) => {
            dispatch(setAlert({type: "success", message: "Trip successfully deleted"}));
          })
          .catch((error: any) => {
            dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
          })
        }}
        onCancel={() => {
          modal?.current?.hide();
        }}
      />
    )
  };

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      props.useLocalFilters && dispatch(clearLocalFilters());
    }
  }, [])

  return (
    <>
      <div className="row shadow bg-white p-2 mb-1">
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date From"
            block={true}
            value={new Date(filters.from_date)}
            onChange={(value) => {
              dispatch(setFilters({
                from_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date To"
            block={true}
            value={new Date(filters.to_date)}
            onChange={(value) => {
              dispatch(setFilters({
                to_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Type"
            block={true}
            clearable
            options={tripTypes}
            value={tripTypes.find(type => type.value === filters.type)}
            onChange={(value) => dispatch(setFilters({
              type: value?.value,
            }))}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Status"
            block={true}
            clearable
            options={tripStatus}
            value={tripStatus.find(status => status.value === filters.status)}
            onChange={(value) => dispatch(setFilters({
              status: value?.value,
            }))}
          />
        </div>
      </div>
      <div className="card">
        {/* <div className="card-header small with-actions">
            <div>Trips</div>
            <div>
              <button
                className="btn small with-icon bg-secondary"
                onClick={() => createNewTrip()}
              >
                <span className="material-icons">add</span>
                <span>Add</span>
              </button>
            </div>
        </div> */}
        <div className="card-body">
            <div>
              <Table
                loading={isLoading}
                title="Trips"
                exportTitle={props.exportTitle}
                onRowClick={(item) => {
                  permission?.view ?
                  navigate(`/trips/details/${item.trip_id}`) :
                  dispatch(setAlert({type: "error", message: getNoPermissionMessage("view", "trip")}))
                }}
                columns={[
                  {
                    label: "Trip#",
                    name: "trip_number",
                    customRender: true,
                    valueGetter: (item) => padStart(item.trip_number, 4, "0"),
                  },
                  {label: "Customer", name: "customer_name", show: !(props.customer)},
                  {label: "Route", name: "route"},
                  {label: "Departure Date", name: "start_date"},
                  {label: "Arrival Date", name: "end_date"},
                  {
                    label: "Type",
                    name: "trip_type",
                    customRender: true,
                    valueGetter: (item) =>  item.trip_type === "1" ? "Singletrip" : "Roundtrip",
                  },
                  {label: "Vehicle", name: "registration_number", show: !(props.vehicle)},
                  {label: "Driver", name: "driver_name", show: !(props.employee)},
                  {
                    label: "Status",
                    name: "status",
                    customRender: true,
                    valueGetter: (item) =>  item.status == 0 ? "Completed" : (item.status == 1 ? "Ongoing" : (item.status == 2 ? "Yet to start" : (item.status == 3 ? "Yet to Assign" : undefined))),
                  },
                  {label: "Action", name: "action", customRender: true, webOnly: true},
                ]}
                items={data?.trips || []}
                permission={permission}
                onSearch={(search) => dispatch(setFilters({search: search}))}
                pagination={{
                  ...(data?.pagination || {}),
                  onPageChange: (page) => dispatch(setFilters({page: page})),
                  onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
                }}
                params={filters}
                actions={[
                  <button
                    className={`btn small with-icon bg-secondary ${!permission?.add && "disabled"}`}
                    onClick={() =>
                      permission?.add ?
                      createNewTrip() :
                      dispatch(setAlert({type: "error", message: getNoPermissionMessage("add", "trip")}))
                    }
                    key={0}
                  >
                    <span className="material-icons">add</span>
                    <span>Add</span>
                  </button>,
                  <button
                    className={`btn small with-icon bg-success ml-1 ${!permission?.add && "disabled"}`}
                    onClick={() =>
                      permission?.add ?
                      createFromExcel() :
                      dispatch(setAlert({type: "error", message: getNoPermissionMessage("add", "trip")}))
                    }
                    key={1}
                  >
                    <span className="material-icons">file_upload</span>
                    <span>Import From Excel</span>
                  </button>
                ]}
                customRenders={[
                  {
                    columnName: "index",
                    render: (item, index) => <span>{parseInt(data?.pagination?.page_start || 0) + index}</span>
                  },
                  {
                    columnName: "trip_number",
                    render: (item) => <span>{padStart(item.trip_number, 4, '0')}</span>
                  },
                  {
                    columnName: "trip_type",
                    render: (item) => <span>{item.trip_type === "1" ? "Singletrip" : "Roundtrip"}</span>
                  },
                  {
                    columnName: "status",
                    render: (item) => {
                      let trip_status = item.status == 0 ? "Completed" : (item.status == 1 ? "Ongoing" : (item.status == 2 ? "Yet to start" : (item.status == 3 ? "Yet to Assign" : undefined)));
                      let trip_status_class = item.status == 0 ? "bg-success" : (item.status == 1 ? "bg-secondary" : (item.status == 2 ? "bg-warning" : (item.status == 3 ? "bg-danger" : "light")));
                      return <span className={`badge ${trip_status_class} text-xsmall`}>{trip_status}</span>
                    }
                  },
                  {
                    columnName: "action",
                    render: (item) => (
                      <div className="d-flex flex-row align-center">
                        <span
                          className={`material-icons cursor-pointer primary-text ${!permission?.view && "disabled"}`}
                          onClick={(e) => {
                            e.stopPropagation(); 
                            permission?.view ?
                            navigate(`/trips/details/${item.trip_id}`) :
                            dispatch(setAlert({type: "error", message: getNoPermissionMessage("view", "trip")}))
                          }}
                          title="View Details"
                        >
                          visibility
                        </span>
                        <span
                          className={`material-icons cursor-pointer ml-2 secondary-text ${!permission?.update && "disabled"}`}
                          onClick={(e) => {
                            e.stopPropagation(); 
                            permission?.update ?
                            createNewTrip(item):
                            dispatch(setAlert({type: "error", message: getNoPermissionMessage("edit", "trip")}))
                          }}
                          title="Edit Info"
                        >
                          edit
                        </span>
                        <span
                          className={`material-icons cursor-pointer ml-2 danger-text ${!permission?.delete && "disabled"}`}
                          onClick={(e) => {
                            e.stopPropagation(); 
                            permission?.delete ?
                            deleteTrip(item):
                            dispatch(setAlert({type: "error", message: getNoPermissionMessage("delete", "trip")}))
                          }}
                          title="Delete Record"
                        >
                          delete
                        </span>
                      </div>
                    )
                  }
                ]}
              />
            </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default TripList;
