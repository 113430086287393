import { useEffect, useState } from "react";
import { Table } from "../../components/table";

import {
  DateInput,
  SelectInput,
} from "../../components/input";

import { formatDateForDb, padStart } from "../../utils/helpers/helpers";
import { numberFormat } from './../../utils/helpers/helpers';
import { useDispatch, useSelector } from "react-redux";
import { clearAlert, setAlert } from "../../states/AlertState";
import { clearFilters, setFilters } from "../../states/FiltersState";
import { useGetExpensesReportQuery } from "../../services/reports/ReportService";

interface ExpenseReportProps {
  expenseCategoryName?: string,
  expenseCategoryID?: any,
  totalColSpan?: number;
  exportTitle?: string,
  trip?: any,
  vehicle?: any,
  employee?: any,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const ExpenseReport = (props: ExpenseReportProps) => {
  const filters = useSelector((state: any) => state.filtersState);
  const dispatch = useDispatch();  

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetExpensesReportQuery({
    ...filters,
    trip: props.trip?.trip_id,
    vehicle: filters.vehicle || props.vehicle?.vehicle_id, 
    employee: filters.employee || props.employee?.user_id,
    expense_category: filters.expense_category || props.expenseCategoryID,
    is_payment: 0,
  });

  const [expenseCategories, setExpenseCategories] = useState<any[]>([]);
  const [expenseCategoryItems, setExpenseCategoryItems] = useState<any[]>([]);
  const [vehicles, setVehicles] = useState<any[]>([]);
  const [employees, setEmployees] = useState<any[]>([]);

  const [expenseAccountTypes, setExpenseAccountTypes] = useState([
    {label: "Asset", value: "1"},
    {label: "Liability", value: "2"},
    {label: "Expenses", value: "5"},
  ])

  const [fieldsLabels, setFieldsLabels] = useState({
    expenseDate: "Expense Date",
    expenseCategoryItem: "Expense Item",
    expenseCategoryItemUnit: "Expense Item Units",
    amount: "Amount"
  })

  const onCategoryChange = (category: any) => {
    switch(category){
      case 1:
        setFieldsLabels({
          expenseDate: "Paid Date",
          expenseCategoryItem: "Allowance Type",
          expenseCategoryItemUnit: "Expense Item units",
          amount: "Paid Amountt"
        });
        break;
      case 2:
        setFieldsLabels({
          expenseDate: "Filling Date",
          expenseCategoryItem: "Fuel Brand",
          expenseCategoryItemUnit: "Filled Litres",
          amount: "Cost Amount"
        });
        break;
      case 3:
        setFieldsLabels({
          expenseDate: "Changing Date",
          expenseCategoryItem: "Oil Brand",
          expenseCategoryItemUnit: "Total Units",
          amount: "Cost Amount"
        });
        break;
      case 4:
        setFieldsLabels({
          expenseDate: "Changing Date",
          expenseCategoryItem: "Brand Name",
          expenseCategoryItemUnit: "Total Units",
          amount: "Cost Amount"
        });
        break;
      case 5:
        setFieldsLabels({
          expenseDate: "Reparing Date",
          expenseCategoryItem: "Repared Item",
          expenseCategoryItemUnit: "Item Units",
          amount: "Cost Amount"
        });
        break;
      case 135:
        setFieldsLabels({
          expenseDate: "Expense Date",
          expenseCategoryItem: "Spare Name",
          expenseCategoryItemUnit: "Total Units",
          amount: "Cost Amount"
        });
        break;
      default:
        setFieldsLabels({
          expenseDate: "Expense Date",
          expenseCategoryItem: "Expense Item",
          expenseCategoryItemUnit: "Expense Item units",
          amount: "Amount"
        });
    }
  }

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    data?.expense_categories && setExpenseCategories(data.expense_categories.map((expense_category: any) => ({label: expense_category.expense_category_name, value: expense_category.expense_category_id})));
    data?.vehicles && setVehicles(data.vehicles.map((vehicle: any) => ({label: vehicle.registration_number, value: vehicle.vehicle_id})));
    data?.drivers && setEmployees(data.drivers.map((employee: any) => ({label: `${employee.first_name} ${employee.last_name}` , value: employee.user_id})));
    data?.expense_category_items && setExpenseCategoryItems(data.expense_category_items.map((expense_category_item: any) => ({...expense_category_item, label: expense_category_item.expense_category_item_name, value: expense_category_item.expense_category_item_id})));
  }, [data])

  useEffect(() => {
    onCategoryChange(props.expenseCategoryID);
    dispatch(clearFilters())
  }, [props.expenseCategoryID]);

  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [])

  const title = `${props.expenseCategoryName || "Expenses"} Report`;
  return (
    <>
      <div className={`row shadow bg-white p-2 mb-1 ${(props.trip || props.vehicle || props.expenseCategoryID) ? "justify-center" : ""}`}>
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date From"
            block={true}
            value={new Date(filters.from_date)}
            onChange={(value) => {
              dispatch(setFilters({
                from_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date To"
            block={true}
            value={new Date(filters.to_date)}
            onChange={(value) => {
              dispatch(setFilters({
                to_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
        {!(props.trip || props.vehicle || props.expenseCategoryName === "Allowances") &&
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Vehicle"
            block={true}
            clearable
            options={vehicles}
            onChange={(value) => dispatch(setFilters({
              vehicle: value?.value
            }))}
          />
        </div>}
        {!props.expenseCategoryID &&
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Expense Category"
            block={true}
            clearable
            options={expenseCategories}
            onChange={(value) => dispatch(setFilters({
              expense_category: value?.value
            }))}
          />
        </div>}
        {props.expenseCategoryName === "Allowances" &&
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Paid Driver"
            block={true}
            clearable
            options={employees}
            onChange={(value) => dispatch(setFilters({
              employee: value?.value
            }))}
          />
        </div>}
        {props.expenseCategoryName === "Tyre" &&
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Tyre Brand"
            block={true}
            clearable
            options={expenseCategoryItems}
            onChange={(value) => dispatch(setFilters({
              expense_category_item: value?.value
            }))}
          />
        </div>}
        {props.expenseCategoryName === "Spare Parts" &&
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Spare Part Name"
            block={true}
            clearable
            options={expenseCategoryItems}
            onChange={(value) => dispatch(setFilters({
              expense_category_item: value?.value
            }))}
          />
        </div>}
        {props.expenseCategoryName === "Allowances" &&
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Allowance Type"
            block={true}
            clearable
            options={expenseCategoryItems}
            onChange={(value) => dispatch(setFilters({
              expense_category_item: value?.value
            }))}
          />
        </div>}
        {!props.expenseCategoryID &&
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Account Type"
            block={true}
            clearable
            options={expenseAccountTypes}
            onChange={(value) => dispatch(setFilters({
              account_category: value?.value
            }))}
          />
        </div>}
      </div>
      <div className="card">
        <div className="card-body">
          <div>
            <Table
              loading={isLoading}
              title={title}
              exportTitle={props.exportTitle}
              columns={[
                {label: `${fieldsLabels.expenseDate}`, name: "expense_date"},
                {
                  label: "Expense#",
                  name: "expense_number",
                  customRender: true,
                  valueGetter: (item) => padStart(item.expense_number, 4, "0"),
                },
                {
                  label: "Bill#",
                  name: "bill_number",
                  customRender: true,
                  valueGetter: (item) => item.bill_number,
                  show: props.expenseCategoryName !== "Allowances",
                },
                {
                  label: "Trip#",
                  name: "trip_number",
                  customRender: true,
                  valueGetter: (item) => padStart(item.trip_number, 4, "0"),
                },
                {label: "Paid Employee", name: "driver_name", customRender: true, show: props.expenseCategoryName === "Allowances"},
                {label: "Vehicle", name: "vehicle_registration_number", customRender: true, show: !(props.trip || props.vehicle)},
                {label: "Category", name: "expense_category_name", customRender: true, show: !props.expenseCategoryID},
                {label: `${fieldsLabels.expenseCategoryItem}`, name: "expense_category_item_name", customRender: true, show: ["Tyre", "Spare Parts", "Allowances"].includes(props.expenseCategoryName || "")},
                {label: "Description", name: "description", customRender: true},
                {
                  label: `${fieldsLabels.expenseCategoryItemUnit}`,
                  name: "expense_category_item_unit",
                  customRender: true,
                  show: props.expenseCategoryName === "Fuel"
                },
                {label: `${fieldsLabels.amount} (Tsh)`, name: "amount", customRender: true},
              ]}
              items={data?.expenses || []}
              onSearch={(search) => dispatch(setFilters({search: search}))}
              pagination={{
                ...(data?.pagination || {}),
                onPageChange: (page) => dispatch(setFilters({page: page})),
                onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
              }}
              params={filters}
              customRenders={[
                {
                  columnName: "index",
                  render: (item, index) => <span>{index + 1}</span>
                },
                {
                  columnName: "expense_number",
                  render: (item) => <span>{padStart(item.expense_number, 4, '0')}</span>
                },
                {
                  columnName: "bill_number",
                  render: (item) => <span>{item.bill_number || "---"}</span>
                },
                {
                  columnName: "trip_number",
                  render: (item) => <span>{item.trip_number ? padStart(item.trip_number, 4, '0') : "---"}</span>
                },
                {
                  columnName: "expense_category_name",
                  render: (item, index) => <span>{item.expense_category_name || "Bill Payment"}</span>
                },
                {
                  columnName: "amount",
                  render: (item, index) => <span>{item.amount && numberFormat(item.amount)}</span>
                },
                {
                  columnName: "expense_category_item_name",
                  render: (item) => <span>{item.expense_category_item_name || "---"}</span>
                },
                {
                  columnName: "expense_category_item_unit",
                  render: (item, index) => <span>{item.expense_category_item_unit ? numberFormat(item.expense_category_item_unit) : "---"}</span>
                },
                {
                  columnName: "driver_name",
                  render: (item) => <span>{item.driver_name || "---"}</span>
                },
                {
                  columnName: "vehicle_registration_number",
                  render: (item) => <span>{item.vehicle_registration_number || "---"}</span>
                },
                {
                  columnName: "description",
                  render: (item) => <span>{item.description || "---"}</span>
                },
              ]}
              totalColumns={[
                { name:"amount" },
                { name: "expense_category_item_unit" }
              ]}
              totalColSpan={props.totalColSpan || 5}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ExpenseReport;
