import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { Form } from "../../components/form";
import {
  DateInput,
  SelectInput,
  TextField
} from "../../components/input";
import { ConfirmationDialog, Modal } from "../../components/modal";
import { formatDateForDb, getValidDateOrNull } from "../../utils/helpers/helpers";
import { getValidationRules } from '../../utils/helpers/helpers';
import { useDispatch } from "react-redux";
import { useCreateEmployeeMutation, useGetEmployeeToUpdateQuery } from "../../services/employees/EmployeeService";
import { clearAlert, setAlert } from "../../states/AlertState";

interface EmployeeCreateProps {
  employeeID?: any,
  modal?: MutableRefObject<any>,
}

const EmployeeCreate = (props: EmployeeCreateProps) => {
  const dispatch = useDispatch();
  const inputValidation = getValidationRules(); 

  const form = useRef<any>();
  const modal = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 10}).map(() =>  React.createRef()),
    []
  );

  const [formData, setFormData] = useState<any>({});

  const { data, isLoading, isSuccess, isError, error } = useGetEmployeeToUpdateQuery(props.employeeID || -1);
  const [ createEmployee ] = useCreateEmployeeMutation();

  const [roles, setRoles] = useState<any[]>([]);
  const [designations, setDesignations] = useState<any[]>([]);
  const [employmentStatuses, setEmploymentStatuses] = useState<any[]>([]);
  const [maritalStatuses, setMaritalStatuses] = useState<any[]>([]);

  const [genders] = useState([
    {label: "Male", value: "1"},
    {label: "Female", value: "0"},
  ]);

  const save = () => {
    if(!form?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    modal?.current?.show(
      "Confirm Save",
      <ConfirmationDialog
      promptMessage="Are you sure want to save this Employee Info"
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Processing..."}));
        modal?.current?.hide();
        let FD: any = {};
        Object.keys(formData).forEach((key) => {
          FD[key] = typeof formData[key]?.trim === "function" ? formData[key]?.trim() : formData[key];
        });
        createEmployee({...FD, id: props.employeeID})
        .unwrap()
        .then((response: any) => {
          if(isSuccess) {
            if(response?.success){
              if (props.employeeID) {
                dispatch(setAlert({type: "success", message: "Employee Info has successfully saved", unreplaceable: true}));
              }
              else {
                dispatch(setAlert({type: "success", message: "Employee has successfully added", unreplaceable: true}));
              }
              setTimeout(() => {
                props.modal?.current?.hide();
              }, 1500);
            }
            else{
              dispatch(setAlert({type: "error", message: response?.errors, unreplaceable: true}));
            }
          }
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message, unreplaceable: true}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }

  const populateData = (data: any) => {
    setFormData({
      ...data.employee,
      role: data.employee?.role_id,
      designation: data.employee?.designation_id,
      employment_status: data.employee?.employment_status_id,
      marital_status: data.employee?.marital_status_id,
    });
    data?.roles && setRoles(data.roles.map((role: any) => ({label: role.role_name, value: role.role_id})));
    data?.designations && setDesignations(data.designations.map((designation: any) => ({label: designation.designation_name, value: designation.designation_id})));
    data?.employment_status && setEmploymentStatuses(data.employment_status.map((employment_status: any) => ({label: employment_status.employment_status_name, value: employment_status.employment_status_id})));
    data?.marital_statuses && setMaritalStatuses(data.marital_statuses.map((marital_status: any) => ({label: marital_status.marital_status_name, value: marital_status.marital_status_id})));
  }

  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);

  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])

  useEffect(() => {
    isSuccess &&  dispatch(clearAlert());
  }, [isSuccess]);

  useEffect(() => {
    data && populateData(data);
  }, [data])

  return (
    <>
      <div className="card scrollable y">
        <div className="card-body">
          <div>
            <Form ref={form}>
              <div className="row">
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[0]}
                    label="First Name"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={formData.first_name || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      first_name: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[1]}
                    label="Last Name"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={formData.last_name || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      last_name: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[2]}
                    label="Gender"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={genders}
                    value={genders.filter(gender => gender.value === formData.sex)}
                    onChange={(value) => setFormData({
                      ...formData,
                      sex: value?.value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="Birth Date"
                    block={true}
                    value={getValidDateOrNull(formData.birth_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        birth_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Birth Place"
                    block={true}
                    value={formData.birth_place || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      birth_place: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Nationality"
                    block={true}
                    value={formData.nationality || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      nationality: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Languages"
                    block={true}
                    value={formData.languages || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      languages: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                    label="Marital Status"
                    block={true}
                    options={maritalStatuses}
                    value={maritalStatuses.filter(maritalStatus => maritalStatus.value === formData.marital_status)}
                    onChange={(value) => setFormData({
                      ...formData,
                      marital_status: value?.value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[3]}
                    label="Phone No"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={formData.phone_number || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      phone_number: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[4]}
                    label="Email"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={formData.email || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      email: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Present Address"
                    block={true}
                    value={formData.present_address || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      present_address: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Permanent Address"
                    block={true}
                    value={formData.permanent_address || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      permanent_address: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="ID Card Type"
                    block={true}
                    value={formData.id_card_type || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      id_card_type: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="ID Card Number"
                    block={true}
                    value={formData.id_card_number || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      id_card_number: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="Joining Date"
                    block={true}
                    value={getValidDateOrNull(formData.joining_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        joining_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Driving Licence No"
                    block={true}
                    value={formData.driving_licence_number || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      driving_licence_number: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="Driving Licence Expire Date"
                    block={true}
                    value={getValidDateOrNull(formData.driving_licence_expire_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        driving_licence_expire_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="Driving Licence Issue Date"
                    block={true}
                    value={getValidDateOrNull(formData.driving_licence_issue_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        driving_licence_issue_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Passport Number"
                    block={true}
                    value={formData.passport_number || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      passport_number: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="Passport Expire Date"
                    block={true}
                    value={getValidDateOrNull(formData.passport_expire_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        passport_expire_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="Passport Issue Date"
                    block={true}
                    value={getValidDateOrNull(formData.passport_issue_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        passport_issue_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
              </div>
              <div className="grey-text text-large mt-5 mb-3">Academic Info</div>
              <div className="row">
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[5]}
                    label="Role"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={roles}
                    value={roles.find(role => role.value === formData.role)}
                    onChange={(value) => setFormData({
                      ...formData,
                      role: value?.value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                    label="Designation"
                    block={true}
                    options={designations}
                    value={designations.filter(designation => designation.value === formData.designation)}
                    onChange={(value) => setFormData({
                      ...formData,
                      designation: value?.value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Qualification"
                    block={true}
                    value={formData.qualification || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      qualification: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Total Experience"
                    block={true}
                    value={formData.total_experience || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      total_experience: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                    label="Employment Status"
                    block={true}
                    options={employmentStatuses}
                    value={employmentStatuses.filter(employment_status => employment_status.value === formData.employment_status)}
                    onChange={(value) => setFormData({
                      ...formData,
                      employment_status: value?.value
                    })}
                  />
                </div>
              </div>
              <div className="grey-text text-large mt-5 mb-3">Bank Info</div>
              <div className="row">
                <div className="col-12 col-md-4">
                  <TextField
                    label="Bank Name"
                    block={true}
                    value={formData.bank_name || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      bank_name: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Account No"
                    block={true}
                    value={formData.bank_account_number || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      bank_account_number: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Account Name"
                    block={true}
                    value={formData.bank_account_name || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      bank_account_name: value
                    })}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="card-footer d-flex flex-row">
          <button
            className="btn bg-secondary"
            onClick={() => save()}
          >
            {props.employeeID ? "Save" : "Add Employee"}
          </button>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default EmployeeCreate;
