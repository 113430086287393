import React, { useEffect, useMemo, useRef, useState } from "react";
import { ConfirmationDialog, Modal } from "../../../../components/modal";
import { Table } from "../../../../components/table";
import { Form } from "../../../../components/form";
import CurrencyCreate from "./CurrencyCreate";

import { useDispatch, useSelector } from "react-redux";
import { clearAlert, setAlert } from "../../../../states/AlertState";
import { clearFilters, setFilters } from "../../../../states/FiltersState";
import { capitalize, getValidationRules, numberFormat } from "../../../../utils/helpers/helpers";
import { useDeleteCurrencyMutation, useGetCurrenciesQuery } from "../../../../services/settings/accounting/currency/CurrencyService";
import { SelectInput } from "../../../../components/input";
import { useCreateGeneralConfigMutation } from "../../../../services/settings/general/GeneralConfigService";
interface CurrencyListProps {
  exportTitle?: string,
  [rest: string]: any,
}

const CurrencyList = (props: CurrencyListProps) => {
  const user = useSelector((state: any) => state.userState);
  const filters = useSelector((state: any) => state.filtersState);
  const dispatch = useDispatch();
  const inputValidation = getValidationRules(); 
  const form = useRef<any>();
  const modal = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 8}).map(() =>  React.createRef()),
    []
  )

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetCurrenciesQuery(filters);
  const [ deleteCurrencyRecord ] = useDeleteCurrencyMutation();
  const [ createConfig ] = useCreateGeneralConfigMutation();

  const [ currencyOptions, setCurrencyOptions ] = useState([]);
  const [formData, setFormData] = useState<any>({});

  const save = () => {
    if(!form?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    modal?.current?.show(
      "Confirm Save",
      <ConfirmationDialog
      promptMessage="Are you sure want to save this Currency as a default"
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Processing..."}));
        modal?.current?.hide();
        let FD: any = {};
        Object.keys(formData).forEach((key) => {
         FD[key] = typeof formData[key]?.trim === "function" ? formData[key]?.trim() : formData[key];
        });
        let FDT = {
          configs: {
            currency_code: FD.currency_code,
          }
        }
        createConfig({...FDT})
        .unwrap()
        .then((response: any) => {
          dispatch(setAlert({type: "success", message: "Default Currency has successfully saved", unreplaceable: true}));
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }

  const createNewCurrency = (currency: any = undefined) => {
    modal.current?.show(
      `${currency ? "Edit Currency Info" : "New Currency"}`,
      (<CurrencyCreate
        currencyID={currency?.currency_id}
        modal={modal}
      />),
    );
  }

  const deleteCurrency = (currency: any) => {
    modal?.current?.show(
      "Delete Currency",
      <ConfirmationDialog
        promptMessage="Are you sure want to delete this currency"
        okayText="Delete"
        okayClassName="bg-danger"
        onOkay={() => {
          dispatch(setAlert({type: "progress", message: "Deleting..."}));
          modal?.current?.hide();
          deleteCurrencyRecord(currency?.currency_id)
          .unwrap()
          .then((response: any) => {
            dispatch(setAlert({type: "success", message: "Currency successfully deleted"}));
          })
          .catch((error: any) => {
            dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
          })
        }}
        onCancel={() => {
          modal?.current?.hide();
        }}
      />
    )
  };

  useEffect(() => {
    data?.currency?.length && setCurrencyOptions(data.currency.map((currency: any) => ({
      label: `${currency.currency_code} - ${currency.currency_name}`,
      value: currency.currency_code,
      ...currency,
    })))
  }, [data])

  useEffect(() => {
    let config = user?.configuration;
    config && setFormData({
      currency_code: config.currency_code,
    })
  }, [user])

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [])

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header bg-white">Default Currency</div>
            <div className="card-body">
              <div>
                <Form ref={form}>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <SelectInput
                        ref={inputs[0]}
                        label="Currency"
                        block={true}
                        disabled
                        options={currencyOptions}
                        // value={currencyOptions.filter((currency: any) => currency.value === formData.currency_code)}
                        value={currencyOptions.filter((currency: any) => currency.value === "TZS")}
                        onChange={(value) => setFormData({
                          ...formData,
                          currency_code: value?.value
                        })}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="card-footer d-flex flex-row">
              <button
                className="btn bg-secondary"
                onClick={() => save()}
              >
                Save Default Currency
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 mt-5">
          <div className="card">
            <div className="card-body">
              <div>
                <Table
                  loading={isLoading}
                  title="Currency List"
                  exportTitle={props.exportTitle}
                  columns={[
                    {label: "Currency Name", name: "currency_name"},
                    {label: "Currency Code", name: "currency_code"},
                    {label: "Currency Symbol", name: "currency_symbol"},
                    {label: "Exchange Rate", name: "exchange_rate", customRender: true},
                    {label: "Action", name: "action", customRender: true, webOnly: true},
                  ]}
                  items={data?.currency || []}
                  onSearch={(search) => dispatch(setFilters({search: search}))}
                  pagination={{
                    ...(data?.pagination || {}),
                    onPageChange: (page) => dispatch(setFilters({page: page})),
                    onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
                  }}
                  params={{
                    ...filters,
                    from_date: undefined,
                    to_date: undefined
                  }}
                  actions={[
                    <button
                      className="btn small with-icon bg-secondary"
                      onClick={() => createNewCurrency()}
                      key={0}
                    >
                      <span className="material-icons">add</span>
                      <span>Add</span>
                    </button>,
                  ]}
                  customRenders={[
                    {
                      columnName: "exchange_rate",
                      render: (item, index) => <span>{item.exchange_rate && numberFormat(item.exchange_rate)}</span>
                    },
                    {
                      columnName: "action",
                      render: (item) => (
                        <div className="d-flex flex-row align-center">
                          {/* {user?.configuration?.currency_code != item.currency_code && */}
                          {"TZS" != item.currency_code &&
                          <span
                            className="material-icons secondary-text cursor-pointer ml-2"
                            onClick={() => createNewCurrency(item)}
                            title="Edit Info"
                          >
                            edit
                          </span>}
                          {item.company_id !=0 &&
                          <span
                            className="material-icons danger-text cursor-pointer ml-2"
                            onClick={() => deleteCurrency(item)}
                            title="Delete Record"
                          >
                            delete
                          </span>}
                        </div>
                        // : <span className="grey-text">N/A</span>
                      )
                    }
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default CurrencyList;
