import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { Form } from "../../../../components/form";
import {
  TextArea,
  TextField
} from "../../../../components/input";
import { ConfirmationDialog, Modal } from "../../../../components/modal";
import { getValidationRules} from '../../../../utils/helpers/helpers';
import { useDispatch } from "react-redux";
import { useCreateTripDocumentTypeMutation, useGetTripDocumentTypeToUpdateQuery } from "../../../../services/settings/trips/TripDocumentTypeService";
import { clearAlert, setAlert } from "../../../../states/AlertState";

interface TripDocumentTypeCreateProps {
  tripDocumentTypeID?: any,
  accountCategory?: string,
  modal?: MutableRefObject<any>,
}

const TripDocumentTypeCreate = (props: TripDocumentTypeCreateProps) => {
  const dispatch = useDispatch();
  const inputValidation = getValidationRules(); 

  const form = useRef<any>();
  const modal = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 8}).map(() =>  React.createRef()),
    []
  )

  const [formData, setFormData] = useState<any>({});
  const { data, isLoading, isSuccess, isError, error } = useGetTripDocumentTypeToUpdateQuery(props.tripDocumentTypeID || -1);
  const [ createTripDocumentType ] = useCreateTripDocumentTypeMutation();

  const save = () => {
    if(!form?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    modal?.current?.show(
      "Confirm Save",
      <ConfirmationDialog
      promptMessage="Are you sure want to save this Trip Document Type Info"
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Processing..."}));
        modal?.current?.hide();
        let formDt = {
          ...formData,
          account_category: props.accountCategory,
        }
        let FD: any = {};
        Object.keys(formDt).forEach((key) => {
         FD[key] = typeof formDt[key]?.trim === "function" ? formDt[key]?.trim() : formDt[key];
        });
        createTripDocumentType({...FD, id: props.tripDocumentTypeID})
        .unwrap()
        .then((response: any) => {
          if (props.tripDocumentTypeID) {
            dispatch(setAlert({type: "success", message: "Trip Document Type Info has successfully saved", unreplaceable: true}));
          }
          else {
            dispatch(setAlert({type: "success", message: "Trip Document Type has successfully added", unreplaceable: true}));
          }
          setTimeout(() => {
            props.modal?.current?.hide();
          }, 1500);
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }

  const populateData = (data: any) => {
    setFormData({
      ...data.trip_document_type,
    });
  }

  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);

  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])

  useEffect(() => {
    isSuccess &&  dispatch(clearAlert());
  }, [isSuccess]);

  useEffect(() => {
    data && populateData(data);
  }, [data])

  return (
    <>
      <div className="card scrollable y">
        <div className="card-body">
          <div>
            <Form ref={form}>
              <div className="row">
                <div className="col-12">
                  <TextField
                    ref={inputs[1]}
                    label="Trip Document Type Name"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={formData.document_type_name || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      document_type_name: value
                    })}
                  />
                </div>
                <div className="col-12">
                  <TextArea
                    ref={inputs[2]}
                    label="Description"
                    block={true}
                    value={formData.description || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      description: value
                    })}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="card-footer d-flex flex-row">
          <button
            className="btn bg-secondary"
            onClick={() => save()}
          >
            {props.tripDocumentTypeID ? "Save" : "Add Trip Document Type"}
          </button>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default TripDocumentTypeCreate;
