import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmationDialog, Modal } from "../../../components/modal";
import { Table } from "../../../components/table";
import ExpenseTransactionCreate from "./ExpenseTransactionCreate";

import {
  DateInput,
  SelectInput,
} from "../../../components/input";

import { useGetTripsQuery} from "../../../services/trips/TripService";
import { formatDateForDb, getModulePermissions, numberFormat, padStart} from "../../../utils/helpers/helpers";
import { clearFilters, setFilters as setFilter } from "../../../states/FiltersState";
import { clearAlert, setAlert } from "../../../states/AlertState";
import { clearLocalFilters, setLocalFilters } from "../../../states/FiltersLocalState";
import { useDeleteExpenseTransactionMutation, useGetExpenseTransactionsQuery } from "../../../services/accounting/ExpenseTransactionService";
import ReceiptPreview from "./Receipt";
import ExpenseBillPreview from "./ExpenseBill";
import ExpenseCreate from "../../expenses/ExpenseCreate";
import { capitalize } from './../../../utils/helpers/helpers';
import ExpenseList from "../../expenses/ExpenseList";
import JournalPreview from "../journals/JournalPreview";
interface TransactionListProps {
  exportTitle?: string,
  vehicle?: any,
  trip?: any,
  employee?: any,
  vendor?: any,
  document_type?: any,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const TransactionList = (props: TransactionListProps) => {
  const user = useSelector((state: any) => state.userState);
  const setFilters: any = !props.useLocalFilters ? setFilter : setLocalFilters;
  const filters = useSelector((state: any) => !props.useLocalFilters ? state.filtersState : state.filtersLocalState);
  const dispatch = useDispatch();

  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetExpenseTransactionsQuery({
    ...filters,
    vendor: filters.vendor || props.vendor?.vendor_id,
    trip: props.trip?.trip_id,
    vehicle: filters.vehicle || props.vehicle?.vehicle_id, 
    employee: filters.employee || props.employee?.user_id,
    expense_category: filters.expense_category || props.expenseCategoryID,
    document_type: filters.document_type || props.document_type,
  });
  const [ deleteExpenseTransactionRecord ] = useDeleteExpenseTransactionMutation();

  const [documentTypes] = useState([
    {label: "Cash", value: "receipt"},
    {label: "Bill", value: "expense_bill"},
  ]);

  const [paymentStatus] = useState([
    {label: "Paid", value: "Paid"},
    {label: "Unpaid", value: "Unpaid"},
    {label: "Partially Paid", value: "Partially Paid"},
  ]);
  
  const permission = useMemo(
    () => getModulePermissions("trips", user?.permissions),
    [user]
  );

  const createNewExpenseTransaction = (expenseTransaction: any = undefined) => {
    modal.current?.show(
      `${expenseTransaction ? "Edit Expense Info" : "New Expense"}`,
      (<ExpenseTransactionCreate
        expenseTransaction={expenseTransaction}
        document_type={props.document_type}
        trip={props.trip}
        vehicle={props.vehicle}
        expenseCategoryID={props.expenseCategoryID}
        employee={props.employee}
        vendor={props.vendor}
        modal={modal}
      />),
    );
  }

  const deleteExpenseTransaction = (expenseTransaction: any) => {
    modal?.current?.show(
      "Delete Expense",
      <ConfirmationDialog
        promptMessage="Are you sure want to delete this expense"
        okayText="Delete"
        okayClassName="bg-danger"
        onOkay={() => {
          dispatch(setAlert({type: "progress", message: "Deleting..."}));
          modal?.current?.hide();
          deleteExpenseTransactionRecord(expenseTransaction?.expense_transaction_id)
          .unwrap()
          .then((response: any) => {
            dispatch(setAlert({type: "success", message: "Expense successfully deleted"}));
          })
          .catch((error: any) => {
            dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
          })
        }}
        onCancel={() => {
          modal?.current?.hide();
        }}
      />
    )
  };

  const addPayment = (item: any) => {
    modal.current?.show(
      "Add Payment",
      (<ExpenseCreate
        expenseTransaction={item}
        modal={modal}
      />),
    );
  }

  const viewPayments = (item: any) => {
    modal.current?.show(
      "Expense Payments",
      (<ExpenseList
        title="Expense Payments"
        trip={{ trip_id: item?.trip_id }}
        expenseTransaction={item}
        is_payment={1}
        useLocalFilters
        modal={modal}
      />),
      0,
      "large"
    );
  }

  const showDoc = (item: any, docType: string) => {
    modal.current?.show(
      `Expense Bill`,
      (
        <ExpenseBillPreview
          transactionID={item?.expense_transaction_id	}
          modal={modal}
        />
      ),
      0,
    );
  }

  const previewJournal = (item: any) => {
    modal.current?.show(
      "Jouranl",
      (
        <JournalPreview
          journalID={item?.journal_id}
          modal={modal}
        />
      ),
      0,
    );
  }

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      !props.useLocalFilters ? dispatch(clearFilters()) : dispatch(clearLocalFilters());
    }
  }, [])

  return (
    <>
      <div className="row shadow bg-white p-2 mb-1">
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date From"
            block={true}
            value={new Date(filters.from_date)}
            onChange={(value) => {
              dispatch(setFilters({
                from_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date To"
            block={true}
            value={new Date(filters.to_date)}
            onChange={(value) => {
              dispatch(setFilters({
                to_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
        {!props.document_type &&
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Expense Type"
            block={true}
            clearable
            options={documentTypes}
            value={documentTypes.find(type => type.value === filters.document_type)}
            onChange={(value) => dispatch(setFilters({
              document_type: value?.value,
            }))}
          />
        </div>}
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Payment Status"
            block={true}
            clearable
            options={paymentStatus}
            value={paymentStatus.find(status => status.value === filters.status)}
            onChange={(value) => dispatch(setFilters({
              status: value?.value,
            }))}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
            <div>
              <Table
                loading={isLoading}
                title={`${props.title || "Expenses"}`}
                exportTitle={props.exportTitle}
                columns={[
                  {label: "Vendor", name: "vendor_name", customRender: true},
                  {label: "Expense Type", name: "transaction_type", customRender: true, show: !props.document_type},
                  // {label: "Entry Date", name: "created_at"},
                  {label: "Bill#", name: "bill_number", customRender: true},
                  {label: "Trip#", name: "trip_number", customRender: true, show: !props.trip},
                  {label: `${props.document_type === "expense_bill" ? "Bill Date" : "Expense Date"}`, name: "expense_date"},
                  {label: "Amount (TZS)", name: "expense_amount", customRender: true},
                  {label: "Description", name: "description", customRender: true},
                  {label: "Created By", name: "employee_name"},
                  {label: "Payment Status", name: "payment_status", customRender: true},
                  {label: "Action", name: "action", customRender: true, webOnly: true},
                ]}
                items={data?.expense_transactions || []}
                // permission={permission}
                onSearch={(search) => dispatch(setFilters({search: search}))}
                pagination={{
                  ...(data?.pagination || {}),
                  onPageChange: (page) => dispatch(setFilters({page: page})),
                  onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
                }}
                params={filters}
                actions={[
                  ...(props.expenseCategoryID != 1 ? [
                  <button
                    // className={`btn small with-icon bg-secondary ${!permission?.add && "disabled"}`}
                    className={`btn small with-icon bg-secondary`}
                    onClick={() =>
                      // permission?.add ?
                      createNewExpenseTransaction()
                      // :
                      // dispatch(setAlert({type: "error", message: getNoPermissionMessage("add", "expense")}))
                    }
                    key={0}
                  >
                    <span className="material-icons">add</span>
                    <span>Add</span>
                  </button>] : []),
                ]}
                customRenders={[
                  {
                    columnName: "vendor_name",
                    render: (item) => <span>{item.vendor_name || "---"}</span>
                  },
                  {
                    columnName: "transaction_type",
                    render: (item) => <span>{capitalize(item.document_type === "receipt" ? "Cash" : "Bill")}</span>
                  },
                  {
                    columnName: "trip_number",
                    render: (item) => <span>{item.trip_number ? padStart(item.trip_number, 4, "0") : "---"}</span>
                  },
                  {
                    columnName: "bill_number",
                    render: (item) => <span>{item.bill_number || "---"}</span>
                  },
                  {
                    columnName: "expense_amount",
                    render: (item) => <span>{numberFormat(item.expense_amount)}</span>
                  },
                  {
                    columnName: "description",
                    render: (item) => <span>{item.description || "---"}</span>
                  },
                  {
                    columnName: "payment_status",
                    render: (item) => {
                      let payment_status_class = item.payment_status === "Paid" ? "bg-success" : (item.payment_status == "Unpaid" ? "bg-danger" : "bg-warning");
                      return <span className={`badge ${payment_status_class} text-xsmall`}>{item.payment_status}</span>
                    }
                  },
                  {
                    columnName: "action",
                    render: (item) => (
                      <div className="d-flex flex-row align-center">
                        <span
                          // className={`material-icons cursor-pointer ml-2 secondary-text ${!permission?.update && "disabled"}`}
                          className={`material-icons cursor-pointer ml-2 secondary-text`}
                          onClick={(e) => {
                            e.stopPropagation(); 
                            // permission?.update ?
                            createNewExpenseTransaction(item)
                            // :
                            // dispatch(setAlert({type: "error", message: getNoPermissionMessage("edit", "expense")}))
                          }}
                          title="Edit Info"
                        >
                          edit
                        </span>
                        <span
                          // className={`material-icons cursor-pointer ml-2 danger-text ${!permission?.delete && "disabled"}`}
                          className={`material-icons cursor-pointer ml-2 danger-text`}
                          onClick={(e) => {
                            e.stopPropagation(); 
                            // permission?.delete ?
                            deleteExpenseTransaction(item)
                            // :
                            // dispatch(setAlert({type: "error", message: getNoPermissionMessage("delete", "expense")}))
                          }}
                          title="Delete Record"
                        >
                          delete
                        </span>
                        {item.journal_id &&
                        <button
                          className="btn small bg-success cursor-pointer ml-2"
                          onClick={() => previewJournal(item)}
                          title="Preview Journal"
                        >
                          Journal
                        </button>}
                        <button
                          className="btn small bg-primary cursor-pointer ml-1"
                          onClick={() => viewPayments(item)}
                          title="Add Payment"
                        >
                          Payments
                        </button>
                        {item.bill_number &&
                        <button
                          className="btn small bg-primary cursor-pointer ml-1"
                          onClick={() => showDoc(item, "bill")}
                          title="View Invoice"
                        >
                          Bill
                        </button>}
                      </div>
                    )
                  },
                  {
                    columnName: "payment",
                    render: (item) => (
                      <div className="d-flex flex-row align-center">
                        {item.payment_status !== "Unpaid" &&
                        <button
                          className="btn small bg-success cursor-pointer mr-1"
                          onClick={() => viewPayments(item)}
                          title="Add Payment"
                        >
                          View
                        </button>}
                        {item.payment_status !== "Paid" &&
                        <button
                          className="btn small bg-primary cursor-pointer"
                          onClick={() => addPayment(item)}
                          title="Add Payment"
                        >
                          Add
                        </button>}
                      </div>
                    )
                  }
                ]}
              />
            </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default TransactionList;
