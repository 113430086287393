import { useEffect, useRef, useState } from "react";
import { Modal } from "../../components/modal";
import { Header, Footer, Body, Page } from "../../components/app";
import Home from "./home/Home";
import {
  Trips,
  Vehicles,
  Employees,
  Expenses,
  Payments,
  Routes,
  Customers,
  Cargos,
  Reports,
  Settings,
  Accounting,
} from "../../pages";
import secureLocalStorage from "react-secure-storage";
import { useGetUserQuery, useLogoutMutation } from "../../services/users/UserService";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../states/UserState";
import { Loader } from "../../components/loader";
import { hasPermission } from "../../utils/helpers/helpers";
import AccessDenied from './../../components/permission/AccessDenied';

const Account = () => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modal = useRef();
  
  const [ loading, setLoading ] = useState(true);
  const [ appLoading, setAppLoading ] = useState(false);
  const [ getUserData, setGetUserData ] = useState(false);
  const [ logoutUser ] = useLogoutMutation();
  const { data: userData, isSuccess, isError} = useGetUserQuery(null, { skip: !getUserData });

  const onLoadImage = (img: any) => {
    let canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    let ctx: any = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    let dataURL = canvas.toDataURL("image/png");
    try {
      dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
      return dataURL;
    } catch (e) {
      console.log("Storage failed: " + e);
      return false;
    }
  };
  
  const logout = () => {
    setAppLoading(true);
    window.location.href = "/login";
    secureLocalStorage.clear();
    logoutUser({})
    // .unwrap()
    // .then(response => {
    //     window.location.href = "/login"
    // })
    // .catch(error => {
    //     window.location.href = "/login"
    // });
  }
  
  const getModuleComponent = (module: any) => {
    let component: any = AccessDenied;
    if(hasPermission(module, user?.permissions || [])){
      switch(module){
        case "vehicles":
        component = Vehicles;
        break;
        case "employees":
        component = Employees;
        break;
        case "expenses":
        component = Expenses;
        break;
        case "payments":
        component = Payments;
        break;
        case "trips":
        component = Trips;
        break;
        case "routes":
        component = Routes;
        break;
        case "customers":
        component = Customers;
        break;
        case "cargos":
        component = Cargos;
        break;
        case "reports":
        component = Reports;
        break;
        case "settings":
        component = Settings;
        break;
        case "accounting":
        component = Accounting;
        break;
      }
    }
    return component;
  }
  
  useEffect(() => {
    isError && logout();
  }, [isError])
  
  useEffect(() => {
    if(isSuccess){
      dispatch(setUser(userData))
      setLoading(false);
      // setGetUserData(false)
    }
  }, [isSuccess, userData])
  
  useEffect(() => {
    // const ud = secureLocalStorage.getItem("ud");
    // if(!ud){
    //   logout();
    // }
    // else {
    setGetUserData(true)
    // }
  }, [])
  
  return (
    <>
      <div className="main">
      <Header
      onLogout = {logout}
      />
      {!loading ? (
        <Body
          homeUrl="/home"
          homeComponent={Home}
          hideHomeMenu
          pageUrl="/*"
          pageComponent={Page}
          pageRoutes={[
            {
              url: "/home",
              component: Home,
              props: {}
            },
            ...user?.modules?.map((module: any) => {
              return {
                url: `/${module.module?.replace(/_/g, "-")}/*`,
                component: getModuleComponent(module.module),
                props: {}
              }
            })
            
          ]}
        />
      ) :
      (
        <div className="preloader">
        <Loader />
        </div>
      )}
      <Footer />
      <Modal ref={modal}/>
      </div>
      {appLoading && (
        <div className="preloader">
        <Loader />
        </div>
      )}
      {userData?.company?.logo ? (
        <img
          crossOrigin="anonymous"
          src={userData?.company?.logo}
          alt="logo"
          className="hidden"
          onLoad={({ target: img }) => {
            dispatch(setUser({
              ...userData,
              company: {
                ...userData?.company,
                logo: onLoadImage(img)
              }
            }))
          }}
        />
      ) : null}
      <div id="datepicker-portal" />
      <div id="select-portal" />
    </>
  );
};

export default Account;