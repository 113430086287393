const AccountingMenu = {
    menuTitle: "Accounting",
    items: [
      {
        title: 'New Trip Receipt/Invoice',
        startIcon: "commute",
        url: "/accounting/uprocessed-payment-trips",
        permission: "accounting_unprocessed_payment_trips",
      },
      {
        title: 'Trip Receipt/Invoice List',
        startIcon: "receipt",
        url: "/accounting/transactions",
        permission: "accounting_transactions",
      },
      {
        title: 'Expenses',
        startIcon: "paid",
        url: "/accounting/expenses",
        subMenu: [
          {
            title: 'Expense List',
            startIcon: "paid",
            url: "/accounting/expenses/list",
            permission: "accounting_expense_list",
          },
          {
            title: 'Vendors',
            startIcon: "people",
            url: "/accounting/expenses/vendors",
            permission: "accounting_vendors",
          },
          {
            title: 'Expense Items',
            startIcon: "list",
            url: "/accounting/expenses/expense-items",
            permission: "accounting_expense_items",
          },
        ]
      },
      {
        title: 'Advanced Accounting',
        startIcon: "paid",
        url: "/accounting/advanced",
        subMenu: [
          
          {
            // title: 'Journal Entry',
            title: 'Manual Journals',
            startIcon: "account_balance",
            url: "/accounting/advanced/journal-entry",
            permission: "accounting_journal_entry",
          },
          {
            title: 'Chart of Accounts',
            startIcon: "account_tree",
            url: "/accounting/advanced/chart-of-accounts",
            permission: "accounting_chart_of_accounts",
          },
        ]
      },
      {
        title: 'Company Overview',
        startIcon: "summarize",
        url: "/accounting/reports",
        subMenu: [
          {
            title: 'Profit and Loss',
            startIcon: "summarize",
            url: "/accounting/reports/profit-and-loss",
            permission: "accounting_reports",
          },
          {
            title: 'Cash Flow Statement',
            startIcon: "summarize",
            url: "/accounting/reports/cash-flow",
            permission: "accounting_reports",
          },
          {
            title: 'Balance Sheet',
            startIcon: "summarize",
            url: "/accounting/reports/balance-sheet",
            permission: "accounting_reports",
          },
          {
            title: 'General Ledger',
            startIcon: "summarize",
            url: "/accounting/reports/general-ledger",
            permission: "accounting_reports",
          },
          {
            title: 'Trial Balance',
            startIcon: "summarize",
            url: "/accounting/reports/trial-balance",
            permission: "accounting_reports",
          },
          {
            title: 'Movement of Equity',
            startIcon: "summarize",
            url: "/accounting/reports/movement-of-equity",
            permission: "accounting_reports",
          },
        ]
      },
      {
        title: 'Receivables',
        startIcon: "summarize",
        url: "/accounting/reports/receivables",
        subMenu: [
          {
            title: 'AR Aging',
            startIcon: "summarize",
            url: "/accounting/reports/receivables/ar-aging",
            permission: "accounting_reports",
          },
          {
            title: 'Invoice Details',
            startIcon: "summarize",
            url: "/accounting/reports/receivables/invoice-details",
            permission: "accounting_reports",
          },
          {
            title: 'Customer Balance',
            startIcon: "summarize",
            url: "/accounting/reports/receivables/customer-balance",
            permission: "accounting_reports",
          },
          {
            title: 'Receivable Summary',
            startIcon: "summarize",
            url: "/accounting/reports/receivables/receivable-summary",
            permission: "accounting_reports",
          },
          {
            title: 'Receivable Details',
            startIcon: "summarize",
            url: "/accounting/reports/receivables/receivable-details",
            permission: "accounting_reports",
          },
        ]
      },
      {
        title: 'Payments Received',
        startIcon: "summarize",
        url: "/accounting/reports/payments-received",
        subMenu: [
          {
            title: 'Payments Received',
            startIcon: "summarize",
            url: "/accounting/reports/payments-received/payments-received",
            permission: "accounting_reports",
          },
          {
            title: 'Time to Get Paid',
            startIcon: "summarize",
            url: "/accounting/reports/payments-received/time-to-get-paid",
            permission: "accounting_reports",
          },
        ]
      },
    ]
}

export default AccountingMenu;