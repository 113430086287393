import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../components/modal";
import { Table } from "../../components/table";

import {
  DateInput,
  SelectInput,
} from "../../components/input";

import { useGetTripsQuery, useDeleteTripMutation } from "../../services/trips/TripService";
import { formatDateForDb, numberFormat, padStart } from "../../utils/helpers/helpers";
import { useNavigate } from "react-router-dom";
import { clearFilters, setFilters } from "../../states/FiltersState";
import { clearAlert, setAlert } from "../../states/AlertState";
import { useGetTripsReportQuery } from "../../services/reports/ReportService";
interface TripsReportProps {
  exportTitle?: string,
  vehicle?: any,
  employee?: any,
  customer?: any,
  cargo?: any,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const TripsReport = (props: TripsReportProps) => {
  const filters = useSelector((state: any) => state.filtersState);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetTripsReportQuery({
    ...filters,
    vehicle: filters.vehicle || props.vehicle?.vehicle_id,
    employee: filters.employee || props.employee?.user_id,
    customer: filters.customer || props.customer?.customer_id,
    cargo: filters.cargo || props.cargo?.cargo_id,
  });
  const [ deleteTripRecord ] = useDeleteTripMutation();

  const [tripTypes, setTripTypes] = useState([
    {label: "Singletrip", value: "1"},
    {label: "Roundtrip", value: "0"},
  ]);

  const [tripStatus, setTripStatus] = useState([
    {label: "Completed", value: "0"},
    {label: "Ongoing", value: "1"},
    {label: "Yet to start", value: "2"},
    {label: "Yet to Assign", value: "3"},
  ]);

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [])

  return (
    <>
      <div className="row shadow bg-white p-2 mb-1">
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date From"
            block={true}
            value={new Date(filters.from_date)}
            onChange={(value) => {
              dispatch(setFilters({
                from_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date To"
            block={true}
            value={new Date(filters.to_date)}
            onChange={(value) => {
              dispatch(setFilters({
                to_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Trip Status"
            block={true}
            clearable
            options={tripStatus}
            value={tripStatus.find(status => status.value === filters.status)}
            onChange={(value) => dispatch(setFilters({
              status: value?.value,
            }))}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Trip ype"
            block={true}
            clearable
            options={tripTypes}
            value={tripTypes.find(type => type.value === filters.type)}
            onChange={(value) => dispatch(setFilters({
              type: value?.value,
            }))}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
            <div>
              <Table
                loading={isLoading}
                title="Trips Report"
                exportTitle={props.exportTitle}
                columns={[
                  {
                    label: "Trip#",
                    name: "trip_number",
                    customRender: true,
                    valueGetter: (item) => padStart(item.trip_number, 4, "0"),
                  },
                  {label: "Route", name: "route"},
                  {label: "Dep. Date", name: "start_date"},
                  {label: "Arr. Date", name: "end_date", customRender: true},
                  {label: "Customer", name: "customer_name", show: !(props.customer)},
                  {label: "Cargo(s)", name: "cargos"},
                  {label: "Vehicle", name: "vehicle_registration_number", show: !(props.vehicle)},
                  {label: "Driver", name: "driver_name", show: !(props.employee)},
                  {
                    label: "Type",
                    name: "trip_type",
                    customRender: true,
                    valueGetter: (item) =>  item.trip_type === "1" ? "Singletrip" : "Roundtrip",
                  },
                  {
                    label: "Status",
                    name: "status",
                    customRender: true,
                    valueGetter: (item) =>  item.status == 0 ? "Completed" : (item.status == 1 ? "Ongoing" : (item.status == 2 ? "Yet to start" : (item.status == 3 ? "Yet to Assign" : undefined))),
                  },
                  {
                    label: "Freight Rate (Tsh)",
                    name: "freight_rate",
                    customRender: true,
                    valueGetter: (item) =>  numberFormat(item.freight_rate),
                  },
                  {
                    label: "Expenses (Tsh)",
                    name: "total_expense",
                    customRender: true,
                    valueGetter: (item) =>  numberFormat(item.total_expense),
                  },
                  {
                    label: "Expected Profit (Tsh)",
                    name: "profit",
                    customRender: true,
                    valueGetter: (item) =>  numberFormat(item.profit),
                  },
                ]}
                items={data?.trips || []}
                onSearch={(search) => dispatch(setFilters({search: search}))}
                pagination={{
                  ...(data?.pagination || {}),
                  onPageChange: (page) => dispatch(setFilters({page: page})),
                  onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
                }}
                params={filters}
                customRenders={[
                  {
                    columnName: "index",
                    render: (item, index) => <span>{parseInt(data?.pagination?.page_start || 0) + index}</span>
                  },
                  {
                    columnName: "trip_number",
                    render: (item) => <span>{padStart(item.trip_number, 4, '0')}</span>
                  },
                  {
                    columnName: "end_date",
                    render: (item) => <span>{item.end_date || "---"}</span>
                  },
                  {
                    columnName: "trip_type",
                    render: (item) => <span>{item.trip_type === "1" ? "Singletrip" : "Roundtrip"}</span>
                  },
                  {
                    columnName: "status",
                    render: (item) => {
                      let trip_status = item.status == 0 ? "Completed" : (item.status == 1 ? "Ongoing" : (item.status == 2 ? "Yet to start" : (item.status == 3 ? "Yet to Assign" : undefined)));
                      let trip_status_class = item.status == 0 ? "bg-success" : (item.status == 1 ? "bg-secondary" : (item.status == 2 ? "bg-warning" : (item.status == 3 ? "bg-danger" : "light")));
                      return <span className={`badge ${trip_status_class} text-xsmall`}>{trip_status}</span>
                    }
                  },
                  {
                    columnName: "freight_rate",
                    render: (item) => <span>{numberFormat(item.freight_rate)}</span>
                  },
                  {
                    columnName: "total_expense",
                    render: (item) => <span>{numberFormat(item.total_expense)}</span>
                  },
                  {
                    columnName: "profit",
                    render: (item) => <span>{numberFormat(item.profit)}</span>
                  },
                ]}
                totalColumns={[
                  { name: "freight_rate" },
                  { name: "total_expense" },
                  { name: "profit" }
                ]}
                totalColSpan={10}
              />
            </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default TripsReport;
