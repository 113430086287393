import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../../../components/modal";
import { Table } from "../../../../components/table";

import {
  DateInput,
  SelectInput,
} from "../../../../components/input";

import { useGetTripsQuery, useDeleteTripMutation } from "../../../../services/trips/TripService";
import { capitalize, formatDateForDb, getDatesOptions, numberFormat } from "../../../../utils/helpers/helpers";
import { useNavigate } from "react-router-dom";
import { clearFilters, setFilters } from "../../../../states/FiltersState";
import { clearAlert, setAlert } from "../../../../states/AlertState";
import { useGetTripsReportQuery } from "../../../../services/reports/ReportService";
import { useGetReceivablesQuery } from "../../../../services/accounting/AccountingReportService";
import moment from "moment";
import ARAgingDetails from "./ARAgingDetails";
import { useGetCustomersQuery } from "../../../../services/customers/CustomerService";
interface ARAgingSummaryProps {
  exportTitle?: string,
  vehicle?: any,
  employee?: any,
  customer?: any,
  cargo?: any,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const ARAgingSummary = (props: ARAgingSummaryProps) => {
  const filters = useSelector((state: any) => state.filtersState);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const modal = useRef<any>();

  const [ listenData, setListenData ] = useState(true);
  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetReceivablesQuery(
    filters, { skip: !((filters?.report_type == "ar_aging_summary") && listenData) }
  );

  const { data: customers } = useGetCustomersQuery({});
  const [customerList, setCustomerList] = useState<any>([]);

  const [entities, setEntities] = useState([
    {label: "Invoice", value: "invoice"},
    {label: "Journal", value: "journal"},
  ]);

  const [agingStarts, setAgingStarts] = useState([
    {label: "Invoice Due Date", value: "invoice_due_date"},
    {label: "Invoice Date", value: "invoice_date"},
  ]);

  const showDetailedReport = (item: any) => {
    setListenData(false);
    modal.current?.show(
      "AR Aging Details",
      (<ARAgingDetails
        customer={item.customer_id}
        aging_by={filters.aging_by}
        document_type={filters.document_type}
        annually_report_from_date={filters.annually_report_from_date}
        annually_report_to_date={filters.annually_report_to_date}
        annually_report_date_name={filters.annually_report_date_name}
        setListenData={setListenData}
        modal={modal}
      />),
    );
  }

  useEffect(() => {
    dispatch(setFilters({
      aging_by: "invoice_due_date",
      document_type: "invoice",
      report_type: "ar_aging_summary",
      date_filter_group: "annually",
      date_filter_type: "accumulated",
      limit: 1000000000
    }))
  }, [])

  useEffect(() => {
    customers?.customers?.length && setCustomerList(customers?.customers?.map((customer: any) => ({label: customer.first_name, value: customer.customer_id, ...customer})));
  }, [customers])

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [])

  return (
    <>
      <div className="row shadow bg-white p-2 mb-1">
      <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Report Date"
            block={true}
            options={getDatesOptions()}
            value={getDatesOptions().find(date => date.name === filters.annually_report_date_name)}
            onChange={(value) => dispatch(setFilters({
              annually_report_from_date: value?.name !== "custom" ? (value?.name === "previous_year" ? moment().subtract(1, "years").startOf("year").format("YYYY-MM-DD HH:mm:ss") : moment().startOf("year").format("YYYY-MM-DD HH:mm:ss")): filters.annually_report_from_date,
              annually_report_to_date: value?.name !== "custom" ? value?.to_date : filters.annually_report_to_date,
              annually_report_date_name: value?.name,
            }))}
          />
        </div>
        {filters.annually_report_date_name === "custom" &&
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Custom Report Date"
            block={true}
            value={new Date(filters.annually_report_to_date)}
            onChange={(value) => {
              dispatch(setFilters({
                annually_report_from_date: formatDateForDb(moment(value).startOf("year").format("YYYY-MM-DD HH:mm:ss")),
                annually_report_to_date: formatDateForDb(value),
              }))
            }}
          />
        </div>}
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Aging By"
            block={true}
            options={agingStarts}
            value={agingStarts.find(agingStarts => agingStarts.value === filters.aging_by)}
            onChange={(value) => dispatch(setFilters({
              aging_by: value?.value,
            }))}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Entities"
            block={true}
            clearable
            options={entities}
            value={entities.find(entity => entity.value === filters.document_type)}
            onChange={(value) => dispatch(setFilters({
              document_type: value?.value || "receivables",
            }))}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Customer"
            block={true}
            clearable
            options={customerList}
            value={customerList.find((customer: any) => customer.value == filters.customer)}
            onChange={(value) => dispatch(setFilters({
              customer: value?.value
            }))}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
            <div>
              <Table
                loading={isLoading}
                title={<span>AR Aging Summary By {capitalize(filters.aging_by?.split("_")?.join(" "))} - <span className="text-small">AS of {moment(filters.annually_report_to_date).format("MMM DD, YYYY")}</span></span>}
                exportTitle={props.exportTitle}
                downloadFileName={props.exportTitle || `AR Aging Summary By ${capitalize(filters.aging_by?.split("_")?.join(" "))} - AS of ${moment(filters.annually_report_to_date).format("MMM DD, YYYY")}`}
                onRowClick={(item) => showDetailedReport(item)}
                columns={[
                  {
                    label: "Customer Name",
                    name: "customer_name",
                    customRender: true,
                    valueGetter: (item) =>  capitalize(item.customer_name),
                  },
                  {
                    label: "Current",
                    name: "payment_amount_due_age_1",
                    money: true,
                    customRender: true,
                    valueGetter: (item) =>  numberFormat(item.payment_amount_due_age_1, 2, true),
                  },
                  {
                    label: "1-30 Days",
                    name: "payment_amount_due_age_2",
                    money: true,
                    customRender: true,
                    valueGetter: (item) =>  numberFormat(item.payment_amount_due_age_2, 2, true),
                  },
                  {
                    label: "31-60 Days",
                    name: "payment_amount_due_age_3",
                    money: true,
                    customRender: true,
                    valueGetter: (item) =>  numberFormat(item.payment_amount_due_age_3, 2, true),
                  },
                  {
                    label: "61-90 Days",
                    name: "payment_amount_due_age_4",
                    money: true,
                    customRender: true,
                    valueGetter: (item) =>  numberFormat(item.payment_amount_due_age_4, 2, true),
                  },
                  {
                    label: "> 90 Days",
                    name: "payment_amount_due_age_5",
                    money: true,
                    customRender: true,
                    valueGetter: (item) =>  numberFormat(item.payment_amount_due_age_5, 2, true),
                  },
                  {
                    label: "Total",
                    name: "total_payment_amount_due",
                    money: true,
                    customRender: true,
                    valueGetter: (item) =>  numberFormat(item.total_payment_amount_due, 2, true),
                  },
                ]}
                items={data?.entries || []}
                onSearch={(search) => dispatch(setFilters({search: search}))}
                // pagination={{
                //   ...(data?.pagination || {}),
                //   onPageChange: (page) => dispatch(setFilters({page: page})),
                //   onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
                // }}
                params={filters}
                customRenders={[
                  {
                    columnName: "customer_name",
                    render: (item, index) => <span>{capitalize(item.customer_name)}</span>
                  },
                  {
                    columnName: "payment_amount_due_age_1",
                    render: (item) => <span>{numberFormat(item.payment_amount_due_age_1, 2, true)}</span>
                  },
                  {
                    columnName: "payment_amount_due_age_2",
                    render: (item) => <span>{numberFormat(item.payment_amount_due_age_2, 2, true)}</span>
                  },
                  {
                    columnName: "payment_amount_due_age_3",
                    render: (item) => <span>{numberFormat(item.payment_amount_due_age_3, 2, true)}</span>
                  },
                  {
                    columnName: "payment_amount_due_age_4",
                    render: (item) => <span>{numberFormat(item.payment_amount_due_age_4, 2, true)}</span>
                  },
                  {
                    columnName: "payment_amount_due_age_5",
                    render: (item) => <span>{numberFormat(item.payment_amount_due_age_5, 2, true)}</span>
                  },
                  {
                    columnName: "total_payment_amount_due",
                    render: (item) => <span>{numberFormat(item.total_payment_amount_due, 2, true)}</span>
                  },
                ]}
                totalColumns={[
                  { name: "payment_amount_due_age_1" },
                  { name: "payment_amount_due_age_2" },
                  { name: "payment_amount_due_age_3" },
                  { name: "payment_amount_due_age_4" },
                  { name: "payment_amount_due_age_5" },
                  { name: "total_payment_amount_due" },
                ]}
                decimalPlaces={2}
                showZeroDecimalPlaces
                money
                totalColSpan={2}
              />
            </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default ARAgingSummary;
